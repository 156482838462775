import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { TableColumnsType } from 'antd';
import { message } from 'antd';
import {
  EffortFieldType,
  ICustomFieldModalAttributes,
  IGridList,
  IGridListBlockDetail,
  IGridListStageDetail,
  IProjectSections,
  IProjectState,
  ITaskListDetails,
} from 'models/interface';
import { useProjectDashboardStore, useUserStore } from 'stores';
import Block from '../Block/Block';
import StageNameInputField from '../Stage/StageNameInputField';
import TaskNameInputField from '../Task/TaskNameInputField';
import TableView from './TableComponents/ColumnDragTable';
import useGridHandler, { checkIsCustomField } from '../services/grid.handler';
import useGridService from '../services/grid.service';
import { loggerService } from 'services';
import { useLocation } from 'react-router-dom';
import AddCustomField from '../AddCustomField';
import CustomDropdown from '../CustomFieldComponents/CustomDropdown';
import { Rbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { useFetchNewPermission } from 'components/sharedComponents/hooks';
import CustomNumberField from '../CustomFieldComponents/CustomNumberField';
import MultiSelectDropdown from '../../TaskListV2/TableSection/CustomFieldCells/MultiSelectDropdown';
import GridCustomTextField from '../CustomFieldComponents/GridCustomTextField';
import DateTimeField from '../../TaskListV2/TableSection/CustomCells/DateTimeField';
import EffortsField from '../../TaskListV2/TableSection/CustomCells/EffortsField/EffortsField';

export interface DataType extends ITaskListDetails {
  key: React.Key;
  id: string | number;
  dataIndex: string;
  name: string;
  platform: string;
  version: string;
  label: string;
  title: string;
  upgradeNum: number;
  creator: string;
  createdAt: string;
  show: boolean;
  options?: any[];
  width?: number;
}

export interface ExpandedDataType {
  key: React.Key;
  date: string;
  name: string;
  upgradeNum: string;
}

const CFID_EFFORT_FIELD = 8;

const NestedTable: React.FC<{
  sectionDetails: IProjectSections;
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  getSectionDetails: () => void;
  customfieldCmsData?: ICustomFieldModalAttributes;
}> = (props) => {
  const {
    setGridListDetails,
    gridListDetails,
    sectionDetails,
    getSectionDetails,
    customfieldCmsData,
  } = props ?? {};

  // Hooks
  const location = useLocation();
  const { fetchNewPermission } = useFetchNewPermission();

  // Static
  const org_key = location.pathname.split('/')[2];

  // Stores
  const { projectDetails, gridListCmsData, customFields } =
    useProjectDashboardStore((state: IProjectState) => state);
  const { userDetails } = useUserStore((state: any) => state);

  // States
  const [columns, setColumns] = useState<TableColumnsType<DataType>>([]);
  const [dataSource, setDataSource] = useState<any[]>();
  const [customMetaMap, setCustomMetaMap] = useState<Map<string, any>>();
  let deleteOptionsRef = useRef<any>(null);

  useEffect(() => {
    sessionStorage.removeItem('newCustomField');
  }, []);

  // Memos
  const tableWidth = useMemo(() => {
    const handleGetColumnWidth = (column: any) => {
      if (column?.key === 'task') return 402;
      return checkIsCustomField(column?.key) ? column.width : 96;
    };

    return columns.reduce(
      (acc, curr: any) => acc + handleGetColumnWidth(curr),
      16,
    );
  }, [columns]);

  // Services
  const {
    updateStageDragEnd,
    updateTaskDragEnd,
    validateReOrderItems,
    updateCFCell,
    getCFBlockDataByTask,
    handleNewCFBlock,
    handleDeleteCFBlock,
    handleUpdateCFBlock,
  } = useGridHandler({
    gridListDetails,
    setGridListDetails,
  });
  const {
    reOrderTaskStageAPI,
    addEditCustomField,
    updateGridCustomFieldData,
    deleteCustomFieldColumnAPI,
    updateSettings,
    addNewCustomField,
    deleteCustomFieldV2,
    updateCustomFieldV2,
    updateTaskCustomFieldValue,
  } = useGridService({
    org_key,
    userDetails,
    projectDetails,
    sectionDetails,
    gridListDetails,
    setGridListDetails,
  });

  // Functions
  const handleReOrderStage = async (
    _: TableColumnsType<DataType>,
    fromIndex: number,
    toIndex: number,
  ) => {
    const originData = { ...gridListDetails };
    const { isInvalidReOrder, isBothCustomField } = validateReOrderItems(
      fromIndex,
      toIndex,
    );

    if (isInvalidReOrder) {
      return message.warning('Invalid re-order operation!');
    }

    const { fromId, toId, newCols } = updateStageDragEnd(
      fromIndex,
      toIndex,
      setColumns,
    );

    if (!fromId || !toId) return;

    try {
      const response = await reOrderTaskStageAPI(
        newCols as DataType[],
        dataSource,
      );

      if (response.messageId === -4) {
        const error = new Error(response?.message);
        (error as any).messageId = response?.messageId;
        throw error;
      }

      if (!response) return;

      const responseGridDetails = response.data.grid_list_details;

      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...responseGridDetails,
        },
      } as IGridList);
    } catch (error: any) {
      if (error?.messageId === -4) {
        fetchNewPermission(
          org_key,
          gridListCmsData?.lbl_error_message_permission_denied,
        );
        return;
      }
      setGridListDetails(originData);
      message.error(gridListCmsData?.lbl_generic_error_message, 3);
      await loggerService.log({
        severity: 'High',
        message: 'stage re-order failed',
        payload: 'DB Error',
      });
    }
  };

  const handleReOrderTask = async (from: string, to: string) => {
    const originData = { ...gridListDetails };

    try {
      const newDataSources = updateTaskDragEnd(from, to, setDataSource);
      if (
        newDataSources?.filter((item: any) => item !== undefined).length === 0
      )
        return;
      const response = await reOrderTaskStageAPI(
        columns as DataType[],
        newDataSources,
      );

      if (response.messageId === -4) {
        const error = new Error(response?.message);
        (error as any).messageId = response?.messageId;
        throw error;
      }

      const responseGridDetails = response.data.grid_list_details;
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...responseGridDetails,
        },
      } as IGridList);
    } catch (error: any) {
      if (error?.messageId === -4) {
        fetchNewPermission(
          org_key,
          gridListCmsData?.lbl_error_message_permission_denied,
        );
        return;
      }
      setGridListDetails(originData);
      message.error(gridListCmsData?.lbl_generic_error_message, 3);
      await loggerService.log({
        severity: 'High',
        message: 'task re-order failed',
        payload: 'DB Error',
      });
    }
  };

  const onCustomFieldCellChange = async (
    value: string | EffortFieldType,
    cell: any,
  ) => {
    const blockId = cell?.block_id;
    const taskId = cell?.task_id;
    if (!blockId || !taskId) return;
    const taskName =
      gridListDetails?.grid_list_details?.task_details?.find(
        (i) => i.task_id === taskId,
      )?.task_name ?? '';

    const originData = { ...gridListDetails };
    const isEffortFieldType = (val: any): val is EffortFieldType =>
      typeof val === 'object' && val !== null && 'type' in val;
    try {
      if (typeof value === 'object' && value?.type === 'effort') {
        updateCFCell(value, blockId, taskId);
        await updateGridCustomFieldData(
          taskName,
          taskId,
          getCFBlockDataByTask(
            taskId,
            isEffortFieldType(value) ? value : undefined,
          ),
          value,
        );
        await getSectionDetails();
      } else {
        updateCFCell(value, blockId, taskId);
        await updateGridCustomFieldData(
          taskName,
          taskId,
          getCFBlockDataByTask(taskId),
        );
      }
    } catch (error) {
      console.error(error);
      message.error('Failed to update custom field');
      setGridListDetails(originData);
    }
  };

  const deleteCustomField = async (stage: any) => {
    try {
      await deleteCustomFieldColumnAPI(stage.stage_id);
      await getSectionDetails();
    } catch (error) {
      message.error('Failed to delete custom field');
    }
  };

  const multiSelectOptionFilter = (
    selectedOptions: string[],
    allOptions: any[],
  ) => {
    if (selectedOptions && selectedOptions.length > 0) {
      let fetchOptionDetails: any[] = [];
      selectedOptions.forEach((opt: string) => {
        let findOptionDetails = allOptions.find(
          (itm: any) => itm.value === opt,
        );
        if (findOptionDetails) {
          fetchOptionDetails.push(findOptionDetails);
        }
      });

      return fetchOptionDetails;
    }

    return [];
  };

  const getGridColumns = useCallback(
    (stageDetails: any) => {
      return stageDetails
        .map((stage: any) => {
          const isCustomFieldColumn = `${stage.stage_id}`.includes('_');
          const customMeta = customMetaMap?.get(stage.stage_id) || {};
          return {
            title: (
              <StageNameInputField
                stageDetails={stage}
                gridListDetails={gridListDetails}
                setGridListDetails={setGridListDetails}
                index={stage.stage_id}
                customMeta={{
                  ...customMeta,
                  width: customMeta?.width ?? 150,
                }}
                // updateCustomMeta={handleAddCustomField}
                updateCustomMeta={handleUpdateCustomField}
                deleteOptionsRef={deleteOptionsRef}
                deleteCustomField={() => {
                  // deleteCustomField(stage);
                  handleDeleteCustomField(stage);
                }}
              />
            ),
            rawTitle: stage?.stage_name,
            hidden: false,
            dataIndex: stage?.stage_id?.toString(),
            key: stage?.stage_id?.toString(),
            align: 'center',
            width: isCustomFieldColumn
              ? customMeta?.tempWidth ?? customMeta.width ?? 150
              : 96,
            render: (text: any, record: any) => {
              if (!text || !Object.keys(text).length) return null;

              if (isCustomFieldColumn) {
                const options = (customMeta.options || [])
                  .filter((i: any) => i.value?.trim())
                  .sort((a: any, b: any) => a.option_rank - b.option_rank);

                if (customMeta?.id === 2) {
                  return (
                    <CustomNumberField
                      taskDetail={record}
                      disabled={projectDetails?.is_archived}
                      value={text?.custom_field_block_value?.value as number}
                      configuration={customMeta}
                      onChange={(e) => {
                        // onCustomFieldCellChange(e, text)
                        handleUpdateTaskCustomData(
                          {
                            forEffortField: false,
                            customData: {
                              mapping_id: text?.stage_id,
                              value: e,
                            },
                          },
                          text,
                        );
                      }}
                    />
                  );
                }
                if (customMeta?.id === 5) {
                  let blockValue = text?.custom_field_block_value ?? [];
                  const options = customMeta.options
                    .filter((i: any) => i.value?.trim())
                    .sort((a: any, b: any) => a.option_rank - b.option_rank);

                  const allValues = options.filter((i: any) =>
                    blockValue?.option_id?.includes(i.option_id),
                  );
                  return (
                    <MultiSelectDropdown
                      options={options
                        ?.map((item: any) => ({
                          ...item,
                          label: item.value,
                          key: item.option_id,
                          value: item.option_id,
                          color: item?.color ?? '#FFFFFF',
                        }))
                        .sort(
                          (a: any, b: any) => a.option_rank - b.option_rank,
                        )}
                      values={allValues}
                      onSelect={(e: any) => {}}
                      onChange={(e: any) => {
                        // onCustomFieldCellChange(e, text)
                        handleUpdateTaskCustomData(
                          {
                            forEffortField: false,
                            customData: {
                              mapping_id: text?.stage_id,
                              option_id: e?.map(
                                (option: any) => option?.option_id,
                              ),
                            },
                          },
                          text,
                        );
                      }}
                      disabled={projectDetails?.is_archived}
                    />
                  );
                }
                if (customMeta?.id === 6) {
                  return (
                    <GridCustomTextField
                      disabled={projectDetails?.is_archived}
                      value={text?.custom_field_block_value?.value as string}
                      onChange={(e) =>
                        handleUpdateTaskCustomData(
                          {
                            forEffortField: false,
                            customData: {
                              mapping_id: text?.stage_id,
                              value: e,
                            },
                          },
                          text,
                        )
                      }
                    />
                  );
                }
                if (customMeta?.id === 7) {
                  let blockValue = text?.custom_field_block_value ?? undefined;
                  return (
                    <DateTimeField
                      onSave={(e: any) => {
                        // onCustomFieldCellChange(e, text);
                        handleUpdateTaskCustomData(
                          {
                            forEffortField: false,
                            customData: {
                              mapping_id: text?.stage_id,
                              value: e,
                            },
                          },
                          text,
                        );
                      }}
                      dateTimeValue={blockValue?.value}
                      config={customMeta}
                    />
                  );
                }
                if (customMeta?.id === 8) {
                  if (!('total_duration_in_minutes' in text)) {
                    text.total_duration_in_minutes = 0;
                  }
                  return (
                    <EffortsField
                      type="gridList"
                      taskDetail={text}
                      disabled={projectDetails?.is_archived}
                      value={text?.custom_field_block_value?.value ?? []}
                      onSave={(efforts: any, effortLogged: any) => {
                        handleUpdateTaskCustomData(
                          {
                            forEffortField: true,
                            ...effortLogged,
                            customData: {
                              mapping_id: text?.stage_id,
                              ...efforts,
                            },
                          },
                          text,
                        );
                      }}
                      gridListDetails={gridListDetails}
                    />
                  );
                }
                return (
                  <CustomDropdown
                    options={options}
                    value={text?.custom_field_block_value ?? ''}
                    // onSelect={(e) => {}}
                    onChange={(e) => {
                      // onCustomFieldCellChange(e, text)
                      handleUpdateTaskCustomData(
                        {
                          forEffortField: false,
                          customData: {
                            mapping_id: text?.stage_id,
                            option_id: e?.option_id,
                          },
                        },
                        text,
                      );
                    }}
                    disabled={projectDetails?.is_archived}
                  />
                );
              }

              return (
                <Block
                  ele={text ?? {}}
                  taskIndex={text?.task_id}
                  gridListDetails={gridListDetails}
                  setGridListDetails={setGridListDetails}
                />
              );
            },
          };
        })
        .filter((item: any) => item);
    },
    [gridListDetails, setGridListDetails, customMetaMap, projectDetails],
  );

  const handleAddCustomField = async (
    customMeta: any,
    adding: boolean = false,
  ) => {
    if (customMeta?.tempWidth) delete customMeta.tempWidth;
    const custom_meta: any = [];
    gridListDetails?.grid_list_details?.stage_details?.forEach(
      (stage: IGridListStageDetail) => {
        if (stage?.stage_id && String(stage?.stage_id)?.includes('_')) {
          if (stage?.stage_id === customMeta?.mapping_id) {
            custom_meta.push(customMeta);
          } else {
            const findInCustomMeta = customMetaMap?.get(
              String(stage?.stage_id),
            );
            custom_meta.push(findInCustomMeta);
          }
        }
      },
    );
    const response = await addEditCustomField({
      customMeta,
      deleteOptions: deleteOptionsRef.current,
    });
    if (!adding) {
      await updateSettings({ ...gridListDetails, custom_meta });
    }
    await getSectionDetails();
    if (response?.messageId === -4) {
      fetchNewPermission(
        org_key,
        gridListCmsData?.lbl_error_message_permission_denied,
      );
    }
  };

  const handleAddNewCustomField = async (option: any) => {
    try {
      const { data, messageId } = await addNewCustomField({
        sectionId: Number(gridListDetails?.section_id),
        fieldTypeId: Number(option?.id),
      });
      if (messageId === 1) {
        handleNewCFBlock(data, userDetails.user_id, option);
      } else {
        const error = new Error(data?.message);
        (error as any).messageId = messageId;
        throw error;
      }
    } catch (error: any) {
      if (error?.messageId === -4) {
        fetchNewPermission(
          org_key,
          gridListCmsData?.lbl_error_message_permission_denied,
        );
        return;
      }
    }
  };

  const handleDeleteCustomField = async (option: any) => {
    try {
      const { data, messageId } = await deleteCustomFieldV2({
        sectionId: Number(gridListDetails?.section_id),
        mappingId: option.stage_id,
      });

      if (messageId === 1) {
        // await getSectionDetails();
        handleDeleteCFBlock(option.stage_id);
      } else {
        const error = new Error(data?.message);
        (error as any).messageId = messageId;
        throw error;
      }
    } catch (error: any) {
      if (error?.messageId === -4) {
        fetchNewPermission(
          org_key,
          gridListCmsData?.lbl_error_message_permission_denied,
        );
        return;
      }
      message.error('Failed to delete custom field');
    }
  };

  const handleUpdateCustomField = async (updateCustomField: any) => {
    try {
      let payload: any = { sectionId: Number(gridListDetails?.section_id) };
      const customSettings = { ...updateCustomField };

      payload.mappingId = updateCustomField?.mapping_id;

      if ('options' in customSettings) {
        delete customSettings.options;
      }

      payload.customSetting = customSettings;

      const deleteOptions =
        deleteOptionsRef?.current?.filter(
          (option: any) =>
            option.option_id !== undefined && option.option_id !== null,
        ) ?? [];

      if (deleteOptions?.length) {
        payload.deleteOptions = deleteOptions;
      }

      if (updateCustomField?.options?.length) {
        let addOptions: any[] = [],
          modifyOptions: any[] = [];
        updateCustomField.options.forEach((option: any) => {
          if (!option.option_id) {
            addOptions.push({
              value: option.name,
              color: option.new_color,
              option_rank: option.new_option_rank,
            });
            return;
          }
          if (
            option.name !== option.value ||
            option.color !== option.new_color ||
            option.option_rank !== option.new_option_rank
          ) {
            modifyOptions.push({
              option_id: option.option_id,
              value: option.name,
              color: option.new_color,
              option_rank: option.new_option_rank,
            });
          }
        });
        if (addOptions.length) {
          payload.addOptions = addOptions;
        }
        if (modifyOptions.length) {
          payload.modifyOptions = modifyOptions;
        }
      }

      const { data, messageId, message } = await updateCustomFieldV2(payload);

      if (messageId !== 1) {
        const error = new Error(message);
        (error as any).messageId = messageId;
        throw error;
      }

      handleUpdateCFBlock(data);
      // await getSectionDetails();
    } catch (error: any) {
      if (error?.messageId === -4) {
        fetchNewPermission(
          org_key,
          gridListCmsData?.lbl_error_message_permission_denied,
        );
        return;
      }
    }
  };

  const handleUpdateTaskCustomData = async (option: any, task: any) => {
    const payload = {
      ...option,
      sectionId: task.section_id,
      taskId: task.task_id,
    };

    const oldGridList = { ...gridListDetails };
    try {
      if (payload?.forEffortField) {
        updateCFCell(
          {
            mappingId: option?.customData?.mapping_id,
            value: payload?.effortLogged ?? [],
          } as EffortFieldType,
          task?.block_id,
          task?.task_id,
        );
        delete payload?.effortLogged;
      } else {
        updateCFCell(option?.customData, task?.block_id, task?.task_id);
      }
      const { data, _message, messageId } = await updateTaskCustomFieldValue(
        payload,
      );

      if (messageId !== 1) {
        const error = new Error(data);
        (error as any).messageId = messageId;
        throw error;
      }

      if (payload?.forEffortField) {
        const updatedCellData = data?.[0]?.custom_data.filter(
          (e: any) => e?.mapping_id === payload?.customData?.mapping_id,
        )?.[0];

        updateCFCell(
          {
            value: updatedCellData?.value ?? [],
            mappingId: updatedCellData?.mapping_id,
          } as EffortFieldType,
          task?.block_id,
          task?.task_id,
        );
      }
    } catch (error: any) {
      setGridListDetails(oldGridList);
      if (error?.messageId === -4) {
        fetchNewPermission(
          org_key,
          gridListCmsData?.lbl_error_message_permission_denied,
        );
        return;
      }
    }
  };

  const TaskColumn = useMemo(() => {
    const taskListDetails =
      gridListDetails.grid_list_details?.task_details || [];

    return {
      title: (
        <div className="gridHeading">
          <span>{gridListCmsData?.lbl_task_header ?? 'Tasks'}</span>
          {taskListDetails.length > 0 && !projectDetails?.is_archived && (
            <Rbac
              allowedPermissions={[
                ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_CUSTOM_FIELD_ADD,
                ERbacPermissions.PROJECT_SECTION_TASK_CUSTOM_FIELD_ADD,
              ]}
              project_role_id={projectDetails?.associated_role_id}
            >
              <AddCustomField
                options={customFields ?? []}
                onClick={(customFieldData: any) => {
                  // handleAddCustomField(customFieldData.default_meta, true);
                  handleAddNewCustomField(customFieldData.default_meta);
                }}
                onOpen={() => {
                  const table = document.getElementById(
                    `table-${gridListDetails.section_id}`,
                  );
                  if (table) {
                    const body =
                      table.querySelector('.ant-table-body') ||
                      table.querySelector('.ant-table-content');
                    if (body) {
                      body.scrollTo({ left: 0 });
                    }
                  }
                }}
                customfieldCmsData={customfieldCmsData}
              />
            </Rbac>
          )}
          <span className="verticleTxt">
            {gridListCmsData?.lbl_stage_header ?? 'Stages'}
          </span>
        </div>
      ),
      hidden: false,
      dataIndex: 'task',
      key: 'task',
      width: 406,
      fixed: true,
      render: (text: any) => {
        if (!text || !Object.keys(text).length) return null;

        return (
          <TaskNameInputField
            taskDetails={text}
            gridListDetails={gridListDetails}
            setGridListDetails={setGridListDetails}
          />
        );
      },
    };
  }, [
    gridListCmsData,
    gridListDetails,
    customMetaMap,
    projectDetails?.is_archived,
  ]);

  useEffect(() => {
    const gridData = gridListDetails.grid_list_details;

    if (gridData) {
      const blockDetails = gridData.block_details || [];
      const stageDetails = gridData.stage_details || [];
      const taskDetails = gridData.task_details || [];

      const taskMap = new Map();

      taskDetails.forEach((task) => {
        taskMap.set(task.task_id, { task });
      });

      // @ts-ignore
      blockDetails.forEach((blocks: IGridListBlockDetail[]) => {
        blocks.forEach((block: IGridListBlockDetail) => {
          const taskId = block?.task_id;
          const stageId = block?.stage_id;

          const taskObj = taskMap?.get(taskId);

          if (taskObj && stageId) {
            taskObj[stageId] = block;
          }
        });
      });

      const data: any = [];

      taskDetails.forEach((task) => {
        data.push({ ...taskMap.get(task.task_id), key: task.task_id });
      });

      setColumns([TaskColumn, ...getGridColumns(stageDetails)]);
      setDataSource(data);
    }
  }, [gridListDetails, customMetaMap, getGridColumns]);

  useEffect(() => {
    const customFieldMap = new Map<string, any>();
    if (gridListDetails.custom_meta?.length) {
      gridListDetails.custom_meta.forEach((field) => {
        customFieldMap.set(field?.mapping_id, field);
      });
    }
    setCustomMetaMap(customFieldMap);
  }, [gridListDetails.custom_meta]);
  return (
    <div style={{ width: tableWidth + 12, maxWidth: '100%' }}>
      <TableView
        tableId={`table-${gridListDetails.section_id}`}
        className="nested-table"
        columns={columns}
        onColumnOrderChange={handleReOrderStage}
        onDataSourceChange={handleReOrderTask}
        onClickAdd={() => {}}
        dataSource={dataSource ?? []}
        size="small"
        bordered
        pagination={false}
        key="key"
        rowKey="key"
        locale={{ emptyText: ' ' }} // Remove the No-data UI
        // scroll={
        //   (dataSource?.length ?? 0) > 5
        //     ? { x: tableWidth, y: 480 }
        //     : { x: tableWidth }
        // }
        scroll={
          dataSource?.length! > 5
            ? { x: tableWidth, y: 'calc(100vh - 360px)' }
            : { x: tableWidth }
        }
        disabled={projectDetails?.is_archived}
      />
    </div>
  );
};

export default NestedTable;
