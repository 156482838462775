import { RightOutlined, SettingOutlined } from '@ant-design/icons';
import { Dropdown, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import ColumnEditForm from './ColumnSettings';
import { getNewCustomFieldId } from 'utils/commonFunctions';

interface IAction {
  customMeta: any;
  updateCustomMeta: (customMeta: any) => void;
  onDelete?: (customMeta: any) => void;
  onDeleteOptions?: (deletedOptions: any) => void;
  onClose?: () => void;
  onChange?: (changedValue: any, allValues: any) => void;
}

const ActionDropdown: React.FC<IAction> = ({
  customMeta,
  updateCustomMeta,
  onDelete,
  onChange,
  ...restProps
}) => {
  const props = {
    customMeta,
    onDelete,
  };
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  useEffect(() => {
    let newCF = getNewCustomFieldId();
    if (newCF === customMeta.mapping_id) {
      setTimeout(() => {
        setSelectedOption('settings')
        setOpenDropdown(true);
      }, 100)
    }
  }, []);


  const handleOptionClick = (e: any) => {
    setSelectedOption(e.key);
  };

  const handleCloseDropdown = () => {
    sessionStorage.removeItem('newCustomField');
    setOpenDropdown(false);
  };

  const handleOpenChange = (visible: boolean) => {
    if (visible) {
      setOpenDropdown(visible);
    } else {
      handleCloseDropdown();
    }
  };

  const renderComponent = () => {
    switch (selectedOption) {
      case 'settings':
        return (
          <ColumnEditForm
            {...props}
            {...restProps}
            updateCustomMeta={(customMeta) => {
              updateCustomMeta(customMeta);
              handleCloseDropdown();
              setSelectedOption('');
            }}
            onChange={(changedValue: any, allValues: any) => {
              onChange?.(changedValue, allValues);
            }}
          />
        );
      case 'delete':
        return <>Delete</>;
      default:
        return null;
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {!selectedOption ? (
        <Dropdown
          menu={{
            items: [
              {
                key: 'settings',
                label: (
                  <div className="antdSettingsDropdown">
                    <SettingOutlined /> <span>Settings</span>
                    <span className="ddArrow">
                      <RightOutlined />
                    </span>
                  </div>
                ),
              },
            ],
            onClick: handleOptionClick,
          }}
          open={openDropdown}
          onOpenChange={(visible) => { handleOpenChange(visible) }
          }
          placement="bottomLeft"
          trigger={['click']}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="blkMore"
          >
            More
          </div>
        </Dropdown>
      ) : (
        <Popover
          open
          trigger="click"
          onOpenChange={(e) => {
            if (!e) {
              onChange && onChange({ width: customMeta.width }, null);
              handleCloseDropdown()
              setSelectedOption('');
            }
          }}
          content={renderComponent()}
        ></Popover>
      )}
    </div>
  );
};

export default ActionDropdown;
