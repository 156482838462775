import { FC, Fragment, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import io from 'socket.io-client';
import { Button, Space, Tooltip, notification } from 'antd';
import {
  IOrganization,
  IUserDetails,
  IProfileSettingCmsData,
  IDashboardApiResponse,
  IProjectFolderDetails,
  IProjectList,
  ILoaderState,
  IProjectState,
  IDashboardStore,
} from '../../../models/interface';

import {
  addOverlay,
  getCurrentOrganization,
  getPageSpecificData,
  removeOverlay,
} from '../../../utils/commonFunctions';
import {
  useDashboardStore,
  useLoaderStore,
  useProjectDashboardStore,
  useLocalizationStore,
  useUserStore,
} from '../../../stores';
import { cmsService, dashboardService, loggerService } from '../../../services';
import BrowserStorageService from 'services/browserStorage.service';
import { FolderProjectExpandedView } from './FolderProjectExpandedView/FolderProjectExpandedView';
import { DefaultItemViewForProd } from './DefaultItemViewForProd';
import { generateNewListFromCreateFolder } from 'utils/generateNewList';
import AllOrganizationLeftNav from '../../sharedComponents/AllOrganizationLeftNav/AllOrganizationLeftNav';
import FullCalendar from '@fullcalendar/react';
import { NavRoutesEnum } from 'models/enums/navRoutes';
import { Rbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';

const socket = io(process.env.REACT_APP_SOCKET_URL!, {
  withCredentials: true,
});

export const LeftNav: FC<{
  pageSlug?: string[];
  cmsPageName?: string[];
  isCollapsed?: boolean;
  calendarRef?: React.MutableRefObject<FullCalendar>;
}> = ({ pageSlug, cmsPageName, isCollapsed, calendarRef }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const location = useLocation();
  const { logout } = useAuth0();

  const { userDetails } = useUserStore((state: any) => state);
  const {
    folderProjectList,
    setFolderProjectList,
    setProjectList,
    setFolderList,
    setExpandedNavBar,
  } = useDashboardStore((state: IDashboardStore) => state);
  const { refreshDashboardFolders, setRefreshDashboardFolders } =
    useProjectDashboardStore((state: IProjectState) => state);
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
  const { cmsData, setCmsData } = useLocalizationStore((state: any) => state);

  const getIsNavExpanded = () => {
    const effectiveScreenWidth = window.innerWidth;

    if (effectiveScreenWidth < 1600) {
      return true;
    }
    return isCollapsed ?? false;
  };

  const [pageCmsData, setPageCmsData] = useState<any>(null);
  const [navExpanded, setNavExpanded] = useState<boolean>(getIsNavExpanded());
  const [orgDetails, setOrgDetails] = useState<IOrganization[]>([]);
  const [logoutSwitch, setLogoutSwitch] = useState<boolean>(false);
  const [moreOrgListVisible, setMoreOrgListVisible] = useState<boolean>(false);
  const [profileSettingLabel, setProfileSettingLabel] =
    useState<IProfileSettingCmsData | null>(null);
  const [api, contextHolder] = notification.useNotification({ maxCount: 1 });

  const [newFolderDetails, setNewFolderDetails] = useState({
    visibility: false,
    name: '',
  });

  const org_key = location.pathname.split('/')[2];
  const currentOrganization =
    userDetails?.organization_info &&
    getCurrentOrganization(org_key, userDetails?.organization_info);
  const isFoundOrg = () => {
    return userDetails?.organization_info?.find(
      (ele: any) => ele?.org_key === org_key,
    );
  };
  const navigate = useNavigate();

  const browserStorage = BrowserStorageService.getInstance();

  const goToTeamManagement = () => {
    browserStorage.setLastUrl(location.pathname);
    navigate(`/org/${org_key}/users/settings?section=team-settings`);
  };

  const checkIfProjectExistsInCurrentList = (projectId: number) => {
    if (userDetails && userDetails.project_permissions) {
      let findProject = userDetails.project_permissions.find(
        (itm: any) => Number(itm.project_id) === projectId,
      );
      if (findProject) {
        return true;
      }
    }

    return false;
  };

  const actionOnNotification = (payload: any, isArchived: boolean = false) => {
    let parsedPayload = JSON.parse(payload);
    if (
      parsedPayload.currentOrganizationId ===
        getCurrentOrganization(org_key, userDetails?.organization_info)
          ?.organization_id &&
      parsedPayload.currentUserId !== userDetails.user_id &&
      checkIfProjectExistsInCurrentList(Number(parsedPayload.currentProjectId))
    ) {
      if (isArchived === true) {
        addOverlay();
      }
      openNotification(
        parsedPayload.currentUserName,
        parsedPayload.isArchived,
        isArchived,
        parsedPayload?.currentProjectName,
      );
    }
  };

  const openNotification = (
    name: string,
    projectArchive: boolean,
    isArchived: boolean = false,
    projectName: string = '',
  ) => {
    if (isArchived === true) {
      let tmpPageData = getPageSpecificData(cmsData, 'archive-project')!;
      let archiveProjectCMSData: any = tmpPageData[0]?.attributes;
      const notificationDescription = `<strong>${projectName}</strong> ${
        projectArchive
          ? archiveProjectCMSData?.lbl_archive_notification_config.message
          : archiveProjectCMSData?.lbl_restore_notification_config.message
      }`;
      const btn = (
        <Space>
          <Button
            type="primary"
            size="small"
            onClick={async () => {
              navigate(
                `/org/${location.pathname.split('/')[2]}/${
                  NavRoutesEnum.DASHBOARD
                }`,
              );
              window.location.reload();
              api.destroy();
            }}
          >
            {projectArchive
              ? archiveProjectCMSData?.lbl_archive_notification_config.action
              : archiveProjectCMSData?.lbl_restore_notification_config.action}
          </Button>
        </Space>
      );
      api.info({
        message: projectArchive
          ? archiveProjectCMSData?.lbl_archive_notification_config.title
          : archiveProjectCMSData?.lbl_restore_notification_config.title,
        description: (
          <div dangerouslySetInnerHTML={{ __html: notificationDescription }} />
        ),
        btn,
        closeIcon: <></>,
        placement: 'bottomLeft',
        duration: 0,
        onClose: () => {
          removeOverlay();
        },
      });
    } else {
      let tmpPageData = getPageSpecificData(cmsData, 'task-list')!;
      let taskListCmsData: any = tmpPageData[0]?.attributes;
      const btn = (
        <Space>
          <Button
            type="primary"
            size="small"
            onClick={async () => {
              window.location.reload();
              api.destroy();
            }}
          >
            {taskListCmsData?.lbl_notification_config.action}
          </Button>
        </Space>
      );
      api.info({
        message: taskListCmsData?.lbl_notification_config.title,
        description: `${name} ${taskListCmsData?.lbl_notification_config.message}`,
        btn,
        closeIcon: <></>,
        placement: 'bottomLeft',
        duration: 0,
      });
    }
  };

  useEffect(() => {
    socket.on('receiveProjectArchive', (projectArchivePayload: any) => {
      actionOnNotification(projectArchivePayload, true);
    });

    return () => {
      socket.off('receiveProjectArchive');
    };
  }, []);

  useEffect(() => {
    if (pageSlug) {
      (async () => {
        let tmpPageData: any;
        let profileSetData: any;
        let pageSpecificData = cmsData.pageSpecificData;
        if (pageSpecificData.length > 0) {
          tmpPageData = getPageSpecificData(cmsData, pageSlug[0]);
          profileSetData = getPageSpecificData(cmsData, 'profile-setting');
          await tempPageDataSet(tmpPageData, pageSlug);
          await profileDataSet(profileSetData, pageSlug);
        } else {
          await cmsService.fetchCmsData(
            cmsData,
            setCmsData,
            cmsPageName,
            pageSlug,
          );
          tmpPageData = getPageSpecificData(cmsData, pageSlug[0]);
          tmpPageData && setPageCmsData(tmpPageData[0]?.attributes);
          profileSetData = getPageSpecificData(cmsData, 'profile-setting');
          profileSetData &&
            setProfileSettingLabel(profileSetData[0].attributes);
        }
      })();
    }
  }, [location]);

  useEffect(() => {
    if (refreshDashboardFolders === true) {
      fetchFolderProjectList();
      setRefreshDashboardFolders(false);
    }
  }, [refreshDashboardFolders]);

  useEffect(() => {
    if (userDetails !== null) {
      setOrgDetails(userDetails.organization_info ?? []);
      if (folderProjectList?.length === 0) {
        (async () => {
          fetchFolderProjectList();
        })();
      }
    }
  }, [userDetails]);
  useEffect(() => {
    if (!isFoundOrg()) {
      window.history.pushState(
        {},
        '',
        '/org/' + currentOrganization?.org_key + '/dashboard',
      );
    }
  }, [location]);

  useEffect(() => {
    setExpandedNavBar(navExpanded);
  }, [navExpanded]);

  const tempPageDataSet = async (tmpPageData: any, pageSlug: string[]) => {
    if (!tmpPageData) {
      await cmsService.fetchCmsData(cmsData, setCmsData, cmsPageName, pageSlug);
      tmpPageData = getPageSpecificData(cmsData, pageSlug[0]);
      tmpPageData && setPageCmsData(tmpPageData[0].attributes);
    } else {
      tmpPageData && setPageCmsData(tmpPageData[0].attributes);
    }
  };

  const profileDataSet = async (profileSetData: any, pageSlug: string[]) => {
    if (!profileSetData) {
      await cmsService.fetchCmsData(cmsData, setCmsData, cmsPageName, pageSlug);
      profileSetData = getPageSpecificData(cmsData, 'profile-setting');
      profileSetData && setProfileSettingLabel(profileSetData[0].attributes);
    } else {
      profileSetData && setProfileSettingLabel(profileSetData[0].attributes);
    }
  };

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setLogoutSwitch(false);
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  function settingButtonClickEvent(e: any) {
    setLogoutSwitch(!logoutSwitch);
    setMoreOrgListVisible(false);
  }

  const generateUserAvatar = (userDetails: IUserDetails) => {
    if (!userDetails?.profile_picture) {
      let nameSplit = userDetails?.full_name?.trim()?.split(' ');

      if (nameSplit?.length) {
        if (nameSplit?.length === 1) {
          return { key: 'string', value: `${nameSplit[0]?.charAt(0)}` };
        } else {
          return {
            key: 'string',
            value: `${nameSplit[0]?.charAt(0)}${nameSplit[
              nameSplit?.length - 1
            ]?.charAt(0)}`,
          };
        }
      } else {
        return {
          key: 'string',
          value: `${userDetails?.email?.charAt(0).toLocaleUpperCase()}`,
        };
      }
    } else {
      return { key: 'url', value: userDetails?.profile_picture };
    }
  };

  const addFolder = () => {
    setNewFolderDetails({
      ...newFolderDetails,
      visibility: true,
    });
  };

  const fetchFolderProjectList = async () => {
    try {
      let userDetails = browserStorage.getUserDetails();

      let payload = {
        organizationId: getCurrentOrganization(
          org_key,
          userDetails?.organization_info,
        )?.organization_id!,
        userId: userDetails?.user_id,
      };

      let response: IDashboardApiResponse =
        await dashboardService.getProjectFolder(payload);

      let list: IProjectFolderDetails[] = response.data.project_folder_list;
      let projectList: IProjectList[] = response.data.project_list;
      if (list) {
        setFolderProjectList(list);
      }

      if (projectList) {
        setProjectList(projectList);
      }
    } catch (err) {
      setLoaderState('inactive');
      await loggerService.log({
        severity: 'High',
        message: `Folder list get action failed`,
        payload: { ApiResponse: err },
      });
    } finally {
      setLoaderState('inactive');
    }
  };

  const changeFolderName = async (): Promise<void> => {
    if (newFolderDetails.name.trim() !== '') {
      try {
        const response: any = await dashboardService.addEditFolder({
          folderId: 0,
          folderName: newFolderDetails.name,
          organizationId: getCurrentOrganization(org_key, orgDetails)
            ?.organization_id!,
          userId: userDetails?.user_id!,
        });

        if (response.messageId === 1) {
          let newFolderList = generateNewListFromCreateFolder(
            response.data.project_folder_list,
            folderProjectList ?? [],
          );
          setFolderProjectList(newFolderList);
          setFolderList(response.data.folder_list);
          setNewFolderDetails({
            name: '',
            visibility: false,
          });
        }

        if (response.messageId === -2) {
          document
            .getElementById('addFolderInputField')
            ?.classList.add('duplicateError');
        }

        if (response.messageId === -1) {
          await loggerService.log({
            severity: 'High',
            message: 'folder creation failed',
            payload: 'DB Error',
          });
        }
      } catch (error) {
        await loggerService.log({
          severity: 'High',
          message: 'folder creation failed',
          payload: error,
        });
      }
    } else {
      setNewFolderDetails({
        name: '',
        visibility: false,
      });
    }
  };

  return (
    <Fragment>
      {contextHolder}
      <div className="leftNav">
        <div className={`expander ${navExpanded ? 'collapsedExp' : ''}`}>
          <Tooltip
            title={
              navExpanded
                ? pageCmsData?.lbl_expand_tooltip
                : pageCmsData?.lbl_collapse_tooltip
            }
            color={'#2E364C'}
            placement="right"
          >
            <span
              onClick={() => {
                setNavExpanded((pre) => !pre);
                if (calendarRef) {
                  setTimeout(() => {
                    calendarRef?.current?.getApi()?.updateSize();
                  }, 300);
                }
              }}
              onKeyDown={() => {}}
              className="material-icons-outlined"
            >
              menu
            </span>
          </Tooltip>
        </div>

        <DefaultItemViewForProd
          pageCmsData={pageCmsData}
          navExpanded={navExpanded}
        />

        <AllOrganizationLeftNav
          orgDetails={orgDetails}
          currentOrganization={currentOrganization!}
          setLogoutSwitch={setLogoutSwitch}
          moreOrgListVisible={moreOrgListVisible}
          setMoreOrgListVisible={setMoreOrgListVisible}
          settingLblCms={profileSettingLabel!}
          navExpanded={navExpanded}
          pageCmsData={pageCmsData}
        />
        <h1 className="siteLogo">
          <span aria-label="Cleversort" />
        </h1>
        {userDetails && (
          <div
            className="userBlkWrap profileSettingsPopup"
            onClick={settingButtonClickEvent}
            onKeyDown={() => {}}
          >
            <div
              className={`userAvtr ${
                generateUserAvatar(userDetails)?.key === 'url'
                  ? 'userAvtrImg'
                  : ''
              }`}
            >
              {generateUserAvatar(userDetails)?.key === 'string' ? (
                generateUserAvatar(userDetails)?.value
              ) : (
                <img src={generateUserAvatar(userDetails)?.value} alt="" />
              )}
            </div>
            {logoutSwitch ? (
              <ul className="genericPopup show" ref={wrapperRef}>
                <li
                  onClick={() => {
                    browserStorage.setLastUrl(location.pathname);
                    document
                      .getElementById('rightSideDrawerCloseIcon')
                      ?.click();
                    navigate(`/org/${org_key}/users/settings`);
                  }}
                  onKeyDown={() => {}}
                >
                  <span className="cmnIcon profile-setting"></span>
                  {profileSettingLabel?.lbl_profile_settings}
                </li>
                <li onClick={goToTeamManagement} onKeyDown={() => {}}>
                  <span className="cmnIcon manage-team"></span>
                  {profileSettingLabel?.lbl_manage_team}
                </li>
                {/* <li>
                  <span className="cmnIcon help"></span>
                  Help
                </li> */}
                <li
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_PLATFORM_URL}/privacy-policy`,
                      '_blank',
                    )
                  }
                  onKeyDown={() => {}}
                >
                  <span className="cmnIcon privacy-policy"></span>
                  {profileSettingLabel?.lbl_privacy_policy}
                </li>
                <li
                  onClick={() => {
                    localStorage.removeItem('latestPathName');
                    localStorage.removeItem('selectedThreadId');
                    localStorage.removeItem('currentMessageId');
                    localStorage.removeItem('project_members');
                    sessionStorage.removeItem('lastLocation');
                    browserStorage.clearGlobalData();
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    });
                  }}
                  onKeyDown={() => {}}
                >
                  <span className="cmnIcon logOut"></span>
                  <div>
                    {pageCmsData?.lbl_menu_logout}
                    <p>
                      {userDetails?.email?.length <= 40
                        ? userDetails?.email
                        : `${userDetails?.email?.slice(0, 40)}...`}
                    </p>
                  </div>
                </li>
              </ul>
            ) : null}
          </div>
        )}
      </div>
      <div
        className={`secondPanel${navExpanded ? ' collapsedSecondPanel' : ''}`}
      >
        <div className="companyHeadingPanel">
          <Tooltip
            title={currentOrganization?.organization_name}
            color={'#2E364C'}
            placement={'bottomLeft'}
          >
            <h2>{currentOrganization?.organization_name}</h2>
          </Tooltip>
          {/* <div className="iconList">
            <span className="search"></span>
          </div> */}
        </div>
        <div className="itemList projectItemList">
          <div className="sectionHeading">
            {pageCmsData?.lbl_menu_projects}
            <Tooltip
              title={pageCmsData?.btn_add_new_folder}
              color={'#2E364C'}
              placement="top"
            >
              <span
                className="addPlus"
                onClick={() => addFolder()}
                onKeyDown={() => {}}
              ></span>
            </Tooltip>
          </div>
          {!navExpanded ? (
            <FolderProjectExpandedView
              pageCmsData={pageCmsData}
              orgDetails={orgDetails}
            />
          ) : null}

          {!newFolderDetails.visibility ? null : (
            <div className="itemNameBlk addFolder">
              <input
                className="addFolderInput"
                id="addFolderInputField"
                maxLength={20}
                value={newFolderDetails.name}
                placeholder={pageCmsData?.lbl_default_folder_name}
                onChange={(e) =>
                  setNewFolderDetails({
                    ...newFolderDetails,
                    name: e.target.value,
                  })
                }
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    (async () => {
                      await changeFolderName();
                    })();
                  }
                }}
                onBlur={(e) => {
                  (async () => await changeFolderName())();
                }}
                autoFocus
              />
            </div>
          )}
        </div>
        <Rbac allowedPermissions={[ERbacPermissions.PROJECT_CREATE]}>
            <span
              className="createNewProjectCTA"
              onClick={() => {
                document.body.classList.remove("body-overlay");
                document.getElementById("rightSideDrawerCloseIcon")?.click();
                navigate(
                  `/org/${location.pathname.split("/")[2]}/${NavRoutesEnum.DASHBOARD_PROJECT_LIST}`
                );
              }}
              onKeyDown={() => {}}
            >
              <span className="addPlusSolid"></span>
              {pageCmsData?.btn_create_project}
            </span>
        </Rbac>
      </div>
    </Fragment>
  );
};
