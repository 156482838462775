import { Checkbox, Form, Select } from 'antd';
import { IProjectState } from 'models/interface';
import { useProjectDashboardStore } from 'stores';

const DateFormatForm = ({
  setFieldValue,
  form,
  selectedDateFormat,
  date_formats,
}: any) => {
  const { customfieldCmsData, masterDataOptionalFields } =
    useProjectDashboardStore((state: IProjectState) => state);

  return (
    <>
      <p className="dateSettingsHeading popupHeading">
        {
          customfieldCmsData?.lbl_column_date_time_settings
            ?.lbl_date_settings_header
        }
      </p>
      <label className="labelFontSize-L">
        {
          customfieldCmsData?.lbl_column_date_time_settings
            ?.lbl_date_settings_format
        }
      </label>
      <Form.Item name="selectedDateFormat">
        <Select
          defaultValue={selectedDateFormat ?? 1}
          onChange={(value) => {
            setFieldValue('selectedDateFormat', value);
          }}
          options={masterDataOptionalFields?.date?.date_formats?.map(
            (ele: any) => ({
              id: ele.id,
              label: ele?.description,
              value: ele?.id,
            }),
          )}
        />
      </Form.Item>

      <Form.Item name="showTime" valuePropName="checked">
        <Checkbox
          onChange={(e) => {
            const checked = e.target.checked;
            setFieldValue('showTime', checked);
          }}
        >
          {
            customfieldCmsData?.lbl_column_date_time_settings
              ?.lbl_date_settings_show_time
          }
        </Checkbox>
      </Form.Item>
    </>
  );
};

export default DateFormatForm;
