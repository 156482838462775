import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input, Popover, TimePicker, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import CCalendar from 'components/sharedComponents/CDatePicker/CCalendar';
import { getUserGravatar } from 'utils/commonFunctions';
import BrowserStorageService from 'services/browserStorage.service';
import { IProjectMember, IProjectState } from 'models/interface';
import { useProjectDashboardStore } from 'stores';
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { EffortsFieldEnum } from './EffortField';

const EffortsField: React.FC<{
  type: 'taskList' | 'gridList';
  value: any[];
  taskDetail?: any;
  onSave: (efforts: any, effortLogged: any) => void;
  disabled: boolean | undefined;
  gridListDetails?: any;
}> = ({ type, value, taskDetail, onSave, disabled, gridListDetails }) => {
  const [totalHour, setTotalHour] = useState(0);
  const [effortsData, setEffortsData] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [openCalenderIndex, setOpenCalenderIndex] = useState<number | null>(
    null,
  );
  const [description, setDescription] = useState<string>('');
  const [descriptionEditingIndex, setDescriptionEditingIndex] = useState<
    number | null
  >(null);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  // const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [error, setError] = useState('');

  // const location = useLocation();
  // const org_key = location.pathname.split('/')[2];
  const browserStorage = BrowserStorageService.getInstance();
  const userDetails = browserStorage.getUserDetails();

  const { projectDetails, customfieldCmsData, projectMembers } =
    useProjectDashboardStore((state: IProjectState) => state);

  const { hasPermissions } = useRbac();
  const allowedToEdit = hasPermissions(
    [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
    projectDetails?.associated_role_id,
  );

  const customCms = customfieldCmsData?.lbl_efforts;

  const defaultTime = EffortsFieldEnum.EMPTY_EFFORTS_FIELD;

  // const getTaskName = () => {
  //   if (type === 'taskList') {
  //     return (taskName = taskDetail?.task_name);
  //   } else if (type === 'gridList') {
  //     return (taskName = gridListDetails.grid_list_details.task_details.find(
  //       (ele: any) => ele.task_id === taskDetail.task_id,
  //     ).task_name);
  //   }
  //   return (taskName = customCms?.lbl_effort_title);
  // };

  const getTaskName = () => {
    if (type === 'taskList') {
      return taskDetail?.task_name || customCms?.lbl_effort_title;
    }

    if (type === 'gridList') {
      const task = gridListDetails?.grid_list_details?.task_details?.find(
        (ele: any) => ele.task_id === taskDetail.task_id,
      );
      return task ? task.task_name : customCms?.lbl_effort_title;
    }

    return customCms?.lbl_effort_title;
  };

  // const filterEffortsFieldData = taskDetail?.custom_data?.filter(
  //   (ele: any) => ele?.mapping_id === config?.mapping_id,
  // );

  const mainTotalLoggedHour = () => {
    if (!totalHour) {
      return <p className="CFCustomPlaceholder">{defaultTime}</p>;
    } else {
      return (
        <Tooltip
          title={formatDuration(totalHour)}
          color={'#2E364C'}
          placement="left"
        >
          {formatDuration(totalHour)}
        </Tooltip>
      );
    }
  };

  const reset = () => {
    setEffortsData(value);
    setIsOpen(false);
    setOpenCalenderIndex(null);
    setEditingIndex(null);
    setError('');
  };

  const deepEqual = (arr1: any[], arr2: any[]) => {
    if (arr1?.length !== arr2?.length) return false;
    return arr1?.every((obj1, index) => {
      const obj2 = arr2?.[index];
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1?.length !== keys2?.length) return false;
      return keys1?.every((key) => {
        return obj1?.[key] === obj2?.[key];
      });
    });
  };

  const checkTotalDuration = () => {
    let totalDurationGridList = taskDetail?.total_duration_in_minutes;
    let isCustomField = taskDetail?.custom_field_block_value?.length > 0;

    if (totalDurationGridList === 0) {
      if (isCustomField) {
        const totalMinutes = effortsData
          ?.filter((effort: any) => !effort?.should_delete)
          ?.reduce((sum: number, effort: any) => {
            return sum + (effort?.duration_in_minutes || 0);
          }, 0);

        return totalMinutes;
      } else {
        return 0;
      }
    }
  };

  const formatDuration = (totalMinutes: number): string => {
    const hours = Math.floor(totalMinutes / 60)
      .toString()
      .padStart(2, '0');
    const minutes = (totalMinutes % 60).toString().padStart(2, '0');
    return `${hours}h ${minutes}m`;
  };

  const getTotalMinutes = () => {
    const totalMinutes = effortsData
      ?.filter((effort: any) => !effort?.should_delete)
      ?.reduce((sum: number, effort: any) => {
        return sum + (effort.duration_in_minutes || 0);
      }, 0);

    return formatDuration(totalMinutes);
  };

  let removeEmptyDurationFromPayload = effortsData?.filter(
    (ele: any) => ele?.duration_in_minutes !== null,
  );

  const saveEffort = async () => {
    // setIsFetching(true);
    const addEffort: any = [];
    const deleteEffort: any = [];
    const modifyEffort: any = [];

    removeEmptyDurationFromPayload?.forEach((effort: any) => {
      if (effort?.should_delete) {
        return deleteEffort.push(effort);
      }

      if (!effort?.id) {
        return addEffort.push(effort);
      }

      const originalEffort = value?.find((o: any) => o.id === effort.id);

      if (originalEffort) {
        const hasDurationChanged =
          originalEffort.duration_in_minutes !== effort.duration_in_minutes;
        const hasDateChanged = originalEffort.date !== effort.date;
        const hasDescriptionChanged =
          originalEffort.description?.trim() !== effort.description?.trim();

        if (hasDurationChanged || hasDateChanged || hasDescriptionChanged) {
          modifyEffort.push(effort);
        }
      }
    });

    onSave?.(
      { deleteEffort, addEffort, modifyEffort },
      {
        effortLogged: removeEmptyDurationFromPayload.filter(
          (effort: any) => !effort?.should_delete,
        ),
      },
    );

    reset();
  };

  dayjs.extend(isToday);
  dayjs.extend(isTomorrow);
  dayjs.extend(isYesterday);

  const formatDate = (dateString: any) => {
    const givenDate = dayjs(dateString, 'DD/MM/YYYY');
    if (givenDate.isToday()) {
      return 'Today';
    } else if (givenDate.isTomorrow()) {
      return 'Tomorrow';
    } else if (givenDate.isYesterday()) {
      return 'Yesterday';
    } else {
      return givenDate.format('DD MMM YYYY');
    }
  };

  // const hourMinutesConvertor = (time: any) => {
  //   if (time) {
  //     const hours = time.hour();
  //     const minutes = time.minute();
  //     const timeFormatted = `${hours}h ${minutes > 0 ? `${minutes}m` : ''}`;
  //     const totalMinutes = hours * 60 + minutes;
  //     return { timeFormatted, totalMinutes };
  //   }
  //   return { timeFormatted: '', totalMinutes: null };
  // };

  // const hourMinutesConvertor = (time: any) => {
  //   if (!time) {
  //     return { timeFormatted: '', totalMinutes: null };
  //   }

  //   const hours = time.hour();
  //   const minutes = time.minute();
  //   const timeFormatted = `${hours}h${minutes > 0 ? ` ${minutes}m` : ''}`;
  //   const totalMinutes = hours * 60 + minutes;

  //   return { timeFormatted, totalMinutes };
  // };

  const hourMinutesConvertor = (time: any) => {
    if (!time) {
      return { timeFormatted: '', totalMinutes: null };
    }

    const hours = time.hour();
    const minutes = time.minute();

    let timeFormatted = `${hours}h`;
    if (minutes > 0) {
      timeFormatted += ` ${minutes}m`;
    }

    const totalMinutes = hours * 60 + minutes;

    return { timeFormatted: timeFormatted.trim(), totalMinutes };
  };

  const onTimeChange = (time: any, _timeString: any, index: any) => {
    if (time) {
      const { totalMinutes } = hourMinutesConvertor(time);
      const newEfforts = [...effortsData];
      const selectedEffort = newEfforts[index];
      let selectedDate = selectedEffort.date;
      let existingMinute = selectedEffort.duration_in_minutes;

      let hourPerDate = newEfforts
        .filter((ele: any) => ele.date === selectedDate && !ele?.should_delete)
        .reduce((sum: number, effort: any) => {
          return sum + (effort.duration_in_minutes || 0);
        }, 0);

      if (
        hourPerDate + totalMinutes - (selectedEffort.duration_in_minutes || 0) <
        1440
      ) {
        if (totalMinutes === 0 && !selectedEffort.id) {
          selectedEffort.duration_in_minutes = null;
        } else if (totalMinutes === 0 && selectedEffort.id) {
          selectedEffort.duration_in_minutes = existingMinute;
        } else {
          selectedEffort.duration_in_minutes = totalMinutes;
          setError('');
        }
      } else {
        selectedEffort.duration_in_minutes = existingMinute ?? null;
        setEditingIndex(index);
        setError(customCms?.lbl_err_time);
      }
      setEffortsData(newEfforts);
    }
  };

  const onMainOpenChange = (visible: boolean) => {
    if (visible) {
      setError('');
      setOpenCalenderIndex(null);
      setIsOpen(visible);
    } else if (visible === false) {
      setError('');
      setOpenCalenderIndex(null);
      setTimeout(() => {
        setIsOpen(false);
      }, 100);
    }
  };

  const onChildOpenChange = (visible: boolean, index: any) => {
    if (visible) {
      setOpenCalenderIndex(index);
    } else {
      setTimeout(() => {
        setOpenCalenderIndex(null);
      }, 100);
    }
  };

  // const saveButtonContent = isFetching ? (
  //   <ButtonLoader />
  // ) : (
  //   customCms?.lbl_effort_save
  // );

  let isEditable = allowedToEdit;

  const handleDescriptionSave = () => {
    setDescriptionEditingIndex(null);
  };

  const getPopoverUserAvatarClassName = (
    eachMember: IProjectMember | null | undefined,
  ) => {
    if (eachMember?.inactive) {
      return 'userAvtrBlank';
    }
    return eachMember?.profile_picture ? 'userAvtrImg' : '';
  };

  const getPopoverUserAvatarJsx = (
    eachMember: IProjectMember | null | undefined,
  ) => {
    if (eachMember?.inactive) {
      return null;
    }
    return (
      <Tooltip
        title={eachMember?.full_name || eachMember?.email}
        color={'#2E364C'}
      >
        {eachMember?.profile_picture ? (
          <img alt="user avatar" src={eachMember?.profile_picture}></img>
        ) : (
          getUserGravatar(eachMember?.full_name ?? eachMember?.email!)
        )}
      </Tooltip>
    );
  };

  const LogLists2 = useMemo(() => {
    return effortsData?.map((ele: any, index: any) => {
      if (ele?.should_delete) return null;

      const memberDetails = projectMembers?.filter(
        (member: IProjectMember) => member.user_id === ele.user_id,
      )?.[0];

      return (
        <div key={ele.id || index} className="timeDateAvatar">
          <div className="effortFldTimeDate d-flex">
            {!ele.duration_in_minutes || editingIndex === index ? (
              <TimePicker
                autoFocus
                onChange={(time, timeString) =>
                  onTimeChange(time, timeString, index)
                }
                value={
                  ele.duration_in_minutes !== null
                    ? dayjs()
                        .set('hour', Math.floor(ele.duration_in_minutes / 60))
                        .set('minute', ele.duration_in_minutes % 60)
                    : null
                }
                format={EffortsFieldEnum.FORMAT}
                suffixIcon={false}
                size="middle"
                showNow={false}
                hourStep={1}
                placement="bottomLeft"
                onOk={() => setEditingIndex(null)}
                changeOnScroll
                className="effortFieldTimePicker"
              />
            ) : (
              <span
                onClick={() => {
                  if (isEditable) {
                    setEditingIndex(index);
                  }
                }}
                className="effortFieldTimePicker"
              >
                <Tooltip
                  title={formatDuration(ele.duration_in_minutes)}
                  color={'#2E364C'}
                >
                  {formatDuration(ele.duration_in_minutes)}
                </Tooltip>
              </span>
            )}
            <div
              style={{ pointerEvents: isEditable ? 'auto' : 'none' }}
              className="effortFieldCalendar"
            >
              <Popover
                placement="top"
                className="dateAvatarCalendarPopup"
                content={
                  <CCalendar
                    value={selectedDate}
                    onChange={(date) => {
                      if (date) {
                        setSelectedDate(date);
                      }
                    }}
                    onCancel={() => setOpenCalenderIndex(null)}
                    onOk={() => {
                      if (selectedDate) {
                        onDateOk(index);
                      }
                      setOpenCalenderIndex(null);
                    }}
                    onClear={() => setOpenCalenderIndex(null)}
                    open={true}
                    disableNextDate={true}
                  />
                }
                trigger="click"
                overlayClassName="gridAssigneeListPopOver"
                open={isOpen && openCalenderIndex === index}
                onOpenChange={(visible) => onChildOpenChange(visible, index)}
              >
                <div
                  onClick={() => {
                    if (isEditable) {
                      setEditingIndex(null);
                      setOpenCalenderIndex(index);
                      setSelectedDate(dayjs(ele.date, 'DD/MM/YYYY'));
                    }
                  }}
                >
                  <Tooltip title={formatDate(ele.date)} color={'#2E364C'}>
                    {formatDate(ele.date)}
                  </Tooltip>
                </div>
              </Popover>
            </div>
          </div>
          <div className="dateAvatar">
            <div className="effortFieldDescription">
              {!ele?.description ||
              !ele?.description?.length ||
              descriptionEditingIndex === index ? (
                <Input.TextArea
                  value={ele?.description}
                  autoFocus={descriptionEditingIndex === index}
                  maxLength={150}
                  rows={2}
                  onChange={(e) => {
                    setEffortsData((prevData: any) => {
                      const newEfforts = [...prevData];
                      newEfforts[index].description = e.target.value;
                      return newEfforts;
                    });
                  }}
                  onClick={() => setDescriptionEditingIndex(index)}
                  onPressEnter={handleDescriptionSave}
                  onBlur={handleDescriptionSave}
                  placeholder={customCms?.lbl_effort_description}
                />
              ) : (
                <Tooltip title={ele?.description} color={'#2E364C'}>
                  <span
                    onClick={() => {
                      setDescriptionEditingIndex(index);
                      setDescription(ele?.description);
                    }}
                  >
                    {ele?.description}
                  </span>
                </Tooltip>
              )}
            </div>
            <div
              className={`userAvtr ${getPopoverUserAvatarClassName(
                memberDetails,
              )}`}
            >
              {getPopoverUserAvatarJsx(memberDetails)}
            </div>
            <span
              className="material-icons-outlined close"
              onClick={() => {
                if (isEditable) {
                  deleteEffortField(index);
                  setError('');
                }
              }}
            >
              close
            </span>
          </div>
        </div>
      );
    });
  }, [
    descriptionEditingIndex,
    editingIndex,
    openCalenderIndex,
    selectedDate,
    onTimeChange,
    description,
  ]);

  const content = (
    <div>
      <h3>{getTotalMinutes() ? getTotalMinutes() : defaultTime}</h3>
      {LogLists2}
      {error && <span className="universalErr">{error}</span>}
      <div className="d-flex addEffortFieldCTASec">
        <button
          onClick={() => {
            if (isEditable) {
              setEditingIndex(null);
              setError('');
              addEffortField();
            }
          }}
          // disabled={isFetching || disabled}
          disabled={disabled}
          className="addTimeBtn"
        >
          <span className="addPlusSolid"></span>
          {customCms?.lbl_effort_add}
        </button>
        <div className="d-flex">
          <button
            className="noStyle"
            // disabled={isFetching || disabled}
            disabled={disabled}
            onClick={() => {
              if (isEditable) {
                reset();
              }
            }}
          >
            {customCms?.lbl_effort_cancel ?? 'Cancel'}
          </button>
          <button
            className="btnStyle1"
            // disabled={isFetching || disabled || !isChanged}
            disabled={disabled || !isChanged}
            onClick={() => {
              if (isEditable) {
                saveEffort();
              }
            }}
          >
            {customCms?.lbl_effort_save}
          </button>
        </div>
      </div>
    </div>
  );

  const deleteEffortField = useCallback(
    async (i: number) => {
      const newEfforts = [...effortsData];
      const effortToDelete = newEfforts[i];

      if (effortToDelete?.id) {
        newEfforts[i] = { ...effortToDelete, should_delete: true };
      } else {
        newEfforts.splice(i, 1);
      }
      setTimeout(() => setEffortsData([...newEfforts]), 100);
    },
    [effortsData, setEffortsData],
  );

  const addEffortField = useCallback(() => {
    setDescriptionEditingIndex(null);
    const newField = {
      date: dayjs().format('DD/MM/YYYY'),
      duration_in_minutes: null,
      user_id: userDetails?.user_id,
      description: '',
    };
    setEffortsData((prev: any) => [...prev, newField]);
  }, [userDetails]);

  const onDateOk = useCallback(
    (i: number) => {
      if (!selectedDate) return;
      const updateEffortsDate = [...effortsData];
      const newDate = selectedDate.format('DD/MM/YYYY');
      const selectedEffort = updateEffortsDate[i];
      let duration = selectedEffort?.duration_in_minutes || 0;
      let currentDate = selectedEffort?.date;
      let hourPerDate = updateEffortsDate
        ?.filter((ele: any) => ele.date === newDate && !ele?.should_delete)
        ?.reduce(
          (sum: number, effort: any) => sum + (effort.duration_in_minutes || 0),
          0,
        );

      const totalHourPerDay = hourPerDate + duration;

      if (totalHourPerDay < 1440) {
        updateEffortsDate[i].date = newDate;
        setError('');
      } else {
        updateEffortsDate[i].date = currentDate;
        setError(customCms?.lbl_err_date);
      }

      setEffortsData(updateEffortsDate);
      setOpenCalenderIndex(null);
    },
    [selectedDate],
  );

  useEffect(() => {
    if (value) {
      setEffortsData(JSON.parse(JSON.stringify(value)));
    } else {
      const initialEffort = [
        {
          date: dayjs().format('DD/MM/YYYY'),
          duration_in_minutes: null,
          user_id: userDetails?.user_id,
          description: '',
        },
      ];
      setEffortsData(initialEffort);
    }
  }, [value, isOpen]);

  useEffect(() => {
    let totalDuration = 0;
    if (value?.length) {
      for (const data of value) {
        totalDuration += data.duration_in_minutes;
      }
    }
    setTotalHour(totalDuration);
  }, [value, checkTotalDuration]);

  // useEffect(() => {
  //   if (value) {
  //     if (deepEqual(value, removeEmptyDurationFromPayload)) {
  //       setIsChanged(false);
  //     } else {
  //       setIsChanged(true);
  //     }
  //   } else {
  //     if (
  //       effortsData?.length > 0 &&
  //       effortsData?.[0]?.duration_in_minutes !== null
  //     ) {
  //       setIsChanged(true);
  //     } else {
  //       setIsChanged(false);
  //     }
  //   }
  // }, [effortsData]);

  useEffect(() => {
    const hasValue = value !== undefined && value !== null;

    if (hasValue) {
      setIsChanged(!deepEqual(value, removeEmptyDurationFromPayload));
    } else {
      const hasEffortsData = effortsData?.length > 0;
      const hasValidDuration =
        hasEffortsData && effortsData[0]?.duration_in_minutes !== null;

      setIsChanged(hasValidDuration);
    }
  }, [effortsData, value]);

  return (
    <Popover
      placement="bottom"
      overlayClassName="addEffortFieldPopup"
      title={getTaskName() ?? customCms?.lbl_effort_title}
      trigger={'click'}
      content={content}
      open={isOpen}
      onOpenChange={(open) => {
        onMainOpenChange(open);
      }}
    >
      {mainTotalLoggedHour()}
    </Popover>
  );
};

export default EffortsField;
