import { Popover, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import CCalendar from 'components/sharedComponents/CDatePicker/CCalendar';
import dayjs, { Dayjs } from 'dayjs';
import { eventEmitter } from '../../../../../../../../services/EventEmitter/eventemitter.service';
import { useProjectDashboardStore } from 'stores';
import { IProjectState } from 'models/interface';
import { detectDateFormat } from 'utils/commonFunctions';
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';

type DateTimeValue = {
  Date: string;
  Time: string;
};

interface IDateTimeFieldProps {
  onSave: (value: any) => void;
  dateTimeValue: DateTimeValue;
  config: any;
}

const DateTimeField: React.FC<IDateTimeFieldProps> = ({
  onSave,
  dateTimeValue,
  config,
}) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [formattedAndStoredDate, setFormattedAndStoredDate] =
    useState<string>('');
  const [openCalender, setOpenCalender] = useState<boolean>(false);
  const [selectedTimeString, setSelectedTimeString] = useState<string>('');
  const [selectedDateString, setSelectedDateString] = useState<string>('');
  const { hasPermissions } = useRbac();

  const { customfieldCmsData, projectDetails, masterDataOptionalFields } =
    useProjectDashboardStore((state: IProjectState) => state);

  let newFormat = masterDataOptionalFields?.date?.date_formats?.find(
    (itm: any) => itm.id === config?.selectedDateFormat,
  )?.value;

  let showTime = Boolean(config?.showTime);

  // const getRelativeDayString = (
  //   date: Dayjs,
  //   format: string,
  //   includeTime: boolean,
  // ): string => {
  //   const today = dayjs();
  //   const tomorrow = today.add(1, 'day');
  //   const yesterday = today.subtract(1, 'day');

  //   switch (true) {
  //     case date.isSame(today, 'day'):
  //       return `${
  //         customfieldCmsData?.lbl_column_date_time_settings
  //           ?.lbl_date_settings_today_text
  //       }${includeTime ? `, ${date.format('hh:mm A')}` : ''}`;
  //     case date.isSame(tomorrow, 'day'):
  //       return `${
  //         customfieldCmsData?.lbl_column_date_time_settings
  //           ?.lbl_date_settings_tomorrow_text
  //       }${includeTime ? `, ${date.format('hh:mm A')}` : ''}`;
  //     case date.isSame(yesterday, 'day'):
  //       return `${
  //         customfieldCmsData?.lbl_column_date_time_settings
  //           ?.lbl_date_settings_yesterday_text
  //       }${includeTime ? `, ${date.format('hh:mm A')}` : ''}`;
  //     default:
  //       return date.format(format);
  //   }
  // };

  const getRelativeDayString = (
    date: Dayjs,
    format: string,
    includeTime: boolean,
  ): string => {
    const today = dayjs();
    const tomorrow = today.add(1, 'day');
    const yesterday = today.subtract(1, 'day');

    const dateLabels = {
      today:
        customfieldCmsData?.lbl_column_date_time_settings
          ?.lbl_date_settings_today_text,
      tomorrow:
        customfieldCmsData?.lbl_column_date_time_settings
          ?.lbl_date_settings_tomorrow_text,
      yesterday:
        customfieldCmsData?.lbl_column_date_time_settings
          ?.lbl_date_settings_yesterday_text,
    };

    let dateKey: 'today' | 'tomorrow' | 'yesterday' | null = null;

    if (date.isSame(today, 'day')) {
      dateKey = 'today';
    } else if (date.isSame(tomorrow, 'day')) {
      dateKey = 'tomorrow';
    } else if (date.isSame(yesterday, 'day')) {
      dateKey = 'yesterday';
    }

    const timeString = includeTime ? `, ${date.format('hh:mm A')}` : '';

    return dateKey
      ? `${dateLabels[dateKey]}${timeString}`
      : date.format(format);
  };

  const formatDateAndStore = (dateTime: Dayjs | null) => {
    let formattedDate = ' ';
    let includeTime =
      showTime === true && dateTimeValue && dateTimeValue?.Time !== null;
    let finalFormat = `${newFormat}${includeTime ? ', hh:mm A' : ''}`;
    if (dateTime !== null) {
      formattedDate = getRelativeDayString(dateTime, finalFormat, includeTime);
    }
    setFormattedAndStoredDate(formattedDate);
  };

  // const convertDateTimeObjectIntoDayJsObject = (dateTime: DateTimeValue) => {
  //   let dateFormat = detectDateFormat() ?? 'DD/MM/YYYY';
  //   let timeFormat = 'hh:mm A';
  //   let formattedValue: Dayjs | null;
  //   let formattedTimeString: string;
  //   let formattedDateString: string;

  //   if (dateTime.Date === null && dateTime.Time === null) {
  //     formattedValue = null;
  //     formattedTimeString = '';
  //     formattedDateString = '';
  //   } else {
  //     if (dateTime.Time !== null) {
  //       formattedValue = dayjs(`${dateTime.Date} ${dateTime.Time}`, [
  //         `DD/MM/YYYY${dateTime.Time ? ` ${timeFormat}` : ''}`,
  //       ]);
  //       formattedDateString = dayjs(`${dateTime.Date}`, ['DD/MM/YYYY']).format(
  //         `${dateFormat}`,
  //       );
  //       formattedTimeString = dateTime.Time;
  //     } else {
  //       formattedValue = dayjs(`${dateTime.Date}`, ['DD/MM/YYYY']);
  //       formattedDateString = dayjs(`${dateTime.Date}`, ['DD/MM/YYYY']).format(
  //         `${dateFormat}`,
  //       );
  //       formattedTimeString = '';
  //     }
  //   }
  //   formatDateAndStore(formattedValue);
  //   setSelectedDate(formattedValue);
  //   setSelectedDateString(formattedDateString);
  //   setSelectedTimeString(formattedTimeString);
  // };

  const convertDateTimeObjectIntoDayJsObject = (dateTime: DateTimeValue) => {
    const dateFormat = detectDateFormat() ?? 'DD/MM/YYYY';
    const timeFormat = 'hh:mm A';

    let formattedValue: Dayjs | null;
    let formattedTimeString = '';
    let formattedDateString = '';

    if (dateTime.Date === null && dateTime.Time === null) {
      formattedValue = null;
    } else {
      const dateString = dateTime.Date;
      const timeString = dateTime.Time ? ` ${dateTime.Time}` : '';

      const combinedDateTimeString = `${dateString}${timeString}`;
      const formatString = `DD/MM/YYYY ${timeString ? timeFormat : ''}`.trim();

      formattedValue = dayjs(combinedDateTimeString, [formatString]);

      formattedDateString = dayjs(dateString, ['DD/MM/YYYY']).format(
        dateFormat,
      );
      formattedTimeString = dateTime.Time || '';
    }

    formatDateAndStore(formattedValue);
    setSelectedDate(formattedValue);
    setSelectedDateString(formattedDateString);
    setSelectedTimeString(formattedTimeString);
  };

  const setCalenderSupportedFormat = () => {
    if (dateTimeValue) {
      convertDateTimeObjectIntoDayJsObject(dateTimeValue);
    } else {
      setSelectedDate(null);
      setSelectedTimeString('');
      setFormattedAndStoredDate('');
    }
  };

  const resetComponentStates = () => {
    setOpenCalender(false);
  };

  const clearDates = () => {
    onSave({
      Date: null,
      Time: null,
    });
    setSelectedDate(null);
    setSelectedDateString('');
    setSelectedTimeString('');
    setOpenCalender(false);
  };
  const updateDateTimeStringValue = () => {
    if (selectedDate !== null) {
      let dateFormat = detectDateFormat() ?? 'DD/MM/YYYY';
      let formattedDateString: string = dayjs(selectedDate).format(dateFormat);
      setSelectedDateString(formattedDateString);
    }
  };

  const getContentJSX = () => {
    if (
      dateTimeValue === null ||
      dateTimeValue === undefined ||
      (dateTimeValue.Date === null && dateTimeValue.Time === null)
    ) {
      return (
        <span className="CFCustomPlaceholder">{`${newFormat}${
          showTime ? ', hh:mm AA' : ''
        }`}</span>
      );
    }

    return (
      <span className="dateTimeFetchedData">{formattedAndStoredDate}</span>
    );
  };

  const setDateIfTimeExists = () => {
    if (selectedTimeString !== '' && selectedDateString === '') {
      return dayjs().format('DD/MM/YYYY');
    } else {
      return selectedDateString === ''
        ? null
        : dayjs(selectedDate).format('DD/MM/YYYY');
    }
  };

  useEffect(() => {
    setCalenderSupportedFormat();
  }, [dateTimeValue, newFormat, showTime]);

  useEffect(() => {
    updateDateTimeStringValue();
  }, [selectedDate]);

  useEffect(() => {
    if (Boolean(openCalender) === false) {
      eventEmitter.emit('resetDateTimeInput');
    } else {
      setCalenderSupportedFormat();
    }
  }, [openCalender]);

  const allowedToEdit = hasPermissions(
    [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
    projectDetails?.associated_role_id,
  );

  return (
    <Popover
      placement="bottom"
      content={
        <CCalendar
          value={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          onCancel={() => setOpenCalender(false)}
          onOk={() => {
            onSave({
              Date: setDateIfTimeExists(),
              Time: selectedTimeString === '' ? null : selectedTimeString,
            });
            setOpenCalender(false);
          }}
          onClear={() => {
            clearDates();
          }}
          open={true}
          isUsedInCustomFields={true}
          timeString={selectedTimeString}
          dateString={selectedDateString}
          setSelectedDateString={setSelectedDateString}
          setSelectedTimeString={setSelectedTimeString}
          setSelectedDate={setSelectedDate}
          apiTimeValue={dateTimeValue?.Time}
        />
      }
      trigger="click"
      overlayClassName="gridAssigneeListPopOver"
      open={openCalender}
      onOpenChange={(event) => {
        if (event === false) {
          eventEmitter.emit('resetCalenderState');
          resetComponentStates();
        }
      }}
    >
      <div
        onClick={() => {
          if (allowedToEdit) {
            setOpenCalender(true);
          }
        }}
        className="addEditDateTimeFieldContent"
      >
        <Tooltip title={getContentJSX()}>{getContentJSX()}</Tooltip>
      </div>
    </Popover>
  );
};

export default DateTimeField;
