import React, { useEffect, useState } from 'react';
import {
  ICustomFieldModalAttributes,
  ICustomFieldState,
  IProjectState,
  ITaskList,
} from 'models/interface';
import { useProjectDashboardStore } from 'stores';
import AddTask from '../AddTask';
import { Rbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import Table from './TableSection/Table';

enum TaskColumnSortIds {
  TASK_STATUS = 'task_status_id',
  TASK_PRIORITY = 'task_priority_id',
  TASK_DUE_DATE = 'due_date',
  TASK_NAME = 'task_name',
  TASK_ASSIGNEE = 'assignee_name',
  TASK_UPDATED_ON = 'updated_on',
}

enum TaskColumnSortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

const TaskListDetails: React.FC<{
  taskDetails?: ITaskList;
  setTaskListDetails: React.Dispatch<React.SetStateAction<ITaskList>>;
  sectionToggleState: 'collapsed' | 'expanded';
  // socket?: any;
  setShowSidePanelForCustomField: React.Dispatch<
    React.SetStateAction<ICustomFieldState>
  >;
  getSectionDetails: () => Promise<void>;
  isTaskEmpty?: boolean;
  customfieldCmsData?: ICustomFieldModalAttributes;
  setIsTaskEmpty?: React.Dispatch<React.SetStateAction<boolean>>;
  forPnc?: boolean;
}> = ({
  taskDetails,
  setTaskListDetails,
  sectionToggleState,
  // socket,
  setShowSidePanelForCustomField,
  getSectionDetails,
  isTaskEmpty,
  customfieldCmsData,
  setIsTaskEmpty,
  forPnc,
}) => {
  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );

  return (
    <div className={` `}>
      <Table
        customfieldCmsData={customfieldCmsData}
        isTaskEmpty={isTaskEmpty}
        setIsTaskEmpty={setIsTaskEmpty}
        taskDetails={taskDetails}
        sectionToggleState={sectionToggleState}
        // socket={socket}
        setTaskListDetails={setTaskListDetails}
        forPnc={forPnc}
      />
      <Rbac
        allowedPermissions={[ERbacPermissions.PROJECT_SECTION_TASK_CREATE]}
        project_role_id={projectDetails?.associated_role_id}
      >
        {!projectDetails?.is_archived && !forPnc ? (
          <AddTask
            taskDetails={taskDetails}
            setTaskListDetails={setTaskListDetails}
          />
        ) : (
          <></>
        )}
      </Rbac>
    </div>
  );
};

export default TaskListDetails;
