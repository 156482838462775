import { Select, Tooltip } from 'antd';
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { IProjectState } from 'models/interface';
import React, { useEffect, useState } from 'react';
import { useProjectDashboardStore } from 'stores';
import { getContrastColor } from 'utils/commonFunctions';

interface IPriorityDropdownProps {
  options: any[];
  value: any;
  onChange: (value: any) => void;
  onSelect: (value: any) => void;
  disabled?: boolean;
}

const PriorityDropdown = (props: IPriorityDropdownProps) => {
  const { options, value, onChange, onSelect, disabled = false } = props;

  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );

  const [isEdit, setIsEdit] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const selectRef = React.useRef<any>(null);

  const currentValue = value
    ? options.find((i) => i.option_id === value) ?? {}
    : {};
  const color = currentValue.color ?? '#FFFFFF';
  const { hasPermissions } = useRbac();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target) &&
        !isDropdownOpen
      ) {
        setIsEdit(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef, isDropdownOpen]);

  const allowedToEdit = hasPermissions(
    [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
    projectDetails?.associated_role_id,
  );

  if ((isEdit || !value) && allowedToEdit) {
    return (
      <div ref={selectRef}>
        <Select
          style={{
            width: '100%',
          }}
          disabled={disabled}
          options={options}
          popupClassName="customSelect"
          value={value}
          open={isDropdownOpen}
          onChange={(_value, item) => {
            onChange(item);
            setIsEdit(false);
          }}
          onSelect={(_value, item) => {
            onSelect(item);
          }}
          labelRender={(option) => {
            return (
              <span style={{ background: option.value }}>{option.label}</span>
            );
          }}
          virtual={false}
          optionRender={(option, { index }) => {
            const color = options[index]?.color ?? '#FFFFFF';

            return (
              <Tooltip
                title={option.value}
                placement={'left'}
                color={'#2E364C'}
              >
                <div
                  className="customOption"
                  key={index}
                  style={{
                    background: color,
                    color: getContrastColor(color),
                  }}
                  title=""
                >
                  {option.value}
                </div>
              </Tooltip>
            );
          }}
          onDropdownVisibleChange={(open) => {
            setIsDropdownOpen(open);
            if (!open) setIsEdit(false);
          }}
          allowClear
        />
      </div>
    );
  }
  if (!value && !allowedToEdit) return null;
  return (
    <div
      onClick={() => {
        if (allowedToEdit && !disabled) {
          setIsEdit(true);
          setIsDropdownOpen(true);
        }
      }}
      className="statusCellValue"
      style={{
        background: color,
        color: getContrastColor(color),
      }}
    >
      <Tooltip title={currentValue?.value} placement="left" color={'#2E364C'}>
        {currentValue?.value}
      </Tooltip>
    </div>
  );
};

export default PriorityDropdown;
