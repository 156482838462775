import React, { useEffect, useLayoutEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/zh-cn';
import dayLocaleData from 'dayjs/plugin/localeData';
import { Button, Calendar, Select, Tooltip } from 'antd';
import './CCalendar.css';
import {
  CaretDownOutlined,
  LeftOutlined,
  RetweetOutlined,
  RightOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import {
  useGlobalStore,
  useLocalizationStore,
  useProjectDashboardStore,
} from 'stores';
import { IGlobalState, IProjectState } from 'models/interface';
import { getPageSpecificData } from 'utils/commonFunctions';
import DateTimeInputWithToggle from 'components/pages/DashboardPage/Project/Sections/TaskListV2/TableSection/CustomCells/DateTimeToggleFields';
import { eventEmitter } from '../../../services/EventEmitter/eventemitter.service';

dayjs.extend(dayLocaleData);

interface CCalendarProps {
  values?: { date: string }[];
  value?: Dayjs | null;
  color?: string;
  onOk?: () => void;
  onCancel?: () => void;
  onClear?: () => void;
  onChange?: (e: Dayjs) => void;
  open: boolean;
  isUsedInCustomFields?: boolean;
  timeString?: string;
  dateString?: string;
  setSelectedDateString?: React.Dispatch<React.SetStateAction<string>>;
  setSelectedTimeString?: React.Dispatch<React.SetStateAction<string>>;
  setSelectedDate?: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  disableNextDate?: boolean;
  apiTimeValue?: string | null;
}

const CCalendar = (props: CCalendarProps) => {
  const {
    value,
    values = [],
    color = 'cornflowerblue',
    open,
    onChange,
    onOk,
    onCancel,
    onClear,
    isUsedInCustomFields = false,
    timeString,
    dateString,
    setSelectedDateString,
    setSelectedTimeString,
    setSelectedDate,
    disableNextDate = false,
    apiTimeValue,
  } = props;

  const { taskListCmsData, gridListCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const { cmsData } = useLocalizationStore((state: any) => state);
  const dashBoardCMSData: any =
    getPageSpecificData(cmsData, 'page-dashboard-left-panel')?.[0]
      ?.attributes ?? {};
  const { antLocale } = useGlobalStore((state: IGlobalState) => state);

  const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs());
  const [calendarType, setCalendarType] = useState<'month' | 'year'>('month');

  const handleRemoveHighlightDates = (currentDate: Dayjs) => {
    if (calendarType === 'year') return;
    setTimeout(() => {
      const cells = document.getElementsByClassName('ant-picker-cell');
      const currentDateFormated = dayjs(currentDate).format('YYYY-MM-DD');
      const validHighlights = [
        dayjs().format('YYYY-MM-DD'),
        currentDateFormated,
        ...values.map((i) => i.date),
      ];

      for (const item of cells) {
        const itemValue = item.attributes?.[0]?.nodeValue ?? '';
        // @ts-ignore
        const bgColor = item.children[0].style.backgroundColor;

        if (!validHighlights.includes(itemValue)) {
          item.classList.remove('ant-picker-cell-selected');
          // @ts-ignore
          item.children[0].style.backgroundColor = '';
        }

        if (bgColor === color && currentDateFormated === itemValue) {
          // @ts-ignore
          item.children[0].style.backgroundColor = 'orangered';
        }
      }
    }, 1);
  };

  const handleSelectDate = (
    value: CCalendarProps['values'],
    currentDate: Dayjs,
  ) => {
    if (!value?.length) return;
    setTimeout(() => {
      for (const item of value) {
        const element = document.querySelectorAll(
          `[title="${item.date}"]`,
        )?.[0];

        if (element) {
          element.classList.add('ant-picker-cell-selected');
          // @ts-ignore
          element.children[0].style.backgroundColor = color;
        }
      }
    }, 1);
    handleRemoveHighlightDates(currentDate);
  };

  useLayoutEffect(() => {
    handleSelectDate(values, currentDate);
  }, [currentDate, values]);

  const resetCalenderType = () => {
    if (isUsedInCustomFields) {
      setCalendarType('month');
    }
  };

  const calenderOnChange = (e: any) => {
    const isChangeYear = e.diff(currentDate, 'year');

    setCurrentDate(e);
    if (onChange) onChange(e);
    if (calendarType === 'year' && !isChangeYear) {
      setCalendarType('month');
    }
  };

  const handleSelect = (e: any) => {
    if (value === null) {
      calenderOnChange(e);
    }
  };
  const disableDatesAfterToday = (current: dayjs.Dayjs) => {
    if (disableNextDate) {
      return current && current.isAfter(dayjs().endOf('day'));
    }
    return false;
  };
  useEffect(() => {
    eventEmitter.on('resetCalenderState', resetCalenderType);

    return () => {
      eventEmitter.off('resetCalenderState', resetCalenderType);
    };
  }, []);

  if (!open) return <></>;

  return (
    <div id="ccalendar" className="ccalendar">
      <Calendar
        fullscreen={false}
        headerRender={({ value, type, onChange, onTypeChange }) => {
          if (type !== calendarType) onTypeChange(calendarType);

          const start = 0;
          const end = 12;
          const monthOptions = [];

          let current = value.clone();
          const localeData = value.localeData();
          const months = [];
          for (let i = 0; i < 12; i++) {
            current = current.month(i);
            months.push(localeData.monthsShort(current));
          }

          for (let i = start; i < end; i++) {
            monthOptions.push(
              <Select.Option
                key={i}
                value={i}
                className="month-item"
                title={false}
              >
                {months[i]}
              </Select.Option>,
            );
          }

          const year = value.year();
          const options = [];
          for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
              <Select.Option
                key={i}
                value={i}
                className="year-item"
                title={false}
              >
                {i}
              </Select.Option>,
            );
          }

          const handleMoveDate = (moveType: 'left' | 'right' | 'back') => {
            const dateType = type === 'month' ? 'M' : 'year';
            const moveTo = dayjs(value)[
              moveType === 'left' ? 'subtract' : 'add'
            ](1, dateType);

            if (moveType === 'back') {
              if (type === 'year') onTypeChange('month');
              else onChange(dayjs());

              return;
            }

            if (type === 'month') onChange(moveTo);
            else {
              const now = value
                .clone()
                .year(year + (moveType === 'left' ? -1 : 1));
              onChange(now);
            }
          };

          return (
            <div className="header">
              {Boolean(isUsedInCustomFields) === false ? (
                <div className="ccHeadingTxt">
                  {value.format('ddd, MMM YYYY')}
                </div>
              ) : (
                <DateTimeInputWithToggle
                  dateString={dateString ?? ''}
                  timeString={timeString ?? ''}
                  setSelectedDateString={setSelectedDateString}
                  setSelectedTimeString={setSelectedTimeString}
                  setSelectedDate={setSelectedDate}
                  selectedDate={value}
                  apiTimeValue={apiTimeValue}
                />
              )}
              <section className="header-wrapper">
                <div className="date">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      if (type === 'month') onTypeChange('year');
                    }}
                    className="date-month-year"
                  >
                    <div>
                      {type === 'month' && <span>{value.format('MMM')} </span>}
                      {type === 'month' ? (
                        <span>{year}</span>
                      ) : (
                        <Select
                          value={year}
                          title={''}
                          onChange={(newYear) => {
                            const now = value.clone().year(newYear);
                            onChange(now);
                          }}
                        >
                          {options}
                        </Select>
                      )}
                    </div>
                    {type === 'month' && (
                      <CaretDownOutlined style={{ fontSize: 10 }} />
                    )}
                  </div>
                  <Tooltip title={type === 'month' ? 'Today' : 'Back'}>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMoveDate('back');
                      }}
                      icon={
                        type === 'month' ? (
                          <RetweetOutlined style={{ fontSize: 14 }} />
                        ) : (
                          <RollbackOutlined style={{ fontSize: 14 }} />
                        )
                      }
                      className="dateResetBtn"
                    />
                  </Tooltip>
                </div>
                <div className="date-controller">
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMoveDate('left');
                    }}
                    type="text"
                    icon={
                      <LeftOutlined style={{ fontSize: 10, strokeWidth: 30 }} />
                    }
                  ></Button>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMoveDate('right');
                    }}
                    type="text"
                    icon={<RightOutlined style={{ fontSize: 10 }} />}
                  ></Button>
                </div>
              </section>
            </div>
          );
        }}
        onPanelChange={(_, mode) => {
          setCalendarType(mode);
        }}
        onChange={calenderOnChange}
        onSelect={handleSelect}
        value={value || dayjs()}
        locale={antLocale}
        disabledDate={disableDatesAfterToday}
      />
      <section className="calBtnWrap">
        <Button
          onClick={() => {
            if (onClear) onClear();
          }}
          type="text"
        >
          {taskListCmsData?.due_date_custom_text[4]}
        </Button>
        {Boolean(isUsedInCustomFields) === false && (
          <Button
            onClick={() => {
              if (onCancel) onCancel();
            }}
            type="text"
          >
            {gridListCmsData?.cta_delete_modal_cancel}
          </Button>
        )}
        <Button
          onClick={() => {
            if (onOk) onOk();
          }}
          type="text"
        >
          {dashBoardCMSData?.cta_delete_modal_info}
        </Button>
      </section>
    </div>
  );
};

export default CCalendar;
