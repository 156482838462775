import { Dropdown, MenuProps, Tooltip, message } from 'antd';
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { useFetchNewPermission } from 'components/sharedComponents/hooks';
import { NotificationEventType } from 'models/enums/notification';
import {
  IProjectState,
  ITaskListDetails,
  IUserDetails,
  IUserState,
} from 'models/interface';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { projectService } from 'services';
import { useProjectDashboardStore, useUserStore } from 'stores';
import { getCurrentOrganization } from 'utils/commonFunctions';

interface ITaskPriorityComponentProps {
  eachTaskDetail: ITaskListDetails;
  sectionId?: number | string;
  setTaskListDetails: any;
  taskDetails: any;
  notifyChannel?: (
    userDetails: IUserDetails,
    eventType: string,
    eventValue?: string,
  ) => void;
  setEachTaskDetail?: React.Dispatch<React.SetStateAction<ITaskListDetails>>;
  findTaskIdAndUpdateMainTaskList?: (
    eachTaskDetailUpdated: ITaskListDetails,
  ) => void;
}

const TaskPriority: React.FC<ITaskPriorityComponentProps> = ({
  eachTaskDetail,
  sectionId,
  notifyChannel,
  setEachTaskDetail,
  findTaskIdAndUpdateMainTaskList,
  setTaskListDetails,
  taskDetails,
}) => {
  const { taskListCmsData, projectDetails, taskPriorityMaster } =
    useProjectDashboardStore((state: IProjectState) => state);
  const { hasPermissions } = useRbac();
  const { userDetails } = useUserStore((state: IUserState) => state);
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];

  const { fetchNewPermission } = useFetchNewPermission();

  const taskPriorityChangeHandler = async (task_priority_id: number) => {
    if (typeof eachTaskDetail.task_id === 'string') {
      message.info(taskListCmsData?.error_message_options?.no_task_name, 3);
      return;
    }
    if (
      projectDetails?.project_id &&
      typeof sectionId === 'number' &&
      typeof eachTaskDetail.task_id === 'number' &&
      userDetails?.organization_info
    ) {
      let _priority_id = task_priority_id;

      // let _priority_id = task_priority_id + 1;
      // if (taskPriorityMaster && _priority_id > taskPriorityMaster?.length) {
      //   _priority_id = 2;
      // }
      if (setEachTaskDetail) {
        let eachTaskDetailUpdated = {
          ...eachTaskDetail,
          task_priority_id: _priority_id,
          task_priority_name: taskPriorityMaster?.find(
            (eachPriority) => eachPriority.value === _priority_id,
          )?.label,
        };
        findTaskIdAndUpdateMainTaskList &&
          findTaskIdAndUpdateMainTaskList(eachTaskDetailUpdated);
        setEachTaskDetail((prev) => ({
          ...prev,
          task_priority_id: _priority_id,
          task_priority_name: taskPriorityMaster?.find(
            (eachPriority) => eachPriority.value === _priority_id,
          )?.label,
        }));
      }
      try {
        const { data, message, messageId } = await projectService.updateTaskV2({
          organizationId: getCurrentOrganization(
            org_key,
            userDetails?.organization_info,
          )?.organization_id!,
          project_id: projectDetails.project_id,
          section_id: sectionId,
          section_details: [
            {
              ...eachTaskDetail,
              task_id: eachTaskDetail.task_id,
              task_priority_id: _priority_id,
            },
          ],
        });

        setTaskListDetails((prevState: any) => ({
          ...taskDetails,
          task_list_details: data,
        }));

        if (notifyChannel) {
          notifyChannel(
            userDetails,
            NotificationEventType.UPDATE_SIMPLE_TASK_ROW,
            JSON.stringify({
              ...eachTaskDetail,
              task_id: eachTaskDetail.task_id,
              task_priority_id: _priority_id,
            }),
          );
        }
      } catch (error: any) {
        if (error?.response?.data?.messageId === -4) {
          fetchNewPermission(
            org_key,
            taskListCmsData?.lbl_error_message_permission_denied,
          );
          return;
        }
        message.error(taskListCmsData?.error_message_options?.msg, 3);
      }
    }
  };
  let priorityOptions = taskDetails?.of_settings?.find((ele: any) => ele?.mapping_id === 9)?.values;
  const statusItems = useMemo<MenuProps['items']>(() => {
    return priorityOptions?.filter((ele: any) => ele?.option_id !== eachTaskDetail?.task_priority_id)
      .map((status: any) => ({
        key: Number(status?.option_id),
        label: (
          <div className="customDropDownItem">
            <span
                className={`priorityWrap priorityIcon
              ${taskListCmsData?.task_priority_options?.find(
                  (eachStatus) =>
                    eachStatus?.priority_id ===
                    (status?.option_id ?? 2),
                )?.className
                  }
            `}
            />
            {status?.name}
          </div>
        ),
      }));
  }, [taskDetails]);

  if (!priorityOptions) {
    return null
  }
  return (
    <Tooltip
      title={
        taskListCmsData?.task_priority_options?.find(
          (eachStatus) =>
            eachStatus?.priority_id === (eachTaskDetail?.task_priority_id ?? 2),
        )?.priority_name
      }
      placement="bottom"
      color={'#2E364C'}
    >
      <Dropdown
        menu={{
          items: statusItems,
          onClick: (e) => {
            hasPermissions(
              [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
              projectDetails?.associated_role_id,
            ) &&
              !projectDetails?.is_archived &&
              taskPriorityChangeHandler(Number(e.key))
          },
        }}
        trigger={["click"]}
        arrow
        placement="bottomCenter"
      >
      <span
        className={`priorityWrap priorityIcon
              ${taskListCmsData?.task_priority_options?.find(
                (eachStatus) =>
                  eachStatus.priority_id ===
                  (eachTaskDetail.task_priority_id ?? 2),
              )?.className
            }`}

          onKeyDown={() => { }}
      />
      </Dropdown>
    </Tooltip>
  );
};

export default TaskPriority;
