import { message } from 'antd';
import classNames from 'classnames';
import {
  IGridList,
  ILoaderState,
  IProjectState,
  ITaskList,
} from 'models/interface';
import { FC, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { customizationService, loggerService } from 'services';
import { useLoaderStore, useProjectDashboardStore, useUserStore } from 'stores';
import { getCurrentOrganization, removeOverlay } from 'utils/commonFunctions';
import { useFetchNewPermission } from '../hooks';
import CustomDoneStatus from './CustomDoneStatus';
import StickerPackList from './StickerPackList';

const RightSideDrawerForCustomizeStatusUpdate: FC<{
  showSidePanel: boolean;
  setShowSidePanel: React.Dispatch<React.SetStateAction<boolean>>;
  sectionDetails: ITaskList | IGridList;
  setSectionDetails: React.Dispatch<
    React.SetStateAction<ITaskList | IGridList>
  >;
}> = ({
  showSidePanel,
  setShowSidePanel,
  sectionDetails,
  setSectionDetails,
}) => {
  const { customizeSectionUpdateCmsData, projectDetails } =
    useProjectDashboardStore((state: IProjectState) => state);
  const { userDetails } = useUserStore((state: any) => state);
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
  const [packId, setPackId] = useState<number>(sectionDetails.pack_id ?? 1);
  const [doneValue, setDoneValue] = useState<number>(
    sectionDetails.status_done_id ?? 1,
  );
  const currentCustomizeValue = useRef({
    doneValue: sectionDetails.status_done_id ?? 1,
    packId: sectionDetails.pack_id ?? 1,
  });

  const location = useLocation();
  const org_key = location.pathname.split('/')[2];

  const { fetchNewPermission } = useFetchNewPermission();

  const closeSidePanel = () => {
    setShowSidePanel(false);
    //addRemoveOverLay();
    removeOverlay();
    setPackId(sectionDetails.pack_id!);
    setDoneValue(sectionDetails.status_done_id!);
  };

  const saveChanges = async () => {
    if (sectionDetails.section_type_id === 1) {
      // for simple task list
      setSectionDetails({
        ...sectionDetails,
        pack_id: packId,
        status_done_id: doneValue,
      });

      const responseSuccess = await updateStickerPackAndDoneTaskAssociation();
      if (responseSuccess) {
        await updateStickerPackAssociation();
      }
      removeOverlay();
    } else {
      // for grid list

      setSectionDetails({
        ...sectionDetails,
        pack_id: packId,
      });

      await updateStickerPackAssociation();
    }
  };

  const updateStickerPackAssociation = async () => {
    setLoaderState('active');
    try {
      let payload = {
        // getDefaultOrganization(userDetails.organization_info)
        //   ?.organization_id!,

        organizationId: getCurrentOrganization(
          org_key,
          userDetails?.organization_info,
        )?.organization_id!,
        userId: userDetails.user_id,
        packId: packId,
        projectId: projectDetails?.project_id,
        sectionId: sectionDetails.section_id,
      };

      await customizationService.updateStickerAssociation(payload);
      removeOverlay();
      setShowSidePanel(false);
      setLoaderState('inactive');
    } catch (error: any) {
      if (error?.response?.data?.messageId === -2) {
        fetchNewPermission(
          org_key,
          customizeSectionUpdateCmsData?.lbl_error_message_permission_denied,
        );
      } else {
        message.error(
          customizeSectionUpdateCmsData?.lbl_generic_error_message,
          3,
        );
        await loggerService.log({
          severity: 'High',
          message: `Sticker pack list get action failed`,
          payload: { ApiResponse: error },
        });
      }
    } finally {
      removeOverlay();
      setShowSidePanel(false);
      setLoaderState('inactive');
    }
  };

  const updateStickerPackAndDoneTaskAssociation = async () => {
    setLoaderState('active');
    try {
      let payload = {
        // getDefaultOrganization(userDetails.organization_info)
        //   ?.organization_id!,
        organization_id: getCurrentOrganization(
          org_key,
          userDetails?.organization_info,
        )?.organization_id!,
        user_id: userDetails.user_id,
        status_view_id: doneValue,
        project_id: projectDetails?.project_id,
        section_id: sectionDetails.section_id,
      };

      await customizationService.updateStatusDoneAssociation(payload);
      removeOverlay();
      setShowSidePanel(false);
      setLoaderState('inactive');
      return true;
    } catch (error: any) {
      if (error?.response?.data?.messageId === -2) {
        fetchNewPermission(
          org_key,
          customizeSectionUpdateCmsData?.lbl_error_message_permission_denied,
        );
      } else {
        message.error(
          customizeSectionUpdateCmsData?.lbl_generic_error_message,
          3,
        );
        await loggerService.log({
          severity: 'High',
          message: `Sticker pack list get action failed`,
          payload: { ApiResponse: error },
        });
      }
    } finally {
      removeOverlay();
      setShowSidePanel(false);
      setLoaderState('inactive');
    }
  };

  const onChange = (evt: number) => {
    setDoneValue(evt);
  };
  const isDisable = () => {
    console.log(currentCustomizeValue.current.doneValue, doneValue);
    return (
      currentCustomizeValue.current.doneValue === doneValue &&
      currentCustomizeValue.current.packId === packId
    );
  };
  return showSidePanel ? (
    // <OutsideClickHandler
    //   onOutsideClick={() => {
    //     closeSidePanel();
    //   }}
    // >
    <div className="customizeStatusUpdateDrawer createNewProjectForm addToggle">
      <div className="modal-content">
        <div className="modal-header">
          <h3>{customizeSectionUpdateCmsData?.lbl_panel_header}</h3>
          <span
            className="material-icons-outlined modal-close"
            onClick={() => closeSidePanel()}
            onKeyDown={() => {}}
          >
            close
          </span>
        </div>
        <div className="formContent">
          {sectionDetails.section_type_id === 1 && (
            <div className="customStatusUpdateWrapper customizeTaskName">
              <div className="customStatusUpdate">
                <p>
                  {customizeSectionUpdateCmsData?.lbl_done_task_section_header}
                </p>
              </div>
              <CustomDoneStatus value={doneValue} onChange={onChange} />
            </div>
          )}
          <div className="customStatusUpdateWrapper customizeStickerImg">
            <div className="customStatusUpdate">
              <p>
                {
                  customizeSectionUpdateCmsData?.lbl_done_task_status_section_header
                }
              </p>
            </div>
            <StickerPackList packId={packId} setPackId={setPackId} />
          </div>
        </div>
        <div className="modal-footer">
          <div className="btnSec">
            <button className="noStyle" onClick={() => closeSidePanel()}>
              {customizeSectionUpdateCmsData?.btn_cancel_cta}
            </button>
            <button
              disabled={isDisable()}
              className={classNames({
                disabledBtn: isDisable(),
                btnStyle1: !isDisable(),
              })}
              onClick={() => saveChanges()}
            >
              {customizeSectionUpdateCmsData?.btn_save_changes_cta}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    //</OutsideClickHandler>
    <></>
  );
};

export default RightSideDrawerForCustomizeStatusUpdate;
