import React, { FC, useEffect, useMemo, useState } from 'react';
import { Dropdown, Menu, MenuProps, Popover, Tooltip, message } from 'antd';
import {
  IPackConfiguration,
  IPackDetail,
  IProjectState,
  ITaskStatus,
  IUserState,
} from 'models/interface';
import {
  useProjectDashboardStore,
  useTaskStatusStore,
  useUserStore,
} from 'stores';
import dayjs from 'dayjs';
import { projectService } from 'services';
import { getCurrentOrganization } from 'utils/commonFunctions';
import { markTaskDoneInMessageThread } from 'utils/firebaseCommonFunctions';
import { SectionTypeMessages } from 'models/enums/messages';
import { useFetchNewPermission } from '../hooks';

interface ITeamMember {
  email: string;
  role_id: number;
  user_id: number;
  full_name: string;
  should_delete: boolean;
  profile_picture: string;
}

export interface IEachTaskDetail {
  org_id: number;
  org_key: string;
  task_id: number;
  due_date: string;
  org_logo: string;
  org_color: string;
  task_name: string;
  team_name: string;
  created_by: string;
  project_id: number;
  section_id: number;
  stage_name: string | null;
  assignee_id: number;
  assigner_id: number;
  team_member: ITeamMember[];
  project_name: string;
  section_name: string;
  assignee_name: string;
  assigner_name: string;
  task_status_id: number;
  section_type_id: number;
  task_priority_id: number;
  task_rank?: number;
  block_id?: number;
}

interface IStatusChange {
  eachTaskDetail: IEachTaskDetail;
  packId?: number;
  setEachTaskDetail?: any;
  packList?: any;
  project_role_id?: number | undefined;
  index: number;
  taskListDetails?: any;
  setTaskListDetails?: React.Dispatch<React.SetStateAction<IEachTaskDetail[]>>;
  // socket?: any;
  findTaskIdAndUpdateMainTaskList?: any;
  apiVersion?: 'v1' | 'v2';
  disable?: boolean
}

const StatusChange: FC<IStatusChange> = ({
  eachTaskDetail,
  packId,
  setEachTaskDetail,
  packList,
  taskListDetails,
  setTaskListDetails,
  index,
  // socket,
  findTaskIdAndUpdateMainTaskList,
  apiVersion,
  disable = false
}) => {
  const { taskStatusCmsData, taskStatusMaster } = useTaskStatusStore(
    (state: ITaskStatus) => state,
  );
  const { projectDetails, taskListCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const { userDetails } = useUserStore((state: IUserState) => state);

  const { fetchNewPermission } = useFetchNewPermission();
  const statusPackList = packList?.find(
    (ele: IPackDetail) => ele?.pack_id === 1,
  )?.pack_configuration;


  const getStatusValue = (statusId: number) => {
    let packDetailsPerTask = statusPackList?.find((ele: any) => ele?.status_key === statusId);
    return packDetailsPerTask?.status_value;
  }

  const isOverDue = eachTaskDetail.task_status_id === 2;

  const optionalFieldList = taskListDetails?.of_settings?.find((of: any) => of?.mapping_id === 1)?.values;



  const taskStatusChangeHandler = async (statusId: number) => {
    if (typeof eachTaskDetail.task_id === 'string') {
      message.info(taskStatusCmsData?.error_message_options?.no_task_name, 3);
      return;
    }
    if (
      eachTaskDetail?.project_id &&
      typeof eachTaskDetail.task_id === 'number' &&
      userDetails?.organization_info
    ) {
      let _status_id = statusId;
      // switch (statusId) {
      //   case 1:
      //     _status_id = 3;

      //     break;
      //   case 2:
      //     _status_id = 3;
      //     break;
      //   case 3:
      //     _status_id = 4;
      //     break;
      //   case 4:
      //     _status_id =
      //       dayjs(eachTaskDetail.due_date).diff(
      //         dayjs(new Date().setHours(0, 0, 0, 0)),
      //         'days',
      //       ) < 0
      //         ? 2
      //         : 1;
      //     break;

      //   default:
      //     break;
      // }

      // if (socket && eachTaskDetail.section_type_id === 1) {
      //   notificationService.sendSimpleTaskUpdateNotification(socket, {
      //     eventType: NotificationEventType.UPDATE_SIMPLE_TASK_ROW,
      //     eventValue: JSON.stringify({
      //       ...eachTaskDetail,
      //       task_id: eachTaskDetail.task_id,
      //       task_status_id: _status_id,
      //     }),
      //     sectionType: SectionType.SIMPLE_TASK_LIST,
      //     currentOrganizationId: getCurrentOrganization(
      //       eachTaskDetail.org_key,
      //       userDetails?.organization_info!
      //     )?.organization_id,
      //     currentProjectId: eachTaskDetail.project_id,
      //     currentSectionId: eachTaskDetail.section_id,
      //     currentUserId: userDetails.user_id,
      //     currentUserName: userDetails.full_name
      //       ? userDetails.full_name
      //       : userDetails.email,
      //   });
      // }

      setEachTaskDetail &&
        setEachTaskDetail((prev: any) => ({
          ...prev,
          task_status_id: _status_id,
          task_status_name: taskStatusMaster?.find(
            (eachStatus) => eachStatus.value === _status_id,
          )?.label,
        }));
      let temp = taskListDetails;
      temp[index] = {
        ...eachTaskDetail,
        task_status_id: _status_id,
        task_status_name: taskStatusMaster?.find(
          (eachStatus) => eachStatus.value === _status_id,
        )?.label,
      };

      setTaskListDetails && setTaskListDetails(temp);
      if (findTaskIdAndUpdateMainTaskList !== undefined) {
        findTaskIdAndUpdateMainTaskList({
          ...eachTaskDetail,
          task_status_id: _status_id,
          task_status_name: taskStatusMaster?.find(
            (eachStatus) => eachStatus.value === _status_id,
          )?.label,
        });
      }
      try {
        if (eachTaskDetail?.section_type_id === 1) {
          const payload = {
            organizationId: getCurrentOrganization(
              eachTaskDetail?.org_key,
              userDetails?.organization_info,
            )?.organization_id!,
            project_id: eachTaskDetail?.project_id,
            section_id: eachTaskDetail?.section_id,
            section_details: [
              {
                ...eachTaskDetail,
                task_id: eachTaskDetail.task_id,
                task_status_id: _status_id,
              },
            ],
          };
          if (apiVersion === 'v2') {
            await projectService.updateTaskV2(payload);
          } else {
            await projectService.updateTask(payload);
          }
        }
        if (eachTaskDetail?.section_type_id == 2) {
          await projectService.updateGridBlock({
            organizationId: getCurrentOrganization(
              eachTaskDetail?.org_key,
              userDetails?.organization_info,
            )?.organization_id!,
            userId: userDetails?.user_id,
            projectId: eachTaskDetail?.project_id,
            sectionId: eachTaskDetail!.section_id,
            blockId: eachTaskDetail?.block_id ?? eachTaskDetail?.task_id,
            assignee: eachTaskDetail.assignee_id,
            assigner: eachTaskDetail.assigner_id,
            dueDate: eachTaskDetail.due_date,
            blockStatus: _status_id,
          });
        }
        if (_status_id === 3) {
          markTaskDoneInMessageThread(
            `${SectionTypeMessages.SIMPLE_LIST_TASK}-${process.env.REACT_APP_STAGE
            }-${getCurrentOrganization(
              eachTaskDetail?.org_key,
              userDetails?.organization_info,
            )?.organization_id!}-${projectDetails?.project_id}-${eachTaskDetail!.section_id
            }-${eachTaskDetail.task_id}`,
          );
        } else {
          markTaskDoneInMessageThread(
            `${SectionTypeMessages.SIMPLE_LIST_TASK}-${process.env.REACT_APP_STAGE
            }-${getCurrentOrganization(
              eachTaskDetail?.org_key,
              userDetails?.organization_info,
            )?.organization_id!}-${projectDetails?.project_id}-${eachTaskDetail!.section_id
            }-${eachTaskDetail.task_id}`,
            false,
          );
        }
      } catch (error: any) {
        if (error?.response?.data?.messageId === -4) {
          fetchNewPermission(
            eachTaskDetail?.org_key,
            taskListCmsData?.lbl_error_message_permission_denied,
          );
          return;
        }
        message.error(taskStatusCmsData?.error_message_options?.msg, 3);
      }
    }
  };

  const getStatusImage = (status: number | undefined, packId: number = 1) => {
    if (packList) {
      let pack = packList?.filter(
        (ele: IPackDetail) => ele?.pack_id === packId,
      );
      if (status && pack?.length) {
        return pack[0].pack_configuration.find(
          (ele: IPackConfiguration) =>
            ele?.status_key === eachTaskDetail?.task_status_id,
        )!;
      } else {
        return { status_value: '', status_name: '' };
      }
    } else {
      return taskStatusCmsData?.task_status_options?.find(
        (obj: any) => obj.status_id === eachTaskDetail?.task_status_id,
      );
    }
  };
  // isOverDue || 
  const statusItems = useMemo<MenuProps['items']>(() => {
    return optionalFieldList?.filter((ele: any) => {
      if (dayjs(eachTaskDetail.due_date).diff(
        dayjs(new Date().setHours(0, 0, 0, 0)),
        'days',
      ) < 0
      ) {
        return ele.option_id !== 1 && ele.option_id !== eachTaskDetail?.task_status_id;
      }
      else { return ele.option_id !== eachTaskDetail?.task_status_id && ele.option_id !== 2 }
    })
      .map((status: any) => ({
        key: status.option_id,
        label: (
          <div className="customDropDownItem">
            <span
              className="cmnIcon"
            >
              <img
                src={`${process.env.REACT_APP_STORAGE_BASE_URL}${getStatusValue(status.option_id)}`}
                alt={getStatusValue(status.option_id)}
              />
            </span>
            {status.name}
          </div>
        ),
      }));
  }, [taskListDetails]);

  const getTaskStatusJsx = () => {
    return (
      <Tooltip
        title={
          taskStatusCmsData?.task_status_options?.find(
            (eachStatus: any) =>
              eachStatus?.status_id === (eachTaskDetail?.task_status_id ?? 1),
          )?.status_name
        }
        placement="bottom"
        color={'#2E364C'}
      >
        {/**
         *  if in future rbac is required for status change */
        /*<img
                        onClick={() =>
                            hasPermissions(
                                eachTaskDetail.section_type_id === 1
                                    ? [
                                          ERbacPermissions.PROJECT_SECTION_TASK_STATUS_EDIT,
                                      ]
                                    : [
                                          ERbacPermissions.PROJECT_SECTION_GRIDLIST_BLOCK_STATUS_EDIT,
                                      ],
                                project_role_id
                            ) &&
                            taskStatusChangeHandler(
                                eachTaskDetail.task_status_id ?? 1
                            )
                        }
                        onKeyDown={() => {}}
                        src={`${process.env.REACT_APP_STORAGE_BASE_URL}${
                            getStatusImage(
                                eachTaskDetail?.task_status_id!,
                                packId
                            )?.status_value
                        }`}
                        alt={
                            getStatusImage(
                                eachTaskDetail?.task_status_id!,
                                packId
                            )?.status_name
                        }
                    />*/}
        <Dropdown
          menu={{
            items: statusItems,
            onClick: (e) => {
                !projectDetails?.is_archived &&
                  taskStatusChangeHandler(Number(e.key));

            },
          }}
          overlayClassName='statusOFDropdown'
          trigger={["click"]}
          disabled={disable}
          arrow
          placement="bottomCenter"
        >
          <img
            onKeyDown={() => { }}
            src={`${process.env.REACT_APP_STORAGE_BASE_URL}${getStatusImage(eachTaskDetail?.task_status_id!, packId)
              ?.status_value
              }`}
            alt={
              getStatusImage(eachTaskDetail?.task_status_id!, packId)?.status_name
            }
          />
        </Dropdown>
      </Tooltip>
    );
  };
  return getTaskStatusJsx();
};

export default StatusChange;
