import { FC, Fragment, useCallback, useState } from 'react';
import {
  IGridList,
  IGridListTaskDetail,
  IProjectState,
} from 'models/interface';
import { useProjectDashboardStore } from 'stores';
import { Tooltip } from 'antd';
import { Rbac, useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { SectionTypeMessages } from 'models/enums/messages';
// import debounce from "lodash.debounce";
import TaskDelete from './TaskDelete';
import MessageThread from '../../../MessageThread';
import { debounce } from 'utils/commonFunctions';

const TaskNameInputField: FC<{
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  taskDetails: IGridListTaskDetail;
  submitChange: (stageName: string, eventType: string) => Promise<void>;
  updateTask: (taskName: any, taskId: any) => Promise<void>;
  revertChange: (taskId: number, event: string) => void;
  index?: number;
}> = ({
  taskDetails,
  submitChange,
  updateTask,
  gridListDetails,
  setGridListDetails,
  revertChange,
  index,
}) => {
  const { gridListCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const { hasPermissions } = useRbac();

  // State
  const [input, setInput] = useState(taskDetails.task_name);
  const [openMessageExpandedView, setOpenMessageExpandedView] =
    useState<boolean>(false);
  const checkIfRevertRequired = (taskDetails: IGridListTaskDetail) =>
    taskDetails.backupName === taskDetails.task_name ||
    taskDetails.task_name === '';

  const onKeyUpEventHandler = async (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (taskDetails?.isNew) {
      if (e.key === 'Enter') {
        if (!localStorage.getItem('isTaskAdded')) {
          document
            .getElementById(`${taskDetails.task_id}_input_field`)
            ?.setAttribute('readonly', 'true');
          localStorage.setItem('isTaskAdded', taskDetails.task_id.toString());
          await submitChange(taskDetails.task_name, 'Enter');
          document
            .getElementById(`${taskDetails.task_id}_input_field`)
            ?.removeAttribute('readonly');
          localStorage.removeItem('isTaskAdded');
          setInput('');
        }
      }

      if (e.key === 'Escape') {
        submitChange('', 'Escape');
      }
    } else {
      if (e.key === 'Enter') {
        if (checkIfRevertRequired(taskDetails)) {
          revertChange(taskDetails.task_id, 'Enter');
        } else {
          document.getElementById(`${taskDetails.task_id}_input_field`)?.blur();
        }
      }

      if (e.key === 'Escape') {
        revertChange(taskDetails.task_id, 'Escape');
      }
    }
  };

  const onChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (gridListDetails) {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          task_details: getUpdateTaskDetails(
            gridListDetails,
            taskDetails,
            e.target.value,
          )!,
        },
      } as IGridList);
    }
  };

  const debounceUpdate = useCallback(debounce(onChangeEventHandler, 50), [
    gridListDetails,
  ]);

  const onBlurEventHandler = (
    e: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    if (taskDetails?.isNew) {
      submitChange(taskDetails.task_name, 'Blur');
    } else if (checkIfRevertRequired(taskDetails)) {
      revertChange(taskDetails.task_id, 'Blur');
    } else {
      updateTask(taskDetails.task_name, taskDetails.task_id);
    }
  };

  const getUpdateTaskDetails = (
    gridListDetails: IGridList,
    taskDetails: IGridListTaskDetail,
    newName: string,
  ) => {
    if (gridListDetails.grid_list_details!.task_details) {
      let task = [{ ...taskDetails }];
      if (!task[0]?.backupName) {
        task[0].backupName = task[0].task_name;
      }
      task[0].task_name = newName;

      let existingTask = gridListDetails.grid_list_details!.task_details.filter(
        (ele: IGridListTaskDetail) => ele.task_id !== task[0].task_id,
      );
      return [...existingTask, ...task].sort(
        (a, b) => a.task_rank - b.task_rank,
      );
    }
  };

  const makeFieldEditable = (taskDetails: IGridListTaskDetail) => {
    if (
      hasPermissions(
        [ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_EDIT],
        projectDetails?.associated_role_id,
      )
    ) {
      if (gridListDetails) {
        let task = [{ ...taskDetails }];
        task[0].isEdit = true;
        let existingTask =
          gridListDetails.grid_list_details!.task_details!.filter(
            (ele: IGridListTaskDetail) => ele.task_id !== task[0].task_id,
          );

        setGridListDetails({
          ...gridListDetails,
          grid_list_details: {
            ...gridListDetails.grid_list_details,
            task_details: [...existingTask, ...task].sort(
              (a, b) => a.task_rank - b.task_rank,
            ),
          },
        } as IGridList);
      }
    }
  };

  // useEffect(() => {}, [])

  const getTaskNameFieldJsx = (taskDetails: IGridListTaskDetail) => {
    if (taskDetails.isNew) {
      return (
        <div className="gridTask">
          <span className="borderColor">border</span>
          <input
            type="text"
            id={`${taskDetails.task_id}_input_field`}
            placeholder={gridListCmsData?.lbl_add_task_placeholder}
            value={input}
            aria-label="sign up / login"
            onChange={(e) => {
              setInput(e.target.value);
              debounceUpdate(e);
            }}
            maxLength={100}
            onKeyUp={(e) => onKeyUpEventHandler(e)}
            onBlur={(e) => onBlurEventHandler(e)}
            autoFocus={taskDetails?.isNew}
          />
          {/* <div className="blkMsg haveMsg">
              Message <em>unread</em>
            </div>
            <div className="blkMore">More</div> */}
        </div>
      );
    }

    if (taskDetails.isEdit) {
      return (
        <div className="gridTask">
          <span className="borderColor">border</span>
          <input
            type="text"
            id={`${taskDetails.task_id}_input_field`}
            placeholder={gridListCmsData?.lbl_add_task_placeholder}
            value={input}
            aria-label="sign up / login"
            onChange={(e) => {
              setInput(e.target.value);
              debounceUpdate(e);
            }}
            maxLength={100}
            onKeyUp={(e) => onKeyUpEventHandler(e)}
            onBlur={(e) => onBlurEventHandler(e)}
            autoFocus={taskDetails?.isEdit}
          />
          {!taskDetails?.isNew && (
            <>
              {/* <MessageThread
                componentId="gridTask"
                sectionId={gridListDetails.section_id}
                taskId={taskDetails.task_id}
                sectionType={SectionTypeMessages.GRID_LIST_STAGE}
                threadName={taskDetails.task_name}
                sectionName={gridListDetails.section_name!}
                taskDetails={taskDetails}
                setOpenMessageExpandedView={setOpenMessageExpandedView}
                index={index}
                setExpandedViewForMessageIndex={setExpandedViewForMessageIndex}
              /> */}
              <MessageThread
                componentId="taskListMessaging"
                isBlockedView
                sectionId={gridListDetails.section_id}
                taskId={taskDetails.task_id}
                sectionType={SectionTypeMessages.GRID_LIST_TASK}
                threadName={taskDetails.task_name}
                sectionName={gridListDetails.section_name!}
                taskDetails={taskDetails}
              />
            </>
          )}
          {projectDetails?.is_archived === false && (
            <div className="blkMore">More</div>
          )}
        </div>
      );
    }

    return (
      <Fragment>
        {taskDetails.task_name.length > 30 && !openMessageExpandedView ? (
          <Tooltip
            title={taskDetails.task_name}
            color={'#2E364C'}
            placement="top"
          >
            <div
              onClick={() => makeFieldEditable(taskDetails)}
              onKeyDown={() => {}}
              className="gridTask"
            >
              <span className="borderColor">border</span>
              <span className="blkTxt">{taskDetails?.task_name}</span>
              {!taskDetails?.isNew ? (
                <MessageThread
                  componentId="taskListMessaging"
                  isBlockedView
                  sectionId={gridListDetails.section_id}
                  taskId={taskDetails.task_id}
                  sectionType={SectionTypeMessages.GRID_LIST_TASK}
                  threadName={taskDetails.task_name}
                  sectionName={gridListDetails.section_name!}
                  taskDetails={taskDetails}
                />
              ) : (
                <></>
              )}
              {!taskDetails?.isNew && projectDetails?.is_archived === false ? (
                <Rbac
                  allowedPermissions={[
                    ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_DELETE,
                  ]}
                  project_role_id={projectDetails?.associated_role_id}
                >
                  <TaskDelete
                    taskDetails={taskDetails}
                    gridListDetails={gridListDetails}
                    setGridListDetails={setGridListDetails}
                  />
                </Rbac>
              ) : (
                <></>
              )}
            </div>
          </Tooltip>
        ) : (
          <div
            onClick={() =>
              projectDetails?.is_archived === false &&
              makeFieldEditable(taskDetails)
            }
            onKeyDown={() => {}}
            className="gridTask"
          >
            <span className="borderColor">border</span>
            <span className="blkTxt">{taskDetails?.task_name}</span>
            {!taskDetails?.isNew ? (
              <MessageThread
                componentId="taskListMessaging"
                isBlockedView
                sectionId={gridListDetails.section_id}
                taskId={taskDetails.task_id}
                sectionType={SectionTypeMessages.GRID_LIST_TASK}
                threadName={taskDetails.task_name}
                sectionName={gridListDetails.section_name!}
                taskDetails={taskDetails}
              />
            ) : (
              <></>
            )}
            {!taskDetails?.isNew && projectDetails?.is_archived === false ? (
              <Rbac
                allowedPermissions={[
                  ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_DELETE,
                ]}
                project_role_id={projectDetails?.associated_role_id}
              >
                <TaskDelete
                  taskDetails={taskDetails}
                  gridListDetails={gridListDetails}
                  setGridListDetails={setGridListDetails}
                />
              </Rbac>
            ) : (
              <></>
            )}
          </div>
        )}
      </Fragment>
    );
  };

  return getTaskNameFieldJsx(taskDetails);
};

export default TaskNameInputField;
