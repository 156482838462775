import { FC, Fragment, useEffect, useState } from 'react';
import { Select, Avatar, Tooltip } from 'antd';
import { UseFormGetValues } from 'react-hook-form';

import {
  IOrganizationAssociatedUserDetails,
  IProjectCreationAttributes,
  IProjectRolesDetails,
  IProjectState,
  IProjectSubmitData,
} from 'models/interface';
import {
  useDashboardStore,
  useProjectDashboardStore,
  useUserStore,
} from 'stores';
import { generateUserAvatar } from 'utils/commonFunctions';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { Rbac } from 'auth/rbac/rbac';
import { modeOps } from 'models/enums/constants';

const RightSideDrawerUserListDropDown: FC<{
  pageSpecificDataState?: IProjectCreationAttributes | null;
  associatedUser: any[];
  userListState: any[];
  setAssociatedUser: any;
  setUserListState: any;
  getValues: UseFormGetValues<IProjectSubmitData>;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  mode: string;
  showProjectMemberBlock?: boolean;
  setShowProjectMemberBlock?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  pageSpecificDataState,
  associatedUser,
  userListState,
  setAssociatedUser,
  setUserListState,
  getValues,
  setIsDisabled,
  mode,
  showProjectMemberBlock,
  setShowProjectMemberBlock,
}) => {
  const { userDetails } = useUserStore((state: any) => state);
  const { userList } = useDashboardStore((state: any) => state);
  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const [sortedAssociatedUsers, setSortedAssociatedUsers] = useState<any>();

  const handleSearch = (value: any) => {
    let newList: IOrganizationAssociatedUserDetails[] = [];
    let addedUerIds = associatedUser.map(
      (ele: IOrganizationAssociatedUserDetails) => ele.user_id,
    );
    if (value) {
      newList = userList.filter(
        (ele: IOrganizationAssociatedUserDetails) =>
          ele?.full_name?.toLowerCase().includes(value.toLowerCase()) ||
          ele?.email?.toLowerCase().includes(value.toLowerCase()),
      );
      addedUerIds = associatedUser.map(
        (ele: IOrganizationAssociatedUserDetails) => ele.user_id,
      );
      newList = newList.filter(
        (ele: IOrganizationAssociatedUserDetails) =>
          !addedUerIds.includes(ele.user_id),
      );
      setUserListState(newList);
    } else {
      addedUerIds = associatedUser.map(
        (ele: IOrganizationAssociatedUserDetails) => ele.user_id,
      );
      newList = userList.filter(
        (ele: IOrganizationAssociatedUserDetails) =>
          !addedUerIds.includes(ele.user_id),
      );
      setUserListState(newList);
    }
  };

  const onSelect = (value: any) => {
    let user = userList.filter(
      (ele: IOrganizationAssociatedUserDetails) => ele.user_id === value,
    );
    let newList = [...associatedUser];
    newList.push({
      ...user[0],
      full_name_for_display: user[0].full_name,
      role_id: 4,
      should_delete: true,
    });
    newList = newList.filter((ele) => !ele?.isNew);
    setAssociatedUser(newList);
    let updatedList = userListState.filter(
      (ele: IOrganizationAssociatedUserDetails) => ele.user_id !== value,
    );
    setUserListState(updatedList);
    if (
      getValues('projectName').trim() !== '' &&
      getValues('projectName')!.trim().length >= 3
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const removeUser = (row: IOrganizationAssociatedUserDetails) => {
    let newList = associatedUser.filter(
      (ele: IOrganizationAssociatedUserDetails) => ele.user_id !== row.user_id,
    );
    setAssociatedUser(newList);
    let addedUerIds = newList.map(
      (ele: IOrganizationAssociatedUserDetails) => ele.user_id,
    );
    if (newList.length > 0) {
      let newListV1 = userList.filter(
        (ele: IOrganizationAssociatedUserDetails) =>
          !addedUerIds.includes(ele.user_id),
      );
      setUserListState(newListV1);
    } else {
      setUserListState(userList);
    }
  };

  const removeField = () => {
    let newList = associatedUser.filter((ele: any) => !ele?.isNew);
    setAssociatedUser(newList);
    setUserListState(userList);
    setIsDisabled(false);
  };

  const changeRole = (
    userId: number,
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    let newList = [...associatedUser];
    let targetUser = newList.filter(
      (ele: IOrganizationAssociatedUserDetails) => ele.user_id === userId,
    );
    targetUser[0].role_id = +event.currentTarget.value;
    setAssociatedUser(newList);
  };

  const generateGravatar = (name: string) => {
    if (name) {
      let nameSplit = name.trim().split(' ');

      if (nameSplit.length === 1) {
        return `${nameSplit[0].charAt(0).toUpperCase()}`;
      } else {
        return `${nameSplit[0].charAt(0).toUpperCase()}${nameSplit[
          nameSplit.length - 1
        ]
          .charAt(0)
          .toUpperCase()}`;
      }
    }

    return '';
  };

  const generateUserGravatarJsx = (
    user: IOrganizationAssociatedUserDetails,
  ) => {
    if (!user.inactive) {
      if (user.profile_picture) {
        return (
          <Avatar
            src={user.profile_picture}
            alt={user?.full_name ? user.full_name : user.email}
          />
        );
      }
      return (
        <Avatar
          className="userAvtr"
          alt={user?.full_name ? user.full_name : user.email}
        >
          {generateGravatar(user?.full_name ?? '')}
        </Avatar>
      );
    }
    return (
      <Avatar className="userAvtr userAvtrBlank" alt={user.email}></Avatar>
    );
  };

  const getAssociatedUsersGravatarClassName = (
    ele: IOrganizationAssociatedUserDetails,
  ) => {
    if (!ele.inactive) {
      return generateUserAvatar(ele).key === 'url' ? 'userAvtrImg' : '';
    } else {
      return 'userAvtrBlank';
    }
  };

  const getAssociatedUsersEmailClassName = (
    ele: IOrganizationAssociatedUserDetails,
  ) => {
    return !ele.inactive ? 'userEmail' : 'userAvtrBlankContent';
  };

  const getUserDropdownUsernameClassName = (
    user: IOrganizationAssociatedUserDetails,
  ) => {
    return !user?.inactive ? '' : 'userAvtrBlankContent';
  };

  const getProjectAssociatedUsersGravatarJsx = (
    ele: IOrganizationAssociatedUserDetails,
  ) => {
    if (ele.inactive) {
      return null;
    }
    return generateUserAvatar(ele).key === 'string' ? (
      generateUserAvatar(ele).value
    ) : (
      <img src={generateUserAvatar(ele).value} alt="gravatar" />
    );
  };

  const getSortedAssociatedUsers = () => {
    let sortedUserList: IOrganizationAssociatedUserDetails[] | null = null;
    let tempAssociatedMembers = [];
    if (associatedUser && associatedUser.length > 0) {
      const activeUserList = associatedUser.filter(
        (eachUser) => !eachUser.inactive,
      );
      const inactiveUserList = associatedUser.filter(
        (eachUser) => eachUser.inactive,
      );
      let activeSortedUserList: any = [],
        inactiveSortedUserList: any = [];
      if (activeUserList.length) {
        activeSortedUserList = activeUserList.sort((a, b) =>
          a.full_name?.localeCompare(b.full_name!),
        );
      }
      if (inactiveUserList.length) {
        inactiveSortedUserList = inactiveUserList.sort((a, b) =>
          a.email?.localeCompare(b.email!),
        );
      }
      sortedUserList = [...activeSortedUserList, ...inactiveSortedUserList];
      const currentUser = sortedUserList.find(
        (eachUser) => eachUser.email === userDetails.email,
      );
      const owner = sortedUserList.find(
        (eachUser) => eachUser.user_id === projectDetails?.created_by,
      );
      sortedUserList = sortedUserList.filter(
        (eachUser) => eachUser.email !== userDetails.email,
      );
      if (owner !== undefined) {
        tempAssociatedMembers.push(owner);
      }
      if (currentUser !== undefined) {
        tempAssociatedMembers.push(currentUser);
      }
      return [...new Set([...tempAssociatedMembers, ...sortedUserList])];
    }
  };

  const determineDeleteButtonVisibility = (ele: any) => {
    if (Number(userDetails.user_id) === Number(ele.user_id)) {
      return 'hidden';
    }
    if (!ele?.should_delete) {
      return 'hidden';
    }

    if (projectDetails?.associated_role_id === 4 && ele?.role_id === 3) {
      return 'hidden';
    }

    if (projectDetails?.associated_role_id === 5) {
      return 'hidden';
    }
    return 'visible';
  };

  useEffect(() => {
    setSortedAssociatedUsers(getSortedAssociatedUsers());
  }, [associatedUser]);

  const createMemberDropdownOptions = (
    ele: IOrganizationAssociatedUserDetails,
  ) => {
    // let currentProjectRole =
    //   userDetails?.project_permissions?.filter(
    //     (ele: any) => ele.project_id === projectDetails?.project_id,
    //   )?.[0] ?? projectDetails?.associated_role_id;

    let memberRoleOptions: any = [...(pageSpecificDataState?.unk_list ?? [])];

    if (
      projectDetails?.associated_role_id === 3 ||
      projectDetails?.associated_role_id === 8 ||
      ele?.role_id === 3
    ) {
      return renderMemberDropdownOptions(memberRoleOptions);
    }

    if (
      projectDetails?.associated_role_id === 4 &&
      ele?.role_id !== 3 &&
      ele?.role_id !== 8
    ) {
      memberRoleOptions = memberRoleOptions?.filter(
        (ele: any) => ele?.role_id === 4 || ele?.role_id === 5,
      );
      return renderMemberDropdownOptions(memberRoleOptions);
    }

    if (
      projectDetails?.associated_role_id === 5 &&
      ele?.role_id !== 3 &&
      ele?.role_id !== 8
    ) {
      memberRoleOptions = memberRoleOptions?.filter(
        (ele: any) => ele?.role_id === 5,
      );
      return renderMemberDropdownOptions(memberRoleOptions);
    }
    return renderMemberDropdownOptions(memberRoleOptions);
  };

  const renderMemberDropdownOptions = (options: IProjectRolesDetails[]) => (
    <>
      {options?.map((eleRole: IProjectRolesDetails) => (
        <option key={eleRole?.role_id} value={eleRole?.role_id}>
          {eleRole.role_name}
        </option>
      ))}
    </>
  );

  const disableSelectOptions = (member: any) => {
    let currentProjectRole = userDetails?.project_permissions?.filter(
      (ele: any) => ele.project_id === projectDetails?.project_id,
    )?.[0];

    if (!currentProjectRole) {
      currentProjectRole = { role_id: projectDetails?.associated_role_id };
    }

    if (!member.should_delete) {
      return true;
    }

    if (member?.email === userDetails.email) {
      return true;
    }

    if (
      currentProjectRole?.role_id !== 3 &&
      currentProjectRole?.role_id !== 8 &&
      member.role_id === 3
    ) {
      return true;
    }
    return false;
  };

  return (
    <Fragment>
      {sortedAssociatedUsers?.length > 0 && (
        <div
          className={`projectMembersWrap ${
            mode === modeOps.CLONE ? 'cloneProjectMemberRoleSelection' : ''
          }`}
        >
          <div className="projectRightDrawerSectionHeading">
            <p>{pageSpecificDataState?.lbl_team_member}</p>
            <span
              className={`sidePanelIcon ${
                showProjectMemberBlock ? 'chevron-down' : 'chevron-up'
              }`}
              onClick={() => setShowProjectMemberBlock && setShowProjectMemberBlock((prev) => !prev)}
            ></span>
          </div>
          {showProjectMemberBlock ? (
            <ul className="memberRoleSection">
              {sortedAssociatedUsers?.map(
                (ele: IOrganizationAssociatedUserDetails) =>
                  !ele?.isNew && (
                    <li key={ele?.user_id}>
                      <div>
                        <div className="userBlkWrap">
                          <div
                            className={`userAvtr ${getAssociatedUsersGravatarClassName(
                              ele,
                            )}`}
                          >
                            {getProjectAssociatedUsersGravatarJsx(ele)}
                          </div>
                          <div className="userName-details">
                            {ele.inactive ? (
                              <></>
                            ) : (
                              <div className="userName">
                                <span>{ele.full_name_for_display}</span>
                              </div>
                            )}
                            <div
                              className={`${getAssociatedUsersEmailClassName(
                                ele,
                              )}`}
                            >
                              {ele.email}
                            </div>
                          </div>
                        </div>
                        <select
                          name="role"
                          disabled={disableSelectOptions(ele)}
                          style={{ backgroundColor: 'transparent' }}
                          value={ele?.role_id}
                          onChange={(e) => changeRole(ele?.user_id, e)}
                          className={
                            disableSelectOptions(ele) ? 'disabledElement' : ''
                          }
                        >
                          {createMemberDropdownOptions(ele)}
                        </select>
                      </div>

                      <Rbac
                        allowedPermissions={[
                          ERbacPermissions.PROJECT_MEMBER_REMOVE,
                        ]}
                        project_role_id={projectDetails?.associated_role_id!}
                      >
                        <Tooltip
                          title={
                            pageSpecificDataState?.lbl_delete_project_member_tooltip
                          }
                          color={'#2E364C'}
                          placement="bottom"
                        >
                          <span
                            style={{
                              visibility: determineDeleteButtonVisibility(ele),
                            }}
                            className="removeMember material-icons-outlined delete-class deleteFolderBtn"
                            onClick={() => removeUser(ele)}
                            onKeyDown={() => {}}
                          >
                            delete_outline
                          </span>
                        </Tooltip>
                      </Rbac>
                    </li>
                  ),
              )}
              {sortedAssociatedUsers?.map(
                (ele: IOrganizationAssociatedUserDetails) =>
                  ele?.isNew && (
                    <div className="mergedFld mt-10 mr-10" key={ele?.user_id}>
                      <Select
                        showSearch={true}
                        placeholder={pageSpecificDataState?.plc_email}
                        optionFilterProp="children"
                        showArrow={false}
                        filterOption={false}
                        size="large"
                        onSearch={handleSearch}
                        onSelect={onSelect}
                        onBlur={removeField}
                        autoFocus={true}
                        style={{ width: '100%' }}
                        options={
                          mode === modeOps.ADD
                            ? [
                                ...userListState
                                  .filter(
                                    (ele: IOrganizationAssociatedUserDetails) =>
                                      ele.user_id !== userDetails.user_id,
                                  )
                                  .map(
                                    (
                                      user: IOrganizationAssociatedUserDetails,
                                    ) => {
                                      return {
                                        value: user.user_id,
                                        label: (
                                          <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            {generateUserGravatarJsx(user)}
                                            <div style={{ marginLeft: '8px' }}>
                                              {!user?.inactive && (
                                                <>
                                                  <span>{user?.full_name}</span>
                                                  <br />
                                                </>
                                              )}
                                              <span
                                                className={`${getUserDropdownUsernameClassName(
                                                  user,
                                                )}`}
                                              >
                                                {user.email}
                                              </span>
                                            </div>
                                          </div>
                                        ),
                                      };
                                    },
                                  ),
                              ]
                            : [
                                ...userListState.map(
                                  (
                                    user: IOrganizationAssociatedUserDetails,
                                  ) => {
                                    return {
                                      value: user.user_id,
                                      label: (
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          {generateUserGravatarJsx(user)}
                                          <div style={{ marginLeft: '8px' }}>
                                            {!user?.inactive && (
                                              <>
                                                <span>{user?.full_name}</span>
                                                <br />
                                              </>
                                            )}
                                            <span
                                              className={`${getUserDropdownUsernameClassName(
                                                user,
                                              )}`}
                                            >
                                              {user.email}
                                            </span>
                                          </div>
                                        </div>
                                      ),
                                    };
                                  },
                                ),
                              ]
                        }
                      />
                    </div>
                  ),
              )}
            </ul>
          ) : (
            <></>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default RightSideDrawerUserListDropDown;
