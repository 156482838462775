import { IFeedBackPopup } from 'models/interface/pnc.interface';
import { FC, useState } from 'react';
import { useFeedbackStore } from 'stores/feedback.store';
import { useProjectDashboardStore, useUserStore } from 'stores';
import feedbackService from 'services/pncService/feedback.service';
import { useLocation } from 'react-router-dom';
import {
  getCurrentOrganization,
  modifyMessageString,
} from 'utils/commonFunctions';
import dayjs from 'dayjs';
import { projectService } from 'services';
import Editor from 'components/sharedComponents/Editor/Editor';
import Draggable from 'react-draggable';
import { message } from 'antd';
import { usePncCmsDataStore } from 'stores/pncCmsData.store';

const FeedBackPopup: FC<IFeedBackPopup> = ({ closeEditorPopup }) => {
  const { savePayload, updateFeedbackList } = useFeedbackStore(
    (state: any) => state,
  );
  const { projectDetails } = useProjectDashboardStore();
  const { userDetails } = useUserStore((state: any) => state);
  const location = useLocation();
  const { pncFeedbackSpecificCmsData } = usePncCmsDataStore();
  const [taskName, setTaskName] = useState<string>('');

  const getCurrentTimeAsTimestamp = () => {
    let date = new Date();
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);
    let hours = ('0' + date.getHours()).slice(-2);
    let minutes = ('0' + date.getMinutes()).slice(-2);
    let seconds = ('0' + date.getSeconds()).slice(-2);
    let milliseconds = ('00' + date.getMilliseconds()).slice(-3);
    let formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    return formattedDate;
  };

  const org_key = location.pathname.split('/')[2];

  const handleSaveFb = async (editor: any, taggedUserIds: any) => {
    const payload = {
      organizationId: projectDetails?.organization_id,
      projectId: projectDetails?.project_id,
      feedbackId: savePayload.feedbackId,
      feedbackBy: userDetails?.user_id,
      feedback: modifyMessageString(editor),
      feedbackCoordinates: {
        top: savePayload.top,
        left: savePayload.left,
        width: savePayload.width,
        height: savePayload.height,
      },
      taskName: taskName,
    };
    try {
      const response: any = await feedbackService.saveFeedback(payload);
      if (response.data.messageId === 1) {
        updateFeedbackList(response.data.data.feedback_id, {
          details_id: response.data.data.details_id,
          full_name: userDetails?.full_name,
          feedback_reply_data: [],
          task_status_id: 1,
          feedback_id: response.data.data.feedback_id,
          reported_by: userDetails?.user_id,
          reported_on: getCurrentTimeAsTimestamp(),
          feedback_coordinates: {
            top: savePayload.top,
            left: savePayload.left,
            width: savePayload.width,
            height: savePayload.height,
          },
          task_priority_id: 2,
        });
        taggedUserIds && sendNotification(taggedUserIds, editor);
        message.success(
          pncFeedbackSpecificCmsData?.toaster_msgs?.success_save_fb_details,
        );
        setTaskName('');
      } else {
        console.log(response);
        message.error(
          pncFeedbackSpecificCmsData?.toaster_msgs?.error_save_fb_details,
        );
      }
    } catch (error) {
      console.log(error);
      message.error(
        pncFeedbackSpecificCmsData?.toaster_msgs?.error_save_fb_details,
      );
    } finally {
      closeEditorPopup();
    }
  };

  const sendNotification = async (taggedUsers: number[], editor: string) => {
    try {
      await projectService.sendFeedbackMentionNotification({
        users: taggedUsers.filter((e) => e !== userDetails.user_id),
        senderName: userDetails.full_name ?? userDetails.email,
        projectName: projectDetails?.project_name,
        orgKey: org_key,
        teamName: getCurrentOrganization(org_key, userDetails.organization_info)
          ?.organization_name!,
        messageContent: editor,
        projectId: projectDetails?.project_id,
        organizationId: getCurrentOrganization(
          org_key,
          userDetails.organization_info,
        )?.organization_id!,
        date: dayjs().format('MMM D, YYYY'),
        time: dayjs().format('h:mma'),
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Draggable handle=".feedBubblePopHead">
      <div className="feedbackBubblePopup commentContainer">
        <div className="feedBubblePopHead">
          <div className="title">
            {pncFeedbackSpecificCmsData?.feedback_popup_heading}
          </div>
          <button onClick={() => closeEditorPopup()} />
        </div>
        <div className="textEditor customTiptapEditor">
          <Editor
            allowedExtensions={{
              showBoldOutlined: true,
              showItalicOutlined: true,
              showUnorderedListOutlined: true,
              showEmojiControl: true,
              showUploadOutlined: true,
              showLinkOutlined: true,
              showSend: true,
              showCheckList: true,
            }}
            sendMessage={handleSaveFb}
            editorClassName="messageThread"
            setContentAsText={setTaskName}
          />
        </div>
      </div>
    </Draggable>
  );
};

export default FeedBackPopup;
