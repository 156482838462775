import {
  BoldOutlined,
  ItalicOutlined,
  LinkOutlined,
  UnorderedListOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import Picker from '@emoji-mart/react';
// import data from '@emoji-mart/data';
import { Editor } from '@tiptap/core';
import { Popover, Tooltip, message } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import cryptoRandomString from 'crypto-random-string';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { IAllowedExtensions, IProjectState } from 'models/interface';
import React, { Fragment, useState } from 'react';
import BrowserStorageService from 'services/browserStorage.service';
import { useProjectDashboardStore } from 'stores';
import { storage } from 'utils/firebase';
import data from './github_emojis.json';
const allowedFileExtensions = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'doc',
  'docx',
  'txt',
  'xls',
  'xlsx',
  'csv',
  'pdf',
  'pptx',
  'ppt',
  'zip',
  'rar',
  'json',
  'mp4',
  'tiff',
  'jfif',
  'ico',
  'wav',
  'mov',
  'rtf',
  'odt',
  'html',
  'mp3',
  'svg',
  'wmv',
  'xml',
  'webm',
  'webp',
  'oog',
  'ogg',
  'odp',
  'ods',
];

const excludedEmojiIds = [
  'melting_face',
  'saluting_face',
  'face_holding_back_tears',
  'face_with_open_eyes_and_hand_over_mouth',
  'face_with_peeking_eye',
  'face_with_diagonal_mouth',
  'dotted_line_face',
  'hand_with_index_finger_and_thumb_crossed',
  'rightwards_hand',
  'leftwards_hand',
  'palm_down_hand',
  'palm_up_hand',
  'heart_hands',
  'biting_lip',
  'person_with_crown',
  'bubbles',
  'coral',
  'lotus',
  'beans',
  'empty_nest',
  'nest_with_eggs',
  'mirror_ball',
  'low_battery',
  'hamsa',
  'heavy_equals_sign',
  'pirate_flag',
  // 'rolling_on_the_floor_laughing'
];

const EditorControls: React.FC<{
  editor: Editor | null;
  sendMessage: any;
  allowedExtensions?: IAllowedExtensions;
  threadId?: string;
  showEmojiPicker?: boolean;
  setShowEmojiPicker?: React.Dispatch<React.SetStateAction<boolean>>;
  pageCmsData?: any;
  setShowProgressBar: React.Dispatch<React.SetStateAction<boolean>>;
  setProgressBarPercentage: React.Dispatch<React.SetStateAction<number>>;
  progressBarPercentage: number;
  className?: string;
  toolTipPosition?: string;
  isSendDisabled: boolean;
}> = ({
  editor,
  sendMessage,
  allowedExtensions,
  threadId,
  showEmojiPicker,
  setShowEmojiPicker,
  pageCmsData,
  setShowProgressBar,
  setProgressBarPercentage,
  progressBarPercentage,
  className,
  toolTipPosition,
  isSendDisabled,
}) => {
  const browserStorage = BrowserStorageService.getInstance();

  const [linkInputFieldVisible, setLinkInputFieldVisible] = useState(false);
  const [targetUrl, setTargetUrl] = useState('');
  const [attachmentUrls, setAttachmentUrls] = useState<string[]>([]);

  const { messagingCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  if (!editor) {
    return null;
  }

  if (editor.isFocused) {
    if (setShowEmojiPicker && showEmojiPicker === true) {
      setShowEmojiPicker(false);
    }
  }

  const checkIsTextNotSelected = () => {
    const { selection } = editor.state;
    return selection.empty;
  };

  const isValidUrl = (url: string) => {
    //^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w- .\/?%&=]*)?$
    const pattern =
      /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w- .\/?%&=]*)?(#[\w- .\/?%&=]*)?$/;
    return pattern.test(url);
  };

  const handleCancelledOrEmptyInput = () => {
    if (targetUrl === null) {
      setLinkInputFieldVisible(false);
      return true;
    }

    if (targetUrl === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      setLinkInputFieldVisible(false);
      return true;
    }

    return false;
  };

  const handleValidUrlInput = () => {
    const formattedUrl = checkLinkUrl(targetUrl);

    if (checkIsTextNotSelected()) {
      insertLinkAsText(formattedUrl);
    } else {
      setLinkToSelectedText(formattedUrl);
    }

    resetLinkInput();
  };

  const insertLinkAsText = (url: string) => {
    editor.chain().focus().insertContent(`<a href="${url}">${url}</a> `).run();
  };

  const setLinkToSelectedText = (url: string) => {
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    editor.commands.focus('end');
  };

  const resetLinkInput = () => {
    setTargetUrl('');
    setLinkInputFieldVisible(false);
  };

  const showInvalidUrlMessage = () => {
    message.info(
      messagingCmsData
        ? messagingCmsData.lbl_invalid_link
        : pageCmsData.lbl_invalid_link,
      2,
    );
  };

  const handleLinkInput = () => {
    // Handle cases where the input is canceled, empty, or valid
    if (handleCancelledOrEmptyInput()) {
      return;
    }

    if (isValidUrl(targetUrl)) {
      handleValidUrlInput();
    } else {
      showInvalidUrlMessage();
    }
  };

  const onKeyUpEventHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') {
      return;
    }
    handleLinkInput();
  };

  const onBlurEventHandler = () => {
    handleLinkInput();
  };

  const selectEmoji = (data: any) => {
    if(data.id === 'rolling_on_the_floor_laughing'){
      data.id = 'rofl'
    };
    editor.chain().setEmoji(data.id).run();
    setShowEmojiPicker && setShowEmojiPicker(false);
  };

  const parseMentions = (data: any) => {
    const mentions = (data.content || []).flatMap(parseMentions);
    if (data.type === 'mention') {
      mentions.push(data.attrs.id);
    }
    const uniqueMentions = [...new Set(mentions)];

    return uniqueMentions;
  };

  const addAttachment = () => {
    let input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.onchange = async () => uploadFile(input.files);
    input.click();
  };

  // const changeProgress = (
  //   maxPercentage: number,
  //   fileSize: number,
  //   progress: number
  // ) => {
  //   let correctedProgess;
  //   if (maxPercentage > 100) {
  //     if (Math.floor(progressBarPercentage) + 1 >= Math.floor(100 / fileSize)) {
  //       correctedProgess =
  //         ((completeCount * 100 + progress + progressBarPercentage) /
  //           maxPercentage) *
  //         100;
  //       setProgressBarPercentage(correctedProgess);
  //     } else {
  //       correctedProgess =
  //         (completeCount * 100 + progress / maxPercentage) * 100;
  //       setProgressBarPercentage(correctedProgess);
  //     }
  //   } else {
  //     correctedProgess = (completeCount * 100 + progress / maxPercentage) * 100;
  //     setProgressBarPercentage(correctedProgess);
  //   }
  // };

  const setDownloadURL = async (
    storageRef: any,
    extension: string,
    element: any,
  ) => {
    let downloadURL;
    let urls: string[] = [];
    let paths: any = [];
    downloadURL = await getDownloadURL(storageRef);
    urls.push(downloadURL);
    if (
      ['jpg', 'jpeg', 'png', 'gif', 'jfif', 'ico', 'svg', 'webp'].includes(
        extension,
      )
    ) {
      let obj = {
        type: 'image',
        attrs: {
          title: element.name,
          src: downloadURL,
          alt: formatFileSize(element.size),
          onclick: `window.open('${downloadURL}')`,
        },
      };
      paths.push(obj);
    } else {
      addToEditorField(extension, downloadURL, element.name, element.size);
    }
    console.log(paths, urls);
    setImagePathsAndAttachmentUrls(paths, urls);
  };

  const setImagePathsAndAttachmentUrls = (paths: any[], urls: string[]) => {
    editor.chain().insertContent(paths).run();
    editor.commands.enter();
    editor.commands.focus('end');
    setAttachmentUrls((prevState) => [...prevState, ...urls]);
  };

  const uploadFile = async (filesArg: any) => {
    console.log('Hii....', filesArg);
    let userDetails = browserStorage.getUserDetails();

    let notAllowedFileTypeFound = false;
    let files: any = Array.from(filesArg);

    const existingAttachmentLength = getAttachmentUrl(editor.getJSON()).length;

    if (files.length > 10 || existingAttachmentLength + files.length > 10) {
      return message.info(
        messagingCmsData
          ? messagingCmsData.lbl_generic_error_message
          : pageCmsData.lbl_generic_error_message,
        2,
      );
    }

    if (files.some((ele: any) => ele.size / 1048576 > 25)) {
      message.info(
        messagingCmsData
          ? messagingCmsData.lbl_file_size_not_allowed
          : pageCmsData.lbl_file_size_not_allowed,
        2,
      );
      files = files.filter((ele: any) => ele.size / 1048576 <= 25);
    }
    //let maxPercentage = files.length > 0 ? 100 * files.length : 100;
    for (let element of files) {
      let extension = element.name.split('.').pop();
      if (allowedFileExtensions.includes(extension.toLowerCase())) {
        const storageRef = ref(
          storage,
          `/${threadId}/${userDetails?.user_id}/${extension}/${
            element.name.split('.')[0]
          }|${cryptoRandomString({ length: 10 })}.${extension}`,
        );

        let uploadTask = uploadBytesResumable(storageRef, element);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            //changeProgress(maxPercentage, files.length, progress);
            if (progressBarPercentage === 100) {
              setShowProgressBar(false);
              setProgressBarPercentage(0);
            } else {
              setShowProgressBar(true);
              setProgressBarPercentage(progress);
            }
          },
          (error) => {
            console.log(error);
            setShowProgressBar(false);
            setProgressBarPercentage(0);
          },
          () => {
            setShowProgressBar(false);
            setProgressBarPercentage(0);
            setDownloadURL(storageRef, extension, element);
          },
        );
      } else {
        notAllowedFileTypeFound = true;
      }
    }

    if (notAllowedFileTypeFound) {
      message.info(
        messagingCmsData
          ? messagingCmsData.lbl_file_extension_not_allowed
          : pageCmsData.lbl_file_extension_not_allowed,
        2,
      );
    }
  };

  const determineClassName = (extension: string) => {
    let videoFileTypes: string[] = ['mp4', 'mov', 'wmv', 'webm'];
    let audioFileTypes: string[] = ['wav', 'mp3', 'oog', 'ogg'];
    let alreadyClassExists: string[] = [
      'pdf',
      'xls',
      'xlsx',
      'doc',
      'docx',
      'pptx',
      'ppt',
      'zip',
      'rar',
      'html',
      'json',
    ];

    if (videoFileTypes.includes(extension)) {
      return 'video';
    }
    if (audioFileTypes.includes(extension)) {
      return 'audio';
    }
    if (alreadyClassExists.includes(extension)) {
      return extension;
    }

    return 'generic';
  };

  const addToEditorField = (
    extension: string,
    downloadURL: string,
    name: string,
    size: number,
  ) => {
    let linkToAdd;

    linkToAdd = `<a href="${downloadURL}" rel="${name} | ${formatFileSize(
      size,
    )}" target="_blank" class="tiptapExtensionElement ${
      extension === 'txt' ? 'txtFile' : determineClassName(extension)
    }Element ${
      extension === 'txt' ? 'txtFile' : determineClassName(extension)
    }">${name} ${formatFileSize(size)}</a>`;

    let editorHtml = editor.getHTML();

    if (editorHtml === `<p class="tiptapExtensionParagraph"></p>`) {
      editorHtml = linkToAdd;
    } else {
      editorHtml += linkToAdd;
    }
    editor.commands.setContent(editorHtml);
  };

  const extractImageUrls = (
    element: any,
    newUrls: any[],
    oldUrlList: string[],
  ) => {
    if (element.type === 'image' && isImageUploadedAsAttachment(element)) {
      const url = element.attrs.src;
      const data = {
        url,
        size: element.attrs.alt,
        name: element.attrs.title,
      };
      newUrls.push(data);
      removeOldUrl(oldUrlList, url);
    }
  };

  const extractLinkUrls = (ele: any, newUrls: any[], oldUrlList: string[]) => {
    if (
      ele &&
      ele[0].hasOwnProperty('marks') &&
      ele[0].marks[0].type === 'link'
    ) {
      const linkAttrs = ele[0].marks[0].attrs;
      if (linkAttrs.rel !== 'noopener noreferrer nofollow') {
        const url = linkAttrs.href;
        const data = {
          url,
          size: linkAttrs.rel.split('|')[1],
          name: linkAttrs.rel.split('|')[0],
        };
        newUrls.push(data);
        removeOldUrl(oldUrlList, url);
      }
    }
  };

  const removeOldUrl = (oldUrlList: string[], url: string) => {
    const index = oldUrlList.findIndex((ele) => ele === url);
    if (index !== -1) {
      oldUrlList.splice(index, 1);
    }
  };

  const deleteFirebaseObject = (
    desertRef: any,
    ele: string,
    setAttachmentUrls: React.Dispatch<React.SetStateAction<string[]>>,
  ) => {
    deleteObject(desertRef)
      .then(() => {
        setAttachmentUrls((prevState: string[]) =>
          prevState.filter((itm) => itm !== ele),
        );
      })
      .catch((err) => {
        console.log('DELETE ERROR -> ', err);
      });
  };

  const deleteOldUrls = (
    oldUrlList: string[],
    setAttachmentUrls: React.Dispatch<React.SetStateAction<string[]>>,
  ) => {
    oldUrlList.forEach((ele) => {
      const desertRef = ref(storage, ele);
      deleteFirebaseObject(desertRef, ele, setAttachmentUrls);
    });
  };

  const getAttachmentUrl = (data: any) => {
    const content = data.content;
    const oldUrlList = [...attachmentUrls];
    const newUrls: any[] = [];

    content.forEach((element: any) => {
      extractImageUrls(element, newUrls, oldUrlList);
      if (element?.content) {
        extractLinkUrls(element.content, newUrls, oldUrlList);
      }
    });

    deleteOldUrls(oldUrlList, setAttachmentUrls);

    return newUrls;
  };

  function formatFileSize(bytes: number) {
    if (bytes < 0) {
      return '';
    }

    const units = ['bytes', 'KB', 'MB'];
    const factors = [1, 1024, 1024 * 1024];

    let index = 0;
    while (index < units.length - 1 && bytes >= factors[index + 1]) {
      index++;
    }

    let convertedSize: string | number = bytes / factors[index];
    if (convertedSize !== Math.round(convertedSize)) {
      convertedSize = (bytes / factors[index]).toFixed(2);
    }

    return `${convertedSize} ${units[index]}`;
  }

  const checkLinkUrl = (url: string) => {
    if (url.startsWith('https://') || url.startsWith('http://')) {
      return url;
    }

    return `https://${url}`;
  };

  const removeWhiteSpace = () => {
    const remainingStr = editor
      .getHTML()
      .replaceAll('<p class="tiptapExtensionParagraph"></p>', '')
      .split(' ')
      .join('')
      .replaceAll('<p class="tiptapExtensionParagraph"></p>', '');

    return remainingStr !== '';
  };

  const removeWhiteSpaceFromText = () => {
    const contentTypes: any = editor.getJSON().content;
    return contentTypes.find((el: any) => el.type !== 'paragraph')
      ? removeWhiteSpace()
      : Boolean(editor.getText()?.trim());
  };

  const sendTypedMessage = () => {
    let shouldMessageSend =
      targetUrl === '' ? true : isValidUrl(targetUrl) ? true : false;
    if (shouldMessageSend) {
      if (removeWhiteSpaceFromText()) {
        editor.commands.focus('end');
        editor.commands.enter();
        editor.commands.enter();
        if (removeWhiteSpace()) {
          let actualAttachment = getAttachmentUrl(editor.getJSON());
          sendMessage(
            editor.getHTML(),
            parseMentions(editor.getJSON()),
            actualAttachment,
          );
          if (setShowEmojiPicker) {
            setShowEmojiPicker(false);
          }
          setAttachmentUrls([]);
          editor.commands.clearContent();
        } else {
          editor.commands.clearContent();
        }
      } else {
        editor.commands.focus('end');
      }
    }
  };

  const isImageUploadedAsAttachment = (content: any) => {
    let url = content.attrs.src;
    return url.startsWith('https://firebasestorage.googleapis.com');
  };

  const handleEmojiClick = () => {
    if (setShowEmojiPicker) {
      setShowEmojiPicker(!showEmojiPicker);
    }
  };

  return (
    <Fragment>
      {linkInputFieldVisible ? (
        <input
          value={targetUrl}
          onChange={(e) => setTargetUrl(e.target.value)}
          onKeyUp={(e) => onKeyUpEventHandler(e)}
          onBlur={() => onBlurEventHandler()}
          autoFocus
        />
      ) : null}
      <div className="emojiMsg d-flex">
        <div className="leftActions d-flex">
          {allowedExtensions?.showBoldOutlined && (
            <Tooltip
              title={
                messagingCmsData
                  ? messagingCmsData?.lbl_extension_tooltip['0']
                  : pageCmsData?.lbl_extension_tooltip['0']
              }
              placement={(toolTipPosition as TooltipPlacement) ?? 'bottom'}
              // placement='bottom'
              arrow={true}
              color={'#2E364C'}
            >
              <BoldOutlined
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={editor.isActive('bold') ? 'is-active' : ''}
              />
            </Tooltip>
          )}

          {allowedExtensions?.showItalicOutlined && (
            <Tooltip
              title={
                messagingCmsData
                  ? messagingCmsData?.lbl_extension_tooltip['1']
                  : pageCmsData?.lbl_extension_tooltip['1']
              }
              placement={(toolTipPosition as TooltipPlacement) ?? 'bottom'}
              arrow={true}
              color={'#2E364C'}
            >
              <ItalicOutlined
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={editor.isActive('italic') ? 'is-active' : ''}
              />
            </Tooltip>
          )}

          {allowedExtensions?.showUnorderedListOutlined && (
            <Tooltip
              title={
                messagingCmsData
                  ? messagingCmsData?.lbl_extension_tooltip['2']
                  : pageCmsData?.lbl_extension_tooltip['2']
              }
              placement={(toolTipPosition as TooltipPlacement) ?? 'bottom'}
              arrow={true}
              color={'#2E364C'}
            >
              <UnorderedListOutlined
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={editor.isActive('bulletList') ? 'is-active' : ''}
              />
            </Tooltip>
          )}

          {allowedExtensions?.showCheckList && (
            <Tooltip
              title={
                messagingCmsData
                  ? messagingCmsData?.lbl_extension_tooltip['3']
                  : pageCmsData?.lbl_extension_tooltip['3']
              }
              placement="bottom"
              arrow={true}
              color={'#2E364C'}
            >
              <span
                className={`cmnIcon checkList ${
                  editor.isActive('taskList') ? 'is-active' : ''
                }`}
                onClick={() => editor.chain().focus().toggleTaskList().run()}
                onKeyDown={() => {}}
              ></span>
            </Tooltip>
          )}

          {allowedExtensions?.showEmojiControl && (
            <Popover
              placement="bottomRight"
              trigger={'click'}
              content={
                <Picker
                  data={data}
                  onEmojiSelect={selectEmoji}
                  theme="dark"
                  locale={browserStorage.getLocalization().language}
                  exceptEmojis={excludedEmojiIds}
                />
              }
              open={showEmojiPicker}
              overlayClassName={'messageThreadClass ' + className}
              arrow={false}
            >
              <Tooltip
                title={
                  messagingCmsData
                    ? messagingCmsData?.lbl_extension_tooltip['4']
                    : pageCmsData?.lbl_extension_tooltip['4']
                }
                placement="bottom"
                arrow={true}
                color={'#2E364C'}
              >
                <span
                  className={`cmnIcon emoji`}
                  onClick={handleEmojiClick}
                ></span>
              </Tooltip>
            </Popover>
          )}

          {allowedExtensions?.showUploadOutlined && (
            <Tooltip
              title={
                messagingCmsData
                  ? messagingCmsData?.lbl_extension_tooltip['5']
                  : pageCmsData?.lbl_extension_tooltip['5']
              }
              placement="bottom"
              arrow={true}
              color={'#2E364C'}
            >
              <UploadOutlined
                className={
                  getAttachmentUrl(editor.getJSON()).length >= 10
                    ? 'uploadButtonDisabled'
                    : 'uploadButtonEnabled'
                }
                onClick={() => {
                  if (getAttachmentUrl(editor.getJSON()).length < 10) {
                    addAttachment();
                  }
                }}
              />
            </Tooltip>
          )}
          {allowedExtensions?.showLinkOutlined && (
            <Tooltip
              title={
                messagingCmsData
                  ? messagingCmsData?.lbl_extension_tooltip['6']
                  : pageCmsData?.lbl_extension_tooltip['6']
              }
              placement={(toolTipPosition as TooltipPlacement) ?? 'bottom'}
              arrow={true}
              color={'#2E364C'}
            >
              <LinkOutlined
                onClick={() => {
                  const previousUrl = editor.getAttributes('link').href;
                  setTargetUrl(previousUrl);
                  setLinkInputFieldVisible(true);
                }}
              />
            </Tooltip>
          )}
        </div>
        {allowedExtensions?.showSend && (
          <div
            className={`rightAction ${
              !removeWhiteSpaceFromText() || isSendDisabled
                ? 'sendButtonDisabled'
                : 'sendButtonEnabled'
            }`}
            onClick={() => {
              !(!removeWhiteSpaceFromText() || isSendDisabled) &&
                sendTypedMessage();
            }}
            onKeyDown={() => {}}
          >
            <span className="cmnIcon send"></span>
          </div>
        )}

        {allowedExtensions?.showUndoRedo && (
          <div className="d-flex rightActions">
            <Tooltip
              title={
                messagingCmsData
                  ? messagingCmsData?.lbl_extension_tooltip['7']
                  : pageCmsData?.lbl_extension_tooltip['7']
              }
              placement={(toolTipPosition as TooltipPlacement) ?? 'bottom'}
              arrow={true}
              color={'#2E364C'}
            >
              <span
                className="undo"
                onClick={() => editor.chain().focus().undo().run()}
                // disabled={!editor.can().undo()}
              ></span>
            </Tooltip>

            <Tooltip
              title={
                messagingCmsData
                  ? messagingCmsData?.lbl_extension_tooltip['8']
                  : pageCmsData?.lbl_extension_tooltip['8']
              }
              placement={(toolTipPosition as TooltipPlacement) ?? 'bottom'}
              arrow={true}
              color={'#2E364C'}
            >
              <span
                className="redo"
                onClick={() => editor.chain().focus().redo().run()}
                // disabled={!editor.can().redo()}
              ></span>
            </Tooltip>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default EditorControls;
