import {
  IGridList,
  IProjectState,
  ITaskList,
} from 'models/interface';
import { FC } from 'react';
import { useLocalizationStore, useProjectDashboardStore } from 'stores';
import { getPageSpecificData, removeOverlay } from 'utils/commonFunctions';
import TeamOptionalFields from 'components/pages/ProfileSettingAndPrefrence/TeamManagement/Settings/TeamOptionalFields';


const RightSideDrawerForOptionalField: FC<{
  showSidePanelForOptionalField: boolean;
  setShowEditOptionalFieldPanel: React.Dispatch<React.SetStateAction<boolean>>;
  sectionDetails: ITaskList | IGridList;
  setSectionDetails: React.Dispatch<
    React.SetStateAction<ITaskList | IGridList>
  >;
  taskListDetails?: any
}> = ({
  showSidePanelForOptionalField,
  setShowEditOptionalFieldPanel,
  sectionDetails,
  setSectionDetails,
  taskListDetails
}) => {
    const { customOptionalFieldCmsData } =
      useProjectDashboardStore((state: IProjectState) => state);

    const { cmsData } = useLocalizationStore((state: any) => state);

    let optionalFieldCmsData = customOptionalFieldCmsData;

    const closeSidePanel = () => {
      setShowEditOptionalFieldPanel(false);
      removeOverlay();
    };

    return showSidePanelForOptionalField ? (
      <div className="customizeOptionalFieldUpdateDrawer createNewProjectForm addToggle">
        <div className="modal-content">
          <div className="modal-header">
            <h3>{optionalFieldCmsData?.lbl_of_edit_header_btn}</h3>
            <span
              className="material-icons-outlined modal-close"
              onClick={() => closeSidePanel()}
              onKeyDown={() => { }}
            >
              close
            </span>
          </div>
          <div className="formContent">
            {sectionDetails.section_type_id === 1 && (
              <div className="customOptionalFieldUpdateWrapper customizeTaskName">
                {<TeamOptionalFields
                  pageCms={{
                    ...(getPageSpecificData(cmsData, 'task-list')?.[0]?.attributes ||
                      {}),
                    ...(getPageSpecificData(cmsData, 'profile-setting')?.[0]
                      ?.attributes || {}),
                  }}
                  usedOutsideOfSettings={true}
                  sectionOfSettings={taskListDetails?.of_settings}
                  customClassName='sectionOptionalFieldsDetails'
                  showOfSettingsBlock={true}
                  sectionName={`${optionalFieldCmsData?.lbl_of_header} ${sectionDetails?.section_name}`}
                />}
              </div>
            )}

          </div>
          <div className="modal-footer">
            <div className="btnSec">
              <button className="noStyle" onClick={() => closeSidePanel()}>
                {optionalFieldCmsData?.lbl_of_cancel_btn}
              </button>
              <button
                disabled={true}
                className="btnStyle1"
              // onClick={() => saveChanges()}
              >
                {optionalFieldCmsData?.lbl_of_update_btn}
              </button>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <></>
    );
  };
export default RightSideDrawerForOptionalField;
