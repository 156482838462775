import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { message } from 'antd';

import { RightSideDrawer, useAnalytics } from 'components/sharedComponents';
import {
  IDashboardApiResponse,
  ILoaderState,
  IOrganizationAssociatedUserDetails,
  IProjectData,
  IProjectFolderDetails,
  IProjectList,
  IProjectMember,
  IProjectState,
  IProjectSubmitData,
} from 'models/interface';
import {
  useDashboardStore,
  useLoaderStore,
  useProjectDashboardStore,
  useUserStore,
} from 'stores';
import {
  addRemoveOverLay,
  getCurrentOrganization,
  removeOverlay,
} from 'utils/commonFunctions';
import { projectService, loggerService } from 'services';
import {
  generateNewListFromCreateProject,
  generateNewProjectList,
} from 'utils/generateNewList';
import { modeOps } from 'models/enums/constants';
import {
  getMessageThreadByProject,
  updateMembers,
} from 'utils/firebaseCommonFunctions';
import { useFetchNewPermission } from 'components/sharedComponents/hooks';

const UpdateProject: FC<{
  pageSpecificDataState?: any;
  showSidePanel: boolean;
  setShowSidePanel: React.Dispatch<React.SetStateAction<boolean>>;
  getProjectDetails: () => Promise<void>;
}> = ({
  pageSpecificDataState,
  showSidePanel,
  setShowSidePanel,
  getProjectDetails,
}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [associatedUser, setAssociatedUser] = useState<any>([]);
  const [ofSettings, setOfSettings] = useState<any>(null);
  const [shouldCallBackendOf, setShouldCallBackendOf] =
    useState<boolean>(false);

  const storeUserDetail = useUserStore((state) => state.userDetails);
  const { projectDetails, projectMembers, initiateProjectMembers } =
    useProjectDashboardStore((state: IProjectState) => state);
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);

  const gaContext = useAnalytics();
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];

  const { userDetails } = useUserStore((state: any) => state);
  const { folderProjectList, setFolderProjectList, setProjectList } =
    useDashboardStore((state: any) => state);

  const { fetchNewPermission } = useFetchNewPermission();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    reset,
    setFocus,
    formState: { errors },
    getValues,
    clearErrors,
  } = useForm<IProjectSubmitData>();

  useEffect(() => {
    if (projectDetails) {
      setValue('projectName', projectDetails?.project_name!);
      setValue('folderId', projectDetails?.folder_id!);
    }
  }, [projectDetails, showSidePanel]);

  useEffect(() => {
    if (projectMembers) {
      let users = generateUserList(projectMembers);
      setAssociatedUser(users);
    }
  }, [projectMembers, showSidePanel]);

  useEffect(() => {
    if (showSidePanel) {
      setIsDisabled(true);
      setFocus('projectName');
    }
  }, [showSidePanel]);

  useEffect(() => {
    let newProjectDetails = {
      projectName: getValues('projectName'),
      folderId: +getValues('folderId'),
    };

    if (
      newProjectDetails.projectName?.trim().length < 4 ||
      newProjectDetails.projectName?.trim().length > 50
    ) {
      setIsDisabled(true);
      setError('projectName', {
        message:
          pageSpecificDataState?.lbl_error_message_project_name_validation,
      });
    } else {
      clearErrors('projectName');
      setIsDisabled(
        !shouldCallBackend(
          newProjectDetails as IProjectSubmitData,
          associatedUser,
          projectDetails,
          projectMembers,
        ),
      );
    }
  }, [
    watch('projectName'),
    watch('folderId'),
    associatedUser,
    projectDetails,
    projectMembers,
  ]);

  const sendAnalyticsEvent = () => {
    gaContext.updateProps({
      username: userDetails.full_name,
      email: userDetails.email,
      organization: getCurrentOrganization(
        org_key,
        userDetails.organization_info,
      )
        ? getCurrentOrganization(org_key, userDetails.organization_info)
            ?.organization_name
        : '',
      eventName: 'Update Project Finished',
      eventCategory: 'Projects',
      eventLabel: 'Update Project',
    });

    return true;
  };

  const shouldCallBackend = (
    newProjectDetails: IProjectSubmitData,
    newProjectMember: IOrganizationAssociatedUserDetails[],
    oldProjectDetails: IProjectData | null,
    oldProjectMember: IProjectMember[] | null,
  ) => {
    let newMember = newProjectMember
      .filter((ele: IOrganizationAssociatedUserDetails) => ele.should_delete)
      .map((ele: IOrganizationAssociatedUserDetails) => {
        return { userId: ele.user_id, roleId: ele.role_id };
      })
      .sort((a, b) => a.userId - b.userId);

    let oldMember = oldProjectMember
      ?.filter((ele: IProjectMember) => ele?.should_delete)
      .map((ele: IProjectMember) => {
        return { userId: ele.user_id, roleId: ele.role_id };
      })
      .sort((a, b) => a.userId - b.userId);

    if (
      newProjectDetails.projectName?.trim() ===
        oldProjectDetails?.project_name?.trim() &&
      newProjectDetails.folderId == oldProjectDetails?.folder_id &&
      JSON.stringify(oldMember) === JSON.stringify(newMember)
    ) {
      return false;
    }

    return true;
  };

  const onSubmitHandler = async (data: IProjectSubmitData) => {
    sendAnalyticsEvent();
    if (
      shouldCallBackend(data, associatedUser, projectDetails, projectMembers) ||
      shouldCallBackendOf
    ) {
      try {
        setLoaderState('active');
        const oldMember = initiateProjectMembers?.map((e) => e.email) ?? [];
        let payload = {
          projectId: projectDetails?.project_id,
          projectName: data.projectName.trim(),
          projectDescription: '',
          organizationId: getCurrentOrganization(
            org_key,
            userDetails?.organization_info,
          )?.organization_id!,
          folderId: +data.folderId,
          associatedUsers: associatedUser.map(
            (ele: IOrganizationAssociatedUserDetails) => {
              return { userId: ele.user_id, roleId: ele.role_id };
            },
          ),
          newUserAdded: associatedUser
            .filter(
              (ele: IOrganizationAssociatedUserDetails) =>
                !oldMember.includes(ele.email),
            )
            .map((ele: IOrganizationAssociatedUserDetails) => {
              return {
                userId: ele.user_id,
                roleId: ele.role_id,
                email: ele.email,
                projectName: data.projectName.trim(),
                fullName: ele.full_name,
                orgKey: org_key,
                language: ele.additional_settings?.language ?? 'en',
                organizationId: getCurrentOrganization(
                  org_key,
                  userDetails?.organization_info,
                )?.organization_id!,
                organizationName: getCurrentOrganization(
                  org_key,
                  userDetails?.organization_info,
                )?.organization_name!,
                senderName: storeUserDetail?.full_name!,
              };
            }),
          of_settings: ofSettings,
        };

        let response: IDashboardApiResponse =
          await projectService.updateProject(payload);

        if (response.messageId === 1) {
          let targetIndex: number[] = [];
          let targets = folderProjectList.filter(
            (ele: IProjectFolderDetails) => ele.row_is_duplicate === true,
          );

          folderProjectList.forEach(
            (element: IProjectFolderDetails, index: number) => {
              if (element.row_is_duplicate) targetIndex.push(index);
            },
          );

          let newList: IProjectFolderDetails[] =
            generateNewListFromCreateProject(response.data);
          let newProjectList: IProjectList[] = generateNewProjectList(
            response.data,
          );

          targetIndex.forEach((ele, index) => {
            newList.splice(ele, 0, targets[index]);
          });

          getProjectDetails();

          sendAnalyticsEvent();
          setFolderProjectList(newList);
          setProjectList(newProjectList);
          //addRemoveOverLay();
          removeOverlay();
          setShowSidePanel(false);

          const responseFireStore = await getMessageThreadByProject(
            +getCurrentOrganization(org_key, userDetails?.organization_info)
              ?.organization_id!,
            projectDetails?.project_id!,
          );

          let messageThreadId: string[] = [];

          responseFireStore.forEach((doc: any) => {
            messageThreadId.push(doc.id);
          });

          let userId = associatedUser.map(
            (ele: IOrganizationAssociatedUserDetails) => {
              return { user_id: ele.user_id, user_name: ele.full_name };
            },
          );

          for (let id of messageThreadId) {
            await updateMembers(id, userId, data.projectName.trim());
          }
        } else if (response.messageId === -2) {
          setError('projectName', {
            message:
              pageSpecificDataState?.lbl_error_message_project_name_exists,
          });
        } else if (response.messageId === -3) {
          fetchNewPermission(
            org_key,
            pageSpecificDataState?.lbl_error_message_permission_denied,
          );
        }
      } catch (err) {
        message.error(pageSpecificDataState?.lbl_error_message_generic, 3);
        await loggerService.log({
          severity: 'High',
          message: `project creation api failed`,
          payload: { ApiResponse: err },
        });
      } finally {
        setLoaderState('inactive');
      }
    } else {
      //addRemoveOverLay();
      removeOverlay();
      setShowSidePanel(false);
    }
  };

  const generateUserList = (projectMembers: IProjectMember[]) => {
    let tempList = [...projectMembers];

    let owner = tempList
      .filter((ele) => !ele.should_delete)
      .map((ele) => {
        return {
          ...ele,
          full_name_for_display: ele.full_name ? ele.full_name : ele.email,
        };
      });

    let other = tempList
      .filter((ele) => ele.should_delete)
      .map((ele) => {
        if (ele.full_name) {
          return ele;
        } else {
          return {
            ...ele,
            full_name: 'ZZ',
          };
        }
      })
      .sort((a: any, b: any) =>
        (a?.full_name).toLowerCase() > (b?.full_name).toLowerCase() ? 1 : -1,
      )
      .map((ele) => {
        if (ele.full_name !== 'ZZ') {
          return ele;
        } else {
          return {
            ...ele,
            full_name: '',
          };
        }
      })
      .map((ele) => {
        return {
          ...ele,
          full_name_for_display: ele.full_name ? ele.full_name : '',
        };
      });

    return [...owner, ...other].map((eachMember) =>
      eachMember.user_id === userDetails?.user_id
        ? {
            ...eachMember,
            full_name_for_display: `${eachMember.full_name} (${pageSpecificDataState?.lbl_default_member_suffix})`,
          }
        : eachMember,
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <RightSideDrawer
        pageSpecificDataState={pageSpecificDataState}
        showSidePanel={showSidePanel}
        setShowSidePanel={setShowSidePanel}
        register={register}
        errors={errors}
        isDisabled={isDisabled}
        getValues={getValues}
        setIsDisabled={setIsDisabled}
        reset={reset}
        associatedUser={associatedUser}
        setAssociatedUser={setAssociatedUser}
        mode={modeOps.EDIT}
        setValue={setValue}
        setOfSettings={setOfSettings}
        setShouldCallBackendOf={setShouldCallBackendOf}
      />
    </form>
  );
};

export default UpdateProject;
