import { Dropdown, MenuProps } from 'antd';
import { IProjectState } from 'models/interface';
import React, { useEffect, useState } from 'react';
import { projectService } from 'services';
import { useUpdateMessagingCMS } from 'components/sharedComponents/hooks';
import { useProjectDashboardStore } from 'stores';
import ListWithBackgroundColors from 'components/sharedComponents/ListWithBackgroundColors/ListWithBackgroundColors';

interface IProjectMeatballMenu {
  updateProjectColor: (obj: any) => void;
  // pageCms?: any;
}

type TProjectPalette = {
  color_id: number;
  color_name: string;
  color_rank: number;
  hex_code: string;
  rgb_value: string;
};

const ProjectMeatballMenu: React.FC<IProjectMeatballMenu> = ({
  updateProjectColor,
}) => {
  const [colorPalette, setColorPalette] = useState<TProjectPalette[]>([]);
  const [loadCms, setLoadCms] = useState(false);
  const { taskListCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  useUpdateMessagingCMS(loadCms);

  const getColorPalette = async () => {
    try {
      const result: any = await projectService.getProjectColorPalette();
      const color = result.data.data ?? [];
      setColorPalette(color);
    } catch (error) {
      console.error(error);
      setColorPalette([]);
    }
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className="labelColorDropdown">
          <span className="labelColorDropdownInitial">a</span>
          <span>{taskListCmsData?.color_menu?.label}</span>
        </div>
      ),
      children: [
        {
          key: '1-1',
          className: 'projectColorSubmenu',
          label: (
            <div className="colorPalette">
              <ListWithBackgroundColors
                withPaletteImage={true}
                colors={colorPalette}
                callBackFunction={updateProjectColor}
              />
            </div>
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    getColorPalette();
    setLoadCms(true);
    return () => {
      setColorPalette([]);
    };
  }, []);

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      overlayClassName="projectMeatballMenu"
      placement="bottomLeft"
    >
      <a onClick={(e) => e.preventDefault()}>
        <span className="cmnIcon more" />
      </a>
    </Dropdown>
  );
};

export default ProjectMeatballMenu;
