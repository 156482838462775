import { FC, useEffect, useState } from 'react';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import {
  IProjectCreationAttributes,
  IProjectState,
  IProjectSubmitData,
} from 'models/interface';
import {
  useDashboardStore,
  useProjectDashboardStore,
  useLocalizationStore,
  useUserStore,
} from 'stores';
import {
  addRemoveOverLay,
  removeOverlay,
  getPageSpecificData,
} from 'utils/commonFunctions';

import RightSideDrawerProjectNameInputField from './RightSideDrawerProjectNameInputField';
import RightSideDrawerFolderListDropDown from './RightSideDrawerFolderListDropDown';
import RightSideDrawerAddFolderCTA from './RightSideDrawerAddFolderCTA';
import RightSideDrawerUserListDropDown from './RightSideDrawerUserListDropDown';
import RightSideDrawerCTA from './RightSideDrawerCTA';
import { modeOps } from 'models/enums/constants';
import RightSideDrawerAddMemberCTA from './RightSideDrawerAddMemberCTA';
import { Rbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import RightSideDrawerExistingWidgetList from './RightSideDrawerExistingWidgetList';
import OutsideClickHandler from '../OutsideClickHandler';
import TeamOptionalFields from 'components/pages/ProfileSettingAndPrefrence/TeamManagement/Settings/TeamOptionalFields';

const RightSideDrawer: FC<{
  pageSpecificDataState?: IProjectCreationAttributes | null;
  showSidePanel: boolean;
  setShowSidePanel: React.Dispatch<React.SetStateAction<boolean>>;
  register: UseFormRegister<IProjectSubmitData>;
  errors: any;
  isDisabled: boolean;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  getValues: UseFormGetValues<IProjectSubmitData>;
  reset: any;
  setAssociatedUser: any;
  associatedUser: any;
  mode: modeOps;
  setValue: UseFormSetValue<IProjectSubmitData>;
  existingWidgetList?: any;
  clonedSectionsLoading?: boolean;
  onBlur?: () => void;
  isFetching?: boolean;
  setOfSettings?: React.Dispatch<React.SetStateAction<any>>;
  setShouldCallBackendOf?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  pageSpecificDataState,
  showSidePanel,
  setShowSidePanel,
  register,
  errors,
  isDisabled,
  setIsDisabled,
  getValues,
  reset,
  setAssociatedUser,
  associatedUser,
  mode,
  setValue,
  existingWidgetList,
  clonedSectionsLoading,
  onBlur = () => {},
  isFetching = false,
  setOfSettings,
  setShouldCallBackendOf,
}) => {
  const { userList, setSelectedFolderId } = useDashboardStore(
    (state: any) => state,
  );
  const { userDetails } = useUserStore((state: any) => state);
  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const { cmsData } = useLocalizationStore((state: any) => state);
  const [userListState, setUserListState] = useState([]);
  const [showProjectMemberBlock, setShowProjectMemberBlock] = useState(true);
  const [showSectionBlock, setShowSectionBlock] = useState(true);
  const [showOfSettingsBlock, setShowOfSettingsBlock] = useState(true);
  useEffect(() => {
    setUserListState(userList);
  }, [userList]);

  const cmsModeMapping: Record<modeOps, string | undefined> = {
    [modeOps.ADD]: pageSpecificDataState?.txt_modal_header,
    [modeOps.CLONE]: pageSpecificDataState?.txt_modal_header_clone,
    [modeOps.EDIT]: pageSpecificDataState?.txt_modal_header,
  };

  const getOfSettings = (mode: modeOps) => {
    if (mode === modeOps.ADD || mode === modeOps.CLONE) {
      return null;
    }
    return projectDetails?.of_settings;
  };
  const closeRightPanel = () => {
    //addRemoveOverLay();
    removeOverlay();
    setShowSidePanel(false);
    setUserListState(userList);
    setSelectedFolderId(0);
    if (mode === modeOps.ADD) {
      reset({ projectName: ' ' });
      setAssociatedUser([
        {
          email: userDetails.email,
          user_id: 1,
          full_name: userDetails.full_name,
          full_name_for_display: `${userDetails.full_name} (${pageSpecificDataState?.lbl_default_member_suffix})`,
          profile_picture: userDetails.profile_picture,
          role_id: 3,
          should_delete: false,
          isNew: false,
        },
      ]);
    }
  };

  const getAddMemberCTA = () => {
    if (projectDetails && showProjectMemberBlock) {
      return (
        <Rbac
          allowedPermissions={[ERbacPermissions.PROJECT_MEMBER_ADD]}
          project_role_id={projectDetails?.associated_role_id}
        >
          <RightSideDrawerAddMemberCTA
            pageSpecificDataState={pageSpecificDataState}
            associatedUser={associatedUser}
            setAssociatedUser={setAssociatedUser}
            setUserListState={setUserListState}
            setIsDisabled={setIsDisabled}
            isFetching={isFetching}
          />
        </Rbac>
      );
    } else if (showProjectMemberBlock) {
      return (
        <RightSideDrawerAddMemberCTA
          pageSpecificDataState={pageSpecificDataState}
          associatedUser={associatedUser}
          setAssociatedUser={setAssociatedUser}
          setUserListState={setUserListState}
          setIsDisabled={setIsDisabled}
          isFetching={isFetching}
        />
      );
    }
  };

  return showSidePanel ? (
    // <OutsideClickHandler
    //   onOutsideClick={() => {
    //     closeRightPanel();
    //   }}
    // >
    <div className={`createNewProjectForm ${showSidePanel && 'addToggle'}`}>
      <div className="header">
        <h2>{cmsModeMapping[mode]}</h2>
        <span
          id="rightSideDrawerCloseIcon"
          className="material-icons-outlined close"
          onClick={() => closeRightPanel()}
          onKeyDown={() => {}}
        >
          close
        </span>
      </div>
      <div className="formContent">
        <RightSideDrawerProjectNameInputField
          pageSpecificDataState={pageSpecificDataState}
          register={register}
          errors={errors}
          mode={mode}
          onBlur={onBlur}
          isFetching={isFetching}
        />
        <RightSideDrawerFolderListDropDown
          pageSpecificDataState={pageSpecificDataState}
          register={register}
          mode={mode}
          isFetching={isFetching}
        />
        <RightSideDrawerAddFolderCTA
          pageSpecificDataState={pageSpecificDataState}
          setValue={setValue}
          mode={mode}
          getValues={getValues}
          setIsDisabled={setIsDisabled}
          isFetching={isFetching}
        />
        {mode === modeOps.CLONE && (
          <RightSideDrawerExistingWidgetList
            pageSpecificDataState={pageSpecificDataState}
            register={register}
            setValue={setValue}
            getValues={getValues}
            existingWidgetList={existingWidgetList}
            isLoading={clonedSectionsLoading as boolean}
            isFetching={isFetching}
            showSectionBlock={showSectionBlock}
            setShowSectionBlock={setShowSectionBlock}
          />
        )}
        <RightSideDrawerUserListDropDown
          pageSpecificDataState={pageSpecificDataState}
          associatedUser={associatedUser}
          setAssociatedUser={setAssociatedUser}
          userListState={userListState}
          setUserListState={setUserListState}
          getValues={getValues}
          setIsDisabled={setIsDisabled}
          mode={mode}
          showProjectMemberBlock={showProjectMemberBlock}
          setShowProjectMemberBlock={setShowProjectMemberBlock}
        />

        {getAddMemberCTA()}
        {
          <TeamOptionalFields
            pageCms={{
              ...(getPageSpecificData(cmsData, 'task-list')?.[0]?.attributes ||
                {}),
              ...(getPageSpecificData(cmsData, 'profile-setting')?.[0]
                ?.attributes || {}),
            }}
            usedOutsideOfSettings={true}
            setIsDisabled={setIsDisabled}
            setOfSettings={setOfSettings}
            projectOfSettings={getOfSettings(mode)}
            setShouldCallBackendOf={setShouldCallBackendOf}
            showOfSettingsBlock={showOfSettingsBlock}
            setShowOfSettingsBlock={setShowOfSettingsBlock}
          />
        }
        
      </div>
      <div className="modal-footer">
          <RightSideDrawerCTA
            pageSpecificDataState={pageSpecificDataState}
            closeRightPanel={closeRightPanel}
            isDisabled={isDisabled}
            mode={mode}
            isFetching={isFetching}
          />
        </div>
    </div>
  ) : // </OutsideClickHandler>
  null;
};

export default RightSideDrawer;
