import { Organization } from "../../layout/LeftNav/Organizations";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import {
  IOrganization,
  IProfileSettingCmsData,
  IUserState,
} from "../../../models/interface";
import { useUserStore } from "../../../stores";
import { Rbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { useLocation, useNavigate } from "react-router-dom";
import { NavRoutesEnum } from "models/enums/navRoutes";
import { useOrganizationRoutes } from "routes/orgRoutes";
import NewTeamDrawer from "./NewTeamDrawer";
import { addOverlay, addRemoveOverLay, removeOverlay } from "utils/commonFunctions";
import { FolderProjectCollapsedView } from "components/layout/LeftNav/FolderProjectCollapsedView/FolderProjectCollapsedView";
import { Tooltip } from "antd";

const AllOrganizationLeftNav: FC<{
  orgDetails: IOrganization[];
  currentOrganization: IOrganization;
  setLogoutSwitch: React.Dispatch<React.SetStateAction<boolean>>;
  moreOrgListVisible: boolean;
  setMoreOrgListVisible: React.Dispatch<React.SetStateAction<boolean>>;
  settingLblCms: IProfileSettingCmsData;
  navExpanded: boolean;
  pageCmsData: any;
}> = ({
  orgDetails,
  currentOrganization,
  setLogoutSwitch,
  moreOrgListVisible,
  setMoreOrgListVisible,
  settingLblCms,
  navExpanded,
  pageCmsData,
}) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const { userDetails } = useUserStore((state: IUserState) => state);

  const navigate = useNavigate();
  const location = useLocation();
  const { organizationBaseRouteUrl } = useOrganizationRoutes();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const handleOpenDrawer = () => {
    if (document.getElementById("rightSideDrawerCloseIcon")) {
      document.getElementById("rightSideDrawerCloseIcon")?.click();
    }
    setOpenDrawer(true);
    addOverlay();
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    removeOverlay();
  };

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMoreOrgListVisible(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <Fragment>
      {openDrawer ? (
        <div
          className={`addTeamMemberDrawer customizeStatusUpdateDrawer createNewProjectForm ${
            openDrawer ? "addToggle" : ""
          }`}
        >
          <NewTeamDrawer
            openDrawer={openDrawer}
            handleCloseDrawer={handleCloseDrawer}
            settingLblCms={settingLblCms}
          />
        </div>
      ) : null}
      <div className="companyList">
        <ul>
          {orgDetails !== null &&
          orgDetails !== undefined &&
          orgDetails.length > 0
            ? orgDetails
                .filter((ele) => currentOrganization?.org_key === ele.org_key)
                .map((org: any, index: number) => {
                  const key: string = `${index}-key`;
                  return (
                    <Organization
                      key={key}
                      details={org}
                      userDetails={userDetails!}
                      currentOrganization={currentOrganization}
                    />
                  );
                })
            : null}
          <li
            onClick={() => {
              setMoreOrgListVisible(!moreOrgListVisible);
              setLogoutSwitch(false);
            }}
            onKeyDown={() => {}}
          >
            <span className="cmnIcon more-white"></span>
            {moreOrgListVisible ? (
              <ul
                ref={wrapperRef}
                className={`genericPopup ${
                  orgDetails.filter(
                    (ele) => currentOrganization?.org_key !== ele.org_key
                  ).length === 0
                    ? "emptyOrgList"
                    : ""
                } show`}
              
              >
                {orgDetails
                  .filter((ele) => currentOrganization?.org_key !== ele.org_key)
                  .map((org: any, index: number) => {
                    const key: string = `${index}-key`;
                    return (
                      <li
                        key={key}
                        onClick={() => {
                          navigate("/org/" + org.org_key + "/dashboard");
                        }}
                        onKeyDown={() => {}}
                      >
                        <div className="userBlkWrap">
                          <div
                            className="userAvtr"
                            style={{ backgroundColor: org.org_color }}
                          >
                            {org.organization_logo ? (
                              <img src={org.organization_logo} alt="" />
                            ) : (
                              <Fragment>
                                {org.organization_name
                                  ? org.organization_name
                                      ?.split("")[0]
                                      .toLocaleUpperCase()
                                  : userDetails!.full_name.charAt(0)}
                              </Fragment>
                            )}
                          </div>
                          <div className="userName">
                            {org?.organization_name
                              ? org.organization_name
                              : userDetails!.full_name}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    setMoreOrgListVisible(false);
                    handleOpenDrawer();
                  }}
                  onKeyDown={() => {}}
                >
                  <span className="addPlus"></span>
                  <div className="userName">
                    {settingLblCms?.lbl_add_new_work_space}
                  </div>
                </li>
              </ul>
            ) : null}
          </li>
        </ul>
        {navExpanded ? <FolderProjectCollapsedView /> : null}
        {/* <Rbac allowedPermissions={[ERbacPermissions.PROJECT_CREATE]}>
          <Tooltip
            title={pageCmsData?.btn_create_project}
            color={"#2E364C"}
            placement="right"
          >
            <button
              className="addCompany"
              onClick={() => {
                document.body.classList.remove("body-overlay");
                document.getElementById("rightSideDrawerCloseIcon")?.click();
                navigate(
                  `/org/${location.pathname.split("/")[2]}/${NavRoutesEnum.DASHBOARD_PROJECT_LIST}`
                );
              }}
              onKeyDown={() => {}}
            >
              <span className="addCompanyIcon"></span>
            </button>
          </Tooltip>
        </Rbac> */}
      </div>
    </Fragment>
  );
};

export default AllOrganizationLeftNav;
