import {
  EffortFieldType,
  IGridList,
  IProjectData,
  IUpdateTaskPayload,
  IUserDetails,
} from 'models/interface';
import { projectService } from 'services';
import { getCurrentOrganization, groupGridBlock } from 'utils/commonFunctions';
import { message } from 'antd';
import { DataType } from '../Table/Table';

interface GridService {
  org_key: string;
  userDetails: IUserDetails;
  projectDetails: IProjectData | null;
  sectionDetails: IGridList;
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
}

const useGridService = (props: GridService) => {
  const {
    org_key,
    userDetails,
    projectDetails,
    sectionDetails,
    gridListDetails,
    setGridListDetails,
  } = props;

  function getUserDetails() {
    if (!userDetails.organization_info)
      throw new Error('Missing user organization');
    const organizationId = +getCurrentOrganization(
      org_key,
      userDetails.organization_info,
    )?.organization_id!;

    if (!organizationId) throw new Error('Organization not found');

    const payload = {
      organizationId,
      userId: +userDetails.user_id,
      projectId: projectDetails?.project_id!,
      sectionId: +sectionDetails.section_id,
    };

    return payload;
  }

  const customFieldSettingsAPI = async (
    columns?: DataType[],
    dataSource?: any[],
  ) => {
    try {
      if (!columns || !dataSource) return;
      if (
        !userDetails ||
        !userDetails.organization_info ||
        !userDetails.user_id ||
        !org_key
      )
        throw Error('Organization info not found!');

      const organizationId = getCurrentOrganization(
        org_key,
        userDetails?.organization_info,
      )?.organization_id!;

      const payload = {
        organizationId,
        userId: userDetails.user_id,
        sectionId: sectionDetails.section_id,
        projectId: projectDetails?.project_id,
        settings: {
          columnSettings: columns
            ?.map((i) => ({
              mapping_id: i.dataIndex,
              width: i?.width,
              hidden: false,
            }))
            .filter((i) => i.mapping_id !== 'task'),
          taskRank: dataSource?.map((i) => i.key),
        },
      };

      return await projectService.reOrderGrid(payload);
    } catch (err) {
      console.error(err);
    }
  };

  const reOrderTaskStageAPI = async (
    columns?: DataType[],
    dataSource?: any[],
  ) => {
    try {
      return await customFieldSettingsAPI(columns, dataSource);

      // const userDetails = getUserDetails();
      // const { reOrderGridStage, reOrderGridTask } = projectService;
      // const apiHandler = type === "stage" ? reOrderGridStage : reOrderGridTask;
      // const payload: any = {
      //   ...userDetails,
      // };

      // if (type === "stage") {
      //   payload.sourceStageId = fromId;
      //   payload.destinationStageId = toId;
      // }

      // return await apiHandler(payload);
    } catch (err) {
      console.error(err);
    }
  };

  const addEditCustomField = async ({
    customMeta,
    deleteOptions,
  }: {
    customMeta: any;
    deleteOptions: any;
  }) => {
    try {
      if (
        !userDetails ||
        !userDetails.organization_info ||
        !userDetails.user_id ||
        !org_key
      )
        throw Error('Organization info not found!');

      const organizationId = getCurrentOrganization(
        org_key,
        userDetails?.organization_info,
      )?.organization_id!;

      const payload = {
        organizationId,
        projectId: projectDetails?.project_id,
        userId: userDetails.user_id,
        sectionId: sectionDetails.section_id,
        sectionTypeId: sectionDetails.section_type_id,
        customItem: customMeta,
        deleteOptions,
      };
      const response = await projectService.addEditCustomMeta(payload);
      if (response?.messageId === 1) {
        let customMeta = response.data.custom_meta;
        let newCf = customMeta?.[customMeta?.length - 1]?.mapping_id;
        sessionStorage.setItem('newCustomField', newCf);
      }

      if (response?.messageId === -4) {
        const error = new Error(response?.message);
        (error as any).messageId = response?.messageId;
        throw error;
      }
    } catch (error: any) {
      if (error.messageId === -4) {
        return error;
      }
      message.error('Failed to add custom field', 3);
    }
  };

  const updateSettings = async (gridList: any) => {
    try {
      const { organizationId } = validateData(userDetails, org_key);
      const { message, messageId } =
        await projectService.updateCustomFieldSettingV2({
          organizationId: organizationId,
          userId: userDetails.user_id,
          sectionId: sectionDetails?.section_id,
          settings: {
            columnSettings: gridList?.custom_meta?.filter(
              (ele: any) => ele.key !== 'action',
            ),
            taskRank: gridList?.grid_list_details?.task_details.map(
              (ele: any) => ele.task_id,
            ),
          },
        });
      if (messageId !== 1) throw Error(message);
    } catch (error: any) {
      if (error?.response?.data?.messageId === -4) {
        return error?.response?.data;
      }
      message.error(
        error?.message ?? 'Failed to update custom field settings',
        3,
      );
    }
  };

  const updateGridCustomFieldData = async (
    taskName: string,
    taskId: number,
    customData: any,
    value?: EffortFieldType | null,
  ) => {
    try {
      if (
        !userDetails ||
        !userDetails.organization_info ||
        !userDetails.user_id ||
        !org_key
      )
        throw Error('Organization info not found!');

      const organizationId = getCurrentOrganization(
        org_key,
        userDetails?.organization_info,
      )?.organization_id!;

      if (!projectDetails || !projectDetails.project_id)
        throw Error('Project ID not found!');

      let payload: IUpdateTaskPayload = {
        organizationId,
        projectId: projectDetails.project_id,
        userId: +userDetails.user_id,
        sectionId: sectionDetails.section_id,
        taskId,
        taskName,
        customData,
      };
      //TYPE 1
      if (value?.type === 'effort') {
        payload.mapping_id_to_be_updated = value?.mappingId;
      }

      //TYPE 2
      // let isEffortField=customData?.find((ele:any)=>ele.type==="effort");
      // let isEffortsMappingId=isEffortField.mapping_id;
      // if(isEffortField){
      //   payload.mapping_id_to_be_updated = isEffortsMappingId;
      // }

      await projectService.updateGridTask(payload);
    } catch (error) {
      console.error(error);
    }
  };

  const updateGridList = (response: any) => {
    try {
      const updatedGridListDetails = {
        ...response.data.grid_list_details,
        block_details: groupGridBlock(
          response.data.grid_list_details.block_details,
          response.data.grid_list_details.task_details,
        ),
      };

      setGridListDetails({
        ...gridListDetails,
        custom_meta: response.data.custom_meta,
        grid_list_details: updatedGridListDetails,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteCustomFieldColumnAPI = async (mapping_id: string) => {
    try {
      const { organizationId } = validateData(userDetails, org_key);
      const { data, message, messageId } =
        await projectService.deleteCustomMeta({
          organizationId: organizationId?.toString(),
          userId: userDetails?.user_id,
          sectionId: +sectionDetails.section_id,
          mappingId: mapping_id,
        });
      if (messageId !== 1) throw Error(message);
      return data;
    } catch (error: any) {
      message.error(error?.message ?? 'Failed to delete custom field', 3);
    }
  };

  const addNewCustomField = async ({
    sectionId,
    fieldTypeId,
  }: {
    sectionId: number;
    fieldTypeId: number;
  }) => {
    try {
      const { organizationId } = validateData(userDetails, org_key);

      const response = await projectService.addNewCustomField({
        organizationId,
        projectId: projectDetails?.project_id,
        sectionId,
        fieldTypeId,
      });
      if (response?.messageId !== 1) {
        const error = new Error(response?.message);
        (error as any).messageId = response?.messageId;
        throw error;
      }
      sessionStorage.setItem('newCustomField', response?.data?.mapping_id);
      return response;
    } catch (error: any) {
      if (error?.messageId === -4) {
        return error;
      }
      message.error(error?.message ?? 'Failed to add custom field', 3);
    }
  };

  const deleteCustomFieldV2 = async ({
    sectionId,
    mappingId,
  }: {
    sectionId: number;
    mappingId: string;
  }) => {
    try {
      const { organizationId } = validateData(userDetails, org_key);

      const { data, message, messageId } =
        await projectService.deleteCustomField({
          organizationId,
          projectId: projectDetails?.project_id,
          sectionId: sectionId,
          mappingId,
        });
      if (messageId !== 1) {
        const error = new Error(message);
        (error as any).messageId = messageId;
        throw error;
      }
      return { data, message, messageId };
    } catch (error: any) {
      if (error?.response?.data?.messageId === -4) {
        return error;
      }
      message.error(error?.message ?? 'Failed to delete custom field', 3);

      return [];
    }
  };

  const updateCustomFieldV2 = async (payload: any) => {
    try {
      const { organizationId } = validateData(userDetails, org_key);

      const { data, message, messageId } =
        await projectService.updateCustomField({
          ...payload,
          organizationId,
          projectId: projectDetails?.project_id,
        });

      if (messageId !== 1) {
        const error = new Error(message);
        (error as any).messageId = messageId;
        throw error;
      }
      return { data, message, messageId };
    } catch (error: any) {
      if (error?.response?.data?.messageId === -4) {
        return error;
      }
      message.error(
        error?.message ?? 'Failed to update custom field settings',
        3,
      );

      return [];
    }
  };

  const updateTaskCustomFieldValue = async (payload: any) => {
    try {
      const { organizationId } = validateData(userDetails, org_key);
      const { data, message, messageId } =
        await projectService.updateTaskCustomField({
          ...payload,
          organizationId,
          projectId: projectDetails?.project_id,
          for_effort_field: payload.forEffortField,
        });

      if (messageId !== 1) {
        const error = new Error(message);
        (error as any).messageId = messageId;
        throw error;
      }
      return { data, message, messageId };
    } catch (error: any) {
      return error;
    }
  };

  return {
    deleteCustomFieldColumnAPI,
    reOrderTaskStageAPI,
    addEditCustomField,
    updateGridCustomFieldData,
    updateSettings,
    addNewCustomField,
    deleteCustomFieldV2,
    updateCustomFieldV2,
    updateTaskCustomFieldValue,
  };
};

export default useGridService;

function validateData(userDetails: IUserDetails, org_key: string) {
  if (
    !userDetails ||
    !userDetails.organization_info ||
    !userDetails.user_id ||
    !org_key
  )
    throw Error('Organization info not found!');

  const organizationId = getCurrentOrganization(
    org_key,
    userDetails?.organization_info,
  )?.organization_id!;

  if (!organizationId) throw Error('Organization ID not found!');

  return { organizationId };
}
