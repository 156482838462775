import React, { useState } from 'react';
import { Popover, Button, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import {
  CustomField,
  CustomFieldCategory,
} from 'models/interface/customField.interface';
import {
  AttachmentIcon,
  PriorityIcon,
  NumberIcon,
} from 'components/sharedComponents/CustomAppIcons';
import { ICustomFieldModalAttributes } from 'models/interface';
import singleSelectIcon from '../../../../../../../assets/images/single-select.svg';
import multiSelectIcon from '../../../../../../../assets/images/multiSelect.svg';
import shortText from '../../../../../../../assets/images/short-text.svg';
import dueDate from '../../../../../../../assets/images/date.svg';
import effort from '../../../../../../../assets/images/effort.svg';

interface AddCustomFieldProps {
  options: CustomFieldCategory[];
  onClick: (option: CustomField) => void;
  customfieldCmsData?: ICustomFieldModalAttributes;
}

const getIcon = (fieldId: number) => {
  switch (fieldId) {
    case 1:
      return <PriorityIcon />;
    case 2:
      return <NumberIcon />;
    case 3:
      return <AttachmentIcon />;
    case 4:
      return <img src={singleSelectIcon} alt="" />;
    case 5:
      return <img src={multiSelectIcon} alt="" />;
    case 6:
      return <img src={shortText} alt="" />;
    case 7:
      return <img src={dueDate} alt="" />;
    case 8:
      return <img src={effort} alt="" />;
    default:
      return null;
  }
};

const AddCustomField: React.FC<AddCustomFieldProps> = ({
  options,
  onClick,
  customfieldCmsData,
}) => {
  const [visible, setVisible] = useState(false);
  const handleOptionClick = (option: CustomField) => {
    let selectedOption = { ...option };
    if (option.field_type_id === 2) {
      selectedOption.default_meta = {
        ...(option?.default_meta ?? {}),
      };
    }
    onClick(selectedOption);
    setVisible(false);
  };

  const handleClose = () => {
    setVisible(false);
  };
  const getLocalizedCategoryName = (option: CustomFieldCategory) => {
    const foundIndex =
      customfieldCmsData?.lbl_add_column_popup_options.findIndex(
        (item: any) => item.category_id === option.category_id,
      ) ?? -1;
    if (foundIndex !== -1) {
      const item = customfieldCmsData?.lbl_add_column_popup_options[foundIndex];
      return item?.category_name;
    }
    return option?.category_name;
  };
  const content = (
    <>
      {options.map((option) => {
        return (
          <React.Fragment key={option.category_id}>
            <div className="innerHeading">
              {getLocalizedCategoryName(option)}
            </div>
            <div className="addColRow">
              {option.details.map((field, _index) => {
                const fieldId = field?.field_type_id || 1;

                return (
                  <div className="addCol" key={field?.field_type_id}>
                    <Tooltip
                      title={field?.default_meta?.description}
                      color={'#2E364C'}
                    >
                      <Button
                        onClick={() => handleOptionClick(field)}
                        icon={getIcon(fieldId)}
                      >
                        {field.field_type_name}
                      </Button>
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
  return (
    <Popover
      content={content}
      title={
        <>
          {customfieldCmsData?.lbl_add_column_tooltip}
          <Button
            type="text"
            shape="circle"
            icon={<CloseOutlined />}
            onClick={handleClose}
            style={{ float: 'right' }}
          />
        </>
      }
      trigger="click"
      open={visible}
      onOpenChange={setVisible}
      overlayClassName="addCustomFieldPopover"
      placement="topLeft"
    >
      <Tooltip
        title={customfieldCmsData?.lbl_add_column_tooltip}
        placement={'right'}
        color={'#2E364C'}
      >
        <span
          className="addPlusSolid"
          onKeyDown={() => {}}
          onClick={() => setVisible(true)}
        ></span>
      </Tooltip>
    </Popover>
  );
};

export default AddCustomField;
