import { FC, useEffect, useState } from 'react';
import {
  IOrganization,
  IProfileSettingCmsData,
} from '../../../models/interface';
import TeamNotification from './TeamManagement/TeamNotification';
import TeamDetailSettings from './TeamManagement/TeamDetailSettings';
import OrganizationMember from './TeamManagement/OrganizationMember';
// import TeamBillings from "./TeamManagement/TeamBillings";// NOSONAR THIS IMPORT WILL BE USED IN FUTURE
import classNames from 'classnames';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Rbac } from '../../../auth/rbac/rbac';
import { ERbacPermissions } from '../../../auth/rbac/rbacPermissionsList';
import TeamProjects from './TeamManagement/TeamProjects';
import TeamOptionalFields from './TeamManagement/Settings/TeamOptionalFields';

const TeamSettings: FC<{
  isActive: boolean;
  lblCms: IProfileSettingCmsData | null;
  selectedOrganization: IOrganization | null;
  teamNameChange: (newName: string) => void;
}> = ({ isActive, lblCms, selectedOrganization, teamNameChange }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [searchParams] = useSearchParams();

  const location = useLocation();

  useEffect(() => {
    switch (searchParams.get('tab')) {
      case 'notification':
        setActiveTab(1);
        break;
      case 'detail':
        setActiveTab(2);
        break;
      case 'team-members':
        setActiveTab(3);
        break;
      case 'projects':
        setActiveTab(4);
        break;
      // case 'billing':
      //   setActiveTab(5);
      //   break;
      case 'settings':
        setActiveTab(6);
        break;
      default:
        setActiveTab(1);
        break;
    }
  }, []);

  return (
    <div
      id="firstTab"
      className="subTabNavigation tabContent"
      style={{ display: isActive ? 'block' : 'none' }}
    >
      <div className="tab-teaser">
        <div className="tab-menu">
          <ul>
            <li>
              <a
                href="true"
                onClick={(evt) => {
                  evt.preventDefault();
                  setActiveTab(1);
                  sessionStorage.setItem(
                    'lastLocation',
                    `${location.pathname}?section=${searchParams.get(
                      'section',
                    )}&tab=notification`,
                  );
                }}
                data-rel="tab-1"
                className={classNames({ active: activeTab === 1 })}
              >
                {lblCms?.lbl_notifications}
              </a>
            </li>
            <Rbac allowedPermissions={[ERbacPermissions.ORGANIZATION_MANAGE]}>
              <li>
                <a
                  href="true"
                  onClick={(evt) => {
                    evt.preventDefault();
                    setActiveTab(2);
                    sessionStorage.setItem(
                      'lastLocation',
                      `${location.pathname}?section=${searchParams.get(
                        'section',
                      )}&tab=detail`,
                    );
                  }}
                  data-rel="tab-3"
                  className={classNames({ active: activeTab === 2 })}
                >
                  {lblCms?.lbl_team}
                </a>
              </li>
            </Rbac>
            <Rbac allowedPermissions={[ERbacPermissions.ORGANIZATION_MANAGE]}>
              <li>
                <a
                  href="true"
                  onClick={(evt) => {
                    evt.preventDefault();
                    setActiveTab(3);
                    sessionStorage.setItem(
                      'lastLocation',
                      `${location.pathname}?section=${searchParams.get(
                        'section',
                      )}&tab=team-members`,
                    );
                  }}
                  data-rel="tab-7"
                  className={classNames({ active: activeTab === 3 })}
                >
                  {lblCms?.lbl_team_members}
                </a>
              </li>
            </Rbac>
            <Rbac allowedPermissions={[ERbacPermissions.ORGANIZATION_MANAGE]}>
              <li>
                <a
                  href="true"
                  onClick={(evt) => {
                    evt.preventDefault();
                    setActiveTab(4);
                    sessionStorage.setItem(
                      'lastLocation',
                      `${location.pathname}?section=${searchParams.get(
                        'section',
                      )}&tab=projects`,
                    );
                  }}
                  data-rel="tab-8"
                  className={classNames({ active: activeTab === 4 })}
                >
                  {lblCms?.lbl_projects}
                </a>
              </li>
            </Rbac>
            {/* <Rbac allowedPermissions={[ERbacPermissions.ORGANIZATION_MANAGE]}>
              <li>
                <a
                  href="true"
                  onClick={(evt) => {
                    evt.preventDefault();
                    setActiveTab(5);
                    sessionStorage.setItem(
                      'lastLocation',
                      `${location.pathname}?section=${searchParams.get(
                        'section',
                      )}&tab=billing`,
                    );
                  }}
                  data-rel="tab-5"
                  className={classNames({ active: activeTab === 5 })}
                >
                  {lblCms?.lbl_billing}
                </a>
              </li>
            </Rbac> */}
            <Rbac allowedPermissions={[ERbacPermissions.ORGANIZATION_MANAGE]}>
              <li>
                <a
                  href="true"
                  onClick={(evt) => {
                    evt.preventDefault();
                    setActiveTab(6);
                    sessionStorage.setItem(
                      'lastLocation',
                      `${location.pathname}?section=${searchParams.get(
                        'section',
                      )}&tab=team-settings`,
                    );
                  }}
                  data-rel="tab-6"
                  className={classNames({ active: activeTab === 6 })}
                >
                  {lblCms?.lbl_team_settings}
                </a>
              </li>
            </Rbac>
          </ul>
        </div>

        <div className="tab-main-box" style={{ maxWidth: '100%' }}>
          <div
            className="tab-box"
            id="tab-1"
            style={{
              display: activeTab === 1 ? 'block' : 'none',
              maxWidth: '710px',
            }}
          >
            <TeamNotification
              lblCms={lblCms}
              organizationInfo={selectedOrganization}
            />
            {/*<div style={{textAlign: "center"}}>{lblCms?.lbl_under_development}</div>*/}
          </div>
          <div
            className="tab-box"
            id="tab-3"
            style={{
              display: activeTab === 2 ? 'block' : 'none',
              maxWidth: '710px',
            }}
          >
            <TeamDetailSettings
              lblCms={lblCms}
              organizationInfo={selectedOrganization}
            />
          </div>
          <div
            className="tab-box"
            id="tab-7"
            style={{
              display: activeTab === 3 ? 'block' : 'none',
              maxWidth: '710px',
            }}
          >
            <OrganizationMember
              lblCms={lblCms}
              organizationInfo={selectedOrganization}
            />
          </div>
          <div
            className="tab-box"
            id="tab-8"
            style={{
              display: activeTab === 4 ? 'block' : 'none',
              maxWidth: '710px',
            }}
          >
            <TeamProjects
              lblCms={lblCms}
              organizationInfo={selectedOrganization}
            />
          </div>
          {/* <div
            className="tab-box"
            id="tab-5"
            style={{ display: activeTab === 5 ? 'block' : 'none' }}
          >
            <TeamBillings/>
            <div style={{ textAlign: 'center' }}>
              {lblCms?.lbl_under_development}
            </div>
          </div> */}
          <div
            className="tab-box"
            id="tab-10"
            style={{ display: activeTab === 6 ? 'block' : 'none' }}
          >
            <TeamOptionalFields pageCms={lblCms} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSettings;
