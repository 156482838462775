import CanvasStructure from './CanvasStructure';
import { useFeedbackStore } from 'stores/feedback.store';
import PointAndCommentRightDrawer from './PointAndCommentRightDrawer';

const FeedbackBoard = () => {
  const { isRightDrawerOpen, setIsRightDrawerOpen, rightDrawerPayload } =
    useFeedbackStore((state: any) => state);

  return (
    <>
      <div className="pncSection">{<CanvasStructure />}</div>
      {isRightDrawerOpen && (
        <PointAndCommentRightDrawer
          show={isRightDrawerOpen}
          setShow={setIsRightDrawerOpen}
          feedbackId={rightDrawerPayload.feedbackId}
          fbDetailsId={rightDrawerPayload.fbDetailsId}
          taskListDetails={rightDrawerPayload?.taskListDetails}
          setTaskListDetails={rightDrawerPayload?.setTaskListDetails}
        />
      )}
    </>
  );
};

export default FeedbackBoard;
