import { FC } from "react";

export const ButtonLoader: FC<{ className?: string }> = ({ className }) => (
    // <div className={`gradientLoader ${className}`}>
    //     <img
    //         src={
    //             "https://storage.googleapis.com/assets-dev-new.cleversort.com/images/gradient-loader.svg"
    //         }
    //         alt=""
    //     />
    // </div>
    <div className="customLoader"></div>
);