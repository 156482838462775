import { FC } from "react"
import cryptoRandomString from "crypto-random-string"
import { IOrganizationAssociatedUserDetails, IProjectCreationAttributes } from "models/interface"
import { useDashboardStore } from "stores"

const RightSideDrawerAddMemberCTA: FC<{
  pageSpecificDataState?: IProjectCreationAttributes | null;
  associatedUser: any[];
  setAssociatedUser: any;
  setUserListState: any;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isFetching: boolean;
}> = ({
  pageSpecificDataState,
  associatedUser,
  setAssociatedUser,
  setUserListState,
  setIsDisabled,
  isFetching,
}) => {
  const { userList } = useDashboardStore((state: any) => state);

  const addNewMemberRow = () => {
    let newList = [...associatedUser];
    let index = newList.findIndex((ele) => ele?.isNew);
    if (index < 0) {
      let addedUerIds = newList.map(
        (ele: IOrganizationAssociatedUserDetails) => ele.user_id,
      );
      let newListV1 = userList.filter(
        (ele: IOrganizationAssociatedUserDetails) =>
          !addedUerIds.includes(ele.user_id),
      );
      setUserListState(newListV1);

      newList.push({
        user_id: cryptoRandomString({ length: 5 }),
        role_id: 0,
        isNew: true,
      });
      setAssociatedUser(newList);
      setIsDisabled(true);
    }
  };

  return (
    <div className=" itemNameBlk addFolder">
      <span
        className="addPlusSolid"
        onClick={() => {
          if (!isFetching) {
            addNewMemberRow();
          }
        }}
        onKeyDown={() => {}}
      />
      <span
        className="itemName"
        onClick={() => {
          if (!isFetching) {
            addNewMemberRow();
          }
        }}
        onKeyDown={() => {}}
      >
        {pageSpecificDataState?.btn_add_member_cta}
      </span>
    </div>
  );
};

export default RightSideDrawerAddMemberCTA