import { Button, Form, Input, Popconfirm, Slider } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import StatusOptionsCreator from '../CustomFieldForms/StatusForm';
import * as R from 'ramda';
import NumberForm from '../../TaskListV2/TableSection/CustomFieldForms/NumberForm';
import { debounce } from 'utils/commonFunctions';
import { IProjectState } from 'models/interface';
import { useProjectDashboardStore } from 'stores';
import DateFormatForm from '../../TaskListV2/TableSection/CustomFieldForms/DateFormatForm';

export interface ColumnEditFormProps {
  customMeta: any;
  updateCustomMeta: (customMeta: any) => void;
  onDelete?: (customMeta: any) => void;
  onDeleteOptions?: (deletedOptions: any) => void;
  onClose?: () => void;
  onChange?: (changedValues: any, allValues: any) => void;
}

const ColumnEditForm: React.FC<ColumnEditFormProps> = ({
  customMeta,
  onDelete,
  updateCustomMeta,
  onDeleteOptions,
  onClose,
  onChange,
  ...rest
}) => {
  const typeId = customMeta?.id;
  const [changeFields, setChangeFields] = useState({});
  const [showDescription, setShowDescription] = useState(
    !!customMeta?.description,
  );

  const isChanged = useMemo(() => {
    return Object.values(changeFields)?.some((value) => !!value);
  }, [changeFields]);

  const { customfieldCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );

  const [form] = Form.useForm();
  const { setFieldValue } = form;
  useEffect(() => {
    if (customMeta) {
      // const customFormat =
      //   customMeta?.number_format?.find((ele: any) => ele?.id === -1)
      //     ?.description ?? '';

      const customFormat =
        customMeta?.selectedFormat === -1
          ? customMeta?.number_format?.description
          : '';

      setFieldValue('columnName', customMeta.name);
      setFieldValue('description', customMeta.description);
      setFieldValue('options', customMeta.options);
      setFieldValue('width', customMeta.width || 150);

      setFieldValue(
        'selectedNegativeNumberFormat',
        customMeta?.selectedNegativeNumberFormat || 1,
      );
      setFieldValue('precision', customMeta?.precision || 0);
      setFieldValue('selectedFormat', customMeta?.selectedFormat || 1);
      setFieldValue('alignment', customMeta?.alignment || 3);
      setFieldValue('direction', customMeta?.direction || 1);
      setFieldValue('customFormat', customFormat);
    }
    return () => {
      onClose?.();
    };
  }, []);

  useEffect(() => {
    if (customMeta.name === 'Date') {
      setFieldValue('showTime', customMeta?.showTime);
      setFieldValue('selectedDateFormat', customMeta?.selectedDateFormat);
    }
    return () => {
      onClose?.();
    };
  }, []);

  const detectChangedFields = (changedValues: any, originalForm: any) => {
    const baseValues = R.clone({ ...(originalForm ?? {}) });
    const keys = Object.keys(changedValues) || [];
    const changedFields: any = {};
    for (let iterator of keys) {
      const newValue = changedValues?.[iterator as string];
      const oldValue = baseValues?.[iterator as string];
      if ((iterator as string) === 'options') {
        // const newOptions = JSON.stringify(
        //   newValue?.map((o: any) => ({
        //     value: o?.name,
        //     color: o?.color,
        //   })),
        // );
        // const oldOptions = JSON.stringify(
        //   oldValue?.map((o: any) => ({ value: o?.value, color: o?.color })),
        // );
        const newOptions = JSON.stringify(
          newValue
            ?.map((o: any) => ({
              value: o?.name,
              color: o?.new_color,
              option_rank: Number(o?.new_option_rank),
            }))
            .sort((a: any, b: any) => a?.option_rank - b?.option_rank),
        );

        const oldOptions = JSON.stringify(
          oldValue
            ?.map((o: any) => ({
              value: o?.value,
              color: o?.color,
              option_rank: Number(o?.option_rank),
            }))
            .sort((a: any, b: any) => a?.option_rank - b?.option_rank),
        );

        changedFields['options'] = newOptions !== oldOptions;
        continue;
      }
      if (![undefined, null]?.includes(newValue)) {
        changedFields[iterator as string] = newValue !== oldValue;
      }
    }
    return changedFields;
  };
  const handleChangeValues = (changedValues: any, allValues: any) => {
    const baseValues = {
      ...customMeta,
      columnName: customMeta?.name,
    };
    const detectedChanges = detectChangedFields(changedValues, baseValues);
    const otherChanges: Record<string, boolean> = {};
    if (typeId === 2) {
      if (customMeta?.selectedFormat === changedValues?.selectedFormat) {
        otherChanges.selectedFormat = false;
      }
    }
    if (changedValues.hasOwnProperty('description')) {
      otherChanges.description =
        (customMeta?.description || '') !== changedValues?.description;
    }
    setChangeFields((prev: any) => ({
      ...prev,
      ...detectedChanges,
      ...otherChanges,
    }));

    onChange?.(changedValues, { ...allValues });
  };

  const debounceUpdate = useCallback(debounce(handleChangeValues, 100), []);
  return (
    <div className="settingPopup" onClick={(e) => e.stopPropagation()}>
      <div className="popupHeading">
        {
          customfieldCmsData?.lbl_column_setting_popup_options
            .lbl_column_setting_header
        }
      </div>
      <Form
        form={form}
        onValuesChange={debounceUpdate}
        labelCol={{ span: 10 }}
        layout="vertical"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        <Form.Item
          label={
            customfieldCmsData?.lbl_column_setting_popup_options.lbl_column_name
          }
          name="columnName"
        >
          <Input maxLength={50} minLength={1} />
        </Form.Item>
        {!showDescription ? (
          <Form.Item>
            <div
              role="button"
              className="addElement"
              onClick={() => setShowDescription(true)}
              onKeyDown={() => {}}
            >
              <span className="addPlusSolid"></span>
              {
                customfieldCmsData?.lbl_column_setting_popup_options
                  .lbl_add_description
              }
            </div>
          </Form.Item>
        ) : null}
        {showDescription ? (
          <Form.Item
            label={
              customfieldCmsData?.lbl_column_setting_popup_options
                .lbl_description
            }
            name="description"
          >
            <Input.TextArea minLength={1} />
          </Form.Item>
        ) : null}
        <Form.Item
          label={customfieldCmsData?.lbl_column_setting_popup_options.lbl_width}
          name="width"
        >
          <Slider max={500} min={50} />
        </Form.Item>
        {(typeId === 1 || typeId === 4 || typeId === 5) && (
          <Form.Item name="options">
            <StatusOptionsCreator
              onChange={(updatedOptions: any) => {
                setFieldValue('options', updatedOptions);
              }}
              onDeleteOptions={(deletedOptions: any) => {
                onDeleteOptions?.(deletedOptions);
              }}
              options={customMeta?.options || []}
            />
          </Form.Item>
        )}
        {typeId === 2 && (
          <NumberForm
            form={form}
            {...customMeta}
            setFieldValue={setFieldValue}
            // getFieldValue={getFieldValue}
            setChangeFields={setChangeFields}
            editForm={customMeta}
          />
        )}
        {typeId === 7 && (
          <DateFormatForm
            form={form}
            {...customMeta}
            setFieldValue={setFieldValue}
            setChangeFields={setChangeFields}
            editForm={customMeta}
          />
        )}

        <div className="btnWrap">
          <Popconfirm
            title={
              customfieldCmsData?.lbl_column_setting_popup_options
                .lbl_delete_confirm_msg
            }
            onConfirm={onDelete}
            okButtonProps={{
              style: { backgroundColor: '#ba1a1a' },
            }}
            okText={
              customfieldCmsData?.lbl_column_setting_popup_options
                .lbl_delete_ok_text
            }
            cancelText={
              customfieldCmsData?.lbl_column_setting_popup_options
                .lbl_delete_cancel_text
            }
          >
            <Button className="iconBtn">
              <span className="cmnIcon deleteBin"></span>
              {
                customfieldCmsData?.lbl_column_setting_popup_options
                  .lbl_delete_cta
              }
            </Button>
          </Popconfirm>
          <Button
            disabled={!isChanged}
            onClick={() => {
              if (!isChanged) return;
              const allValues = form.getFieldsValue();
              const newName = allValues?.columnName?.trim();

              const updatedMeta = {
                ...customMeta,
                ...allValues,
                name: !newName ? customMeta.name : newName,
                description: allValues.description,
                options: allValues?.options,
                width: allValues.width,
              };

              if (updatedMeta?.customFormat) delete updatedMeta.customFormat;
              if (updatedMeta?.tempWidth) delete updatedMeta.tempWidth;
              // const findCustom = customMeta?.number_format?.findIndex(
              //   (ele: any) => ele?.id === -1,
              // );
              // if (!allValues?.customFormat || findCustom > 0) {
              //   updatedMeta.number_format = customMeta?.number_format?.map(
              //     (ele: any) =>
              //       ele?.id === -1
              //         ? {
              //             ...ele,
              //             description: allValues.customFormat,
              //           }
              //         : ele,
              //   );
              // } else {
              //   updatedMeta.number_format = [
              //     ...(customMeta?.number_format ?? []),
              //     {
              //       id: -1,
              //       description: allValues.customFormat,
              //     },
              //   ];
              // }
              updatedMeta.number_format = {
                id: -1,
                description: allValues.customFormat,
              };
              updateCustomMeta(updatedMeta);
            }}
            type="primary"
            className="btnStyle1"
          >
            {customfieldCmsData?.lbl_column_setting_popup_options.lbl_save_cta}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ColumnEditForm;
