import { FC } from "react"
import { UseFormRegister } from "react-hook-form"
import { IProjectCreationAttributes, IProjectSubmitData } from "models/interface"
import { useDashboardStore } from "stores"
import { modeOps } from "models/enums/constants"

const RightSideDrawerFolderListDropDown: FC<{
  pageSpecificDataState?: IProjectCreationAttributes | null;
  register: UseFormRegister<IProjectSubmitData>;
  mode: modeOps;
  isFetching?: boolean;
}> = ({ pageSpecificDataState, register, mode, isFetching }) => {
  const { folderList } = useDashboardStore((state: any) => state);

  const cmsModeMapping: Record<modeOps, string | undefined> = {
    [modeOps.ADD]: pageSpecificDataState?.lbl_folder_name,
    [modeOps.CLONE]: pageSpecificDataState?.txt_folder_label_clone,
    [modeOps.EDIT]: pageSpecificDataState?.lbl_folder_name,
  };

  return (
    <div className="inputHolder">
      <label htmlFor="category">{cmsModeMapping[mode]}</label>
      <select
        id="category"
        {...register('folderId', { required: false })}
        disabled={isFetching}
      >
        {folderList &&
          [
            {
              folder_id: 0,
              folder_name:
                pageSpecificDataState?.lbl_select_folder_default_option,
              folder_rank: 0,
            },
            ...folderList,
          ].map(
            (ele, index) =>
              !ele?.is_new && (
                <option key={ele.folder_id} value={ele.folder_id}>
                  {ele.folder_name}
                </option>
              ),
          )}
      </select>
    </div>
  );
};

export default RightSideDrawerFolderListDropDown