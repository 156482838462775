import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  EffortFieldType,
  IGridList,
  IGridListBlockDetail,
  IProjectState,
} from 'models/interface';
import { projectService } from 'services';
import { useProjectDashboardStore, useUserStore } from 'stores';
import { getCurrentOrganization, groupGridBlock } from 'utils/commonFunctions';
import BlockWithAssigneeOnlyWrapper from './BlockWithAssigneeOnly/BlockWithAssigneeOnlyWrapper';
import BlockWithCompleteDetailsWrapper from './BlockWithCompleteDetails/BlockWithCompleteDetailsWrapper';
import { SectionTypeMessages } from 'models/enums/messages';
import { markTaskDoneInMessageThread } from 'utils/firebaseCommonFunctions';
import { checkIsCustomField } from '../../services/grid.handler';
import CustomNumberField from '../../CustomFieldComponents/CustomNumberField';
import CustomDropdown from '../../CustomFieldComponents/CustomDropdown';
import MultiSelectDropdown from '../../../TaskListV2/TableSection/CustomFieldCells/MultiSelectDropdown';
import GridCustomTextField from '../../CustomFieldComponents/GridCustomTextField';
import DateTimeField from '../../../TaskListV2/TableSection/CustomCells/DateTimeField';
import EffortsField from '../../../TaskListV2/TableSection/CustomCells/EffortsField/EffortsField';

const Block: FC<{
  gridListDetails: IGridList;
  taskIndex: number;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  openMessageExpandedView?: boolean;
  setOpenMessageExpandedView?: React.Dispatch<React.SetStateAction<boolean>>;
  expandedViewForMessageIndex?: number | undefined;
  setExpandedViewForMessageIndex?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  onCustomFieldCellChange: (
    value: string | EffortFieldType | any,
    cell: any,
  ) => void;
}> = ({
  gridListDetails,
  taskIndex,
  setGridListDetails,
  openMessageExpandedView,
  setOpenMessageExpandedView,
  expandedViewForMessageIndex,
  setExpandedViewForMessageIndex,
  onCustomFieldCellChange,
}) => {
  const { projectDetails, masterDataOptionalFields } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const { userDetails } = useUserStore((state: any) => state);
  const [showDropDown, setShowDropDown] = useState<number>(0);
  const [showCalender, setShowCalender] = useState<number>(0);
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const editingStage = gridListDetails.grid_list_details?.stage_details
    ?.filter((i) => i.isEdit)
    ?.map((i) => i?.stage_id);

  const updateBlock = async (block: IGridListBlockDetail) => {
    try {
      const response: any = await projectService.updateGridBlock({
        organizationId: getCurrentOrganization(
          org_key,
          userDetails?.organization_info,
        )?.organization_id!,
        userId: userDetails?.user_id,
        projectId: projectDetails?.project_id,
        sectionId: gridListDetails!.section_id,
        blockId: block.block_id,
        assignee: block.assignee_id,
        assigner: block.assigner_id,
        dueDate: block.due_date,
        blockStatus: block.block_status_id,
      });

      if (response.messageId !== 1) {
        revertChange(block.block_id);
      } else if (block.block_status_id === 3) {
        markTaskDoneInMessageThread(
          `${SectionTypeMessages.GRID_LIST_BLOCK}-${
            process.env.REACT_APP_STAGE
          }-${getCurrentOrganization(org_key, userDetails?.organization_info)
            ?.organization_id!}-${projectDetails?.project_id}-${
            gridListDetails.section_id
          }-${block.block_id}`,
        );
      }
    } catch (err) {
      revertChange(block.block_id);
    }
  };

  const revertChange = (blockId: number) => {
    if (gridListDetails) {
      let blockDetails =
        gridListDetails.grid_list_details?.block_details!.flat()!;
      let target = blockDetails.find(
        (ele: IGridListBlockDetail) => ele.block_id === blockId,
      )!;
      let targetIndex = blockDetails.findIndex(
        (ele: IGridListBlockDetail) => ele.block_id === blockId,
      )!;

      target.assignee_email = null;
      target.assignee_id = null;
      target.assignee_name = null;
      target.assignee_profile_picture = null;

      blockDetails.splice(targetIndex, 1, target);
      let groupedBlockData = groupGridBlock(
        blockDetails,
        gridListDetails.grid_list_details!.task_details!,
      );

      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          block_details: groupedBlockData as Array<IGridListBlockDetail[]>,
        },
      } as IGridList);
    }
  };

  const getClassNameByFilter = (
    gridList: IGridList,
    blockDetails: IGridListBlockDetail,
  ) => {
    if (
      gridList.show_own_tasks &&
      blockDetails.assignee_id !== userDetails?.user_id
    ) {
      return true;
    }

    if (gridList.hide_completed_tasks && blockDetails.block_status_id === 3) {
      return true;
    }

    if (
      gridList.show_own_tasks === true &&
      gridList.hide_completed_tasks === true &&
      blockDetails.assignee_id !== userDetails?.user_id &&
      blockDetails.block_status_id === 3
    ) {
      return true;
    }

    return false;
  };

  const renderNonCustomField = (
    ele: IGridListBlockDetail,
    gridListDetails: any,
  ) => {
    return (
      <li
        className={`${
          editingStage?.includes(ele.stage_id) && gridListDetails?.compactView
            ? 'blkEditActivated'
            : ''
        } ${
          getClassNameByFilter(gridListDetails, ele)
            ? 'nonHighlightedTaskCard'
            : ''
        }`}
        key={ele.block_id}
      >
        {ele.assignee_id === null && ele.due_date === null ? (
          <BlockWithAssigneeOnlyWrapper
            gridListDetails={gridListDetails}
            setGridListDetails={setGridListDetails}
            blockDetails={ele}
            showDropDown={showDropDown}
            setShowDropDown={setShowDropDown}
            showCalender={showCalender}
            setShowCalender={setShowCalender}
            updateBlock={async (block: IGridListBlockDetail) =>
              await updateBlock(block)
            }
          />
        ) : (
          <BlockWithCompleteDetailsWrapper
            gridListDetails={gridListDetails}
            setGridListDetails={setGridListDetails}
            blockDetails={ele}
            showDropDown={showDropDown}
            setShowDropDown={setShowDropDown}
            showCalender={showCalender}
            setShowCalender={setShowCalender}
            updateBlock={async (block: IGridListBlockDetail) =>
              await updateBlock(block)
            }
            index={taskIndex}
          />
        )}
      </li>
    );
  };

  return (
    <td>
      <ul>
        {gridListDetails?.grid_list_details?.block_details?.map(
          (eleOuter: any, index: number) => {
            if (taskIndex === index)
              return (
                <>
                  {eleOuter?.map((ele: IGridListBlockDetail) => {
                    if (checkIsCustomField(ele.stage_id)) {
                      const customMeta = gridListDetails.custom_meta?.find(
                        (c) => c?.mapping_id === ele?.stage_id,
                      );
                      const options = (customMeta?.options || [])
                        .filter((i: any) => i.value?.trim())
                        .sort(
                          (a: any, b: any) => a.option_rank - b.option_rank,
                        );
                      if (customMeta?.id === 2) {
                        return (
                          <li
                            key={ele.block_id}
                            style={{
                              width: customMeta?.tempWidth ?? customMeta?.width,
                            }}
                          >
                            <CustomNumberField
                              taskDetail={ele}
                              disabled={projectDetails?.is_archived}
                              value={
                                ele?.custom_field_block_value?.value as number
                              }
                              configuration={customMeta}
                              onChange={(e) => {
                                //  onCustomFieldCellChange(e, ele)
                                onCustomFieldCellChange(
                                  {
                                    forEffortField: false,
                                    customData: {
                                      mapping_id: ele?.stage_id,
                                      value: e,
                                    },
                                  },
                                  ele,
                                );
                              }}
                            />
                          </li>
                        );
                      }
                      if (customMeta?.id === 5) {
                        let blockValue = ele?.custom_field_block_value ?? [];
                        const options = customMeta.options
                          .filter((i: any) => i.value?.trim())
                          .sort(
                            (a: any, b: any) => a.option_rank - b.option_rank,
                          );
                        const allValues = options.filter((i: any) =>
                          blockValue?.option_id?.includes(i.option_id),
                        );
                        return (
                          <li
                            key={ele.block_id}
                            style={{
                              width: customMeta?.tempWidth ?? customMeta?.width,
                            }}
                          >
                            <MultiSelectDropdown
                              options={options
                                ?.map((item: any) => ({
                                  ...item,
                                  label: item.value,
                                  key: item.option_id,
                                  value: item.option_id,
                                }))
                                .sort(
                                  (a: any, b: any) =>
                                    a.option_rank - b.option_rank,
                                )}
                              values={allValues}
                              onSelect={(e) => {}}
                              // onChange={(e) => onCustomFieldCellChange(e, ele)}
                              onChange={(e) =>
                                onCustomFieldCellChange(
                                  {
                                    forEffortField: false,
                                    customData: {
                                      mapping_id: ele?.stage_id,
                                      option_id: e?.map(
                                        (option: any) => option?.option_id,
                                      ),
                                    },
                                  },
                                  ele,
                                )
                              }
                              disabled={projectDetails?.is_archived}
                            />
                          </li>
                        );
                      }
                      if (customMeta?.id === 6) {
                        return (
                          <li
                            key={ele.block_id}
                            style={{
                              width: customMeta?.tempWidth ?? customMeta?.width,
                              textAlign: 'center',
                            }}
                          >
                            <GridCustomTextField
                              // taskDetail={ele}
                              disabled={projectDetails?.is_archived}
                              value={
                                ele?.custom_field_block_value?.value as string
                              }
                              // configuration={customMeta}
                              // onChange={(e) => onCustomFieldCellChange(e, ele)}
                              onChange={(e) =>
                                onCustomFieldCellChange(
                                  {
                                    forEffortField: false,
                                    customData: {
                                      mapping_id: ele?.stage_id,
                                      value: e,
                                    },
                                  },
                                  ele,
                                )
                              }
                            />
                          </li>
                        );
                      }
                      if (customMeta?.id === 7) {
                        let blockValue =
                          ele?.custom_field_block_value ?? undefined;
                        return (
                          <li
                            key={ele.block_id}
                            style={{
                              width: customMeta?.tempWidth ?? customMeta?.width,
                              textAlign: 'center',
                            }}
                          >
                            <DateTimeField
                              onSave={(e: any) =>
                                // onCustomFieldCellChange(e, ele)
                                onCustomFieldCellChange(
                                  {
                                    forEffortField: false,
                                    customData: {
                                      mapping_id: ele?.stage_id,
                                      value: e,
                                    },
                                  },
                                  ele,
                                )
                              }
                              dateTimeValue={blockValue?.value}
                              config={customMeta}
                            />
                          </li>
                        );
                      }
                      if (customMeta?.id === 8) {
                        if (!('total_duration_in_minutes' in ele)) {
                          ele.total_duration_in_minutes = 0;
                        }
                        return (
                          <li
                            key={ele.block_id}
                            style={{
                              width: customMeta?.tempWidth ?? customMeta?.width,
                              textAlign: 'center',
                            }}
                          >
                            <EffortsField
                              type="gridList"
                              taskDetail={ele}
                              disabled={projectDetails?.is_archived}
                              value={ele?.custom_field_block_value?.value ?? []}
                              gridListDetails={gridListDetails}
                              onSave={(efforts: any, effortLogged: any) => {
                                onCustomFieldCellChange(
                                  {
                                    forEffortField: true,
                                    ...effortLogged,
                                    customData: {
                                      mapping_id: ele?.stage_id,
                                      ...efforts,
                                    },
                                  },
                                  ele,
                                );
                              }}
                            />
                          </li>
                        );
                      }

                      return (
                        <li
                          key={ele.block_id}
                          style={{
                            width: customMeta?.tempWidth ?? customMeta?.width,
                          }}
                        >
                          <CustomDropdown
                            options={options}
                            value={ele?.custom_field_block_value ?? ''}
                            // onSelect={(e) => {}}
                            onChange={(e) => {
                              // onCustomFieldCellChange(e, ele)
                              onCustomFieldCellChange(
                                {
                                  forEffortField: false,
                                  customData: {
                                    mapping_id: ele?.stage_id,
                                    option_id: e?.option_id,
                                  },
                                },
                                ele,
                              );
                            }}
                            disabled={projectDetails?.is_archived}
                          />
                        </li>
                      );
                    } else {
                      return renderNonCustomField(ele, gridListDetails);
                    }
                  })}
                </>
              );
          },
        )}
      </ul>
    </td>
  );
};

export default Block;
