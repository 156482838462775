import { FC, useState } from "react";
import {
  IGridListBlockDetail,
  IGridList,
  IProjectState,
} from "models/interface";
import BlockDueDate from "./BlockDueDate";
import { getStatusImage } from "../BlockWithCompleteDetails/BlockStatus";
import { useProjectDashboardStore } from "stores";
import ActionDropdown from "../../ActionDropdown";
import { ContextTypes } from "models/enums/constants";
import SharedAssigneeDropDown from "components/sharedComponents/SharedAssigneeDropDown/SharedAssigneeDropDown";
import { getBlockPosition } from "../BlockWithCompleteDetails/BlockWithCompleteDetailsWrapper";

const BLOCK_STATUS = [
  {
    id: 1,
    type: "empty",
    className: "gridNewBlk",
  },
  {
    id: 2,
    type: "overdue",
    className: "gridOverdue",
  },
  {
    id: 3,
    type: "done",
    className: "gridDone",
  },
  {
    id: 4,
    type: "blocked",
    className: "gridBlocked",
  },
];

const getBlockStatus = (id: number) => {
  return BLOCK_STATUS.find((i) => i.id === id);
};

export const getClassName = (statusId: number) => getBlockStatus(statusId);

const BlockWithAssigneeOnlyWrapper: FC<{
  blockDetails: IGridListBlockDetail;
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  updateBlock: (block: IGridListBlockDetail) => Promise<void>;
  showDropDown: number;
  setShowDropDown: React.Dispatch<React.SetStateAction<number>>;
  showCalender: number;
  setShowCalender: React.Dispatch<React.SetStateAction<number>>;
}> = ({
  gridListDetails,
  setGridListDetails,
  updateBlock,
  blockDetails,
  showDropDown,
  setShowDropDown,
  showCalender,
  setShowCalender,
}) => {
  const { packList } = useProjectDashboardStore(
    (state: IProjectState) => state
  );

  const [actionDropdown, setActionDropdown] = useState<boolean> (false);

  const getDropdownState = (param1:boolean) => {
    setActionDropdown(param1)
  }

  const determineClassname = () => {
    if (actionDropdown === true){
      return `gridBlk ${getClassName(blockDetails.block_status_id)?.className} activeGridBlk`
    }
    return `gridBlk ${getClassName(blockDetails.block_status_id)?.className}`
  }
  

  return (
    <div
      className={determineClassname()}
      id={getBlockPosition(gridListDetails, blockDetails)}
    >
      {!gridListDetails?.compactView ? (
        <>
          <BlockDueDate
            blockId={blockDetails.block_id}
            showCalender={showCalender}
            setShowCalender={setShowCalender}
            dueDate={blockDetails.due_date}
            gridListDetails={gridListDetails}
            setGridListDetails={setGridListDetails}
            updateBlock={updateBlock}
          />

          {/* <BlockAssignee
            blockId={blockDetails.block_id}
            gridListDetails={gridListDetails}
            setGridListDetails={setGridListDetails}
            assigneeDetails={{
              assignee_id: blockDetails.assignee_id,
              assignee_name: blockDetails.assignee_name,
              assignee_email: blockDetails.assignee_email,
              assignee_profile_picture: blockDetails.assignee_profile_picture,
            }}
            updateBlock={updateBlock}
            showDropDown={showDropDown}
            setShowDropDown={setShowDropDown}
          /> */}
          <SharedAssigneeDropDown
            blockId={blockDetails.block_id}
            gridListDetails={gridListDetails}
            setGridListDetails={setGridListDetails}
            assigneeDetails={{
              assignee_id: blockDetails.assignee_id,
              assignee_name: blockDetails.assignee_name,
              assignee_email: blockDetails.assignee_email,
              assignee_profile_picture: blockDetails.assignee_profile_picture,
            }}
            updateBlock={updateBlock}
            showDropDown={showDropDown}
            setShowDropDown={setShowDropDown}
            contextType={ContextTypes.GRIDLIST}
          />
        </>
      ) : (
        <ActionDropdown
          blockId={blockDetails.block_id}
          gridListDetails={gridListDetails}
          setGridListDetails={setGridListDetails}
          updateBlock={updateBlock}
          blockDetails={blockDetails}
          getDropdownState = {getDropdownState}
        />
      )}

      {gridListDetails.view_id == 4 ? (
        <div
          style={{
            backgroundImage:
              "url(" +
              `${process.env.REACT_APP_STORAGE_BASE_URL}${
                getStatusImage(
                  blockDetails.block_status_id,
                  gridListDetails!.pack_id,
                  packList
                ).status_value
              }` +
              ")",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          className="blkStatus"
        >
          Status
        </div>
      ) : (
        <div className="blkStatus">Status</div>
      )}
    </div>
  );
};

export default BlockWithAssigneeOnlyWrapper;
