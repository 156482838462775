import { Button, Form, Input, Popconfirm, Slider } from 'antd';
import * as R from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NumberForm from '../CustomFieldForms/NumberForm';
import StatusOptionsCreator from '../CustomFieldForms/StatusForm';
import { debounce } from 'utils/commonFunctions';
import { useProjectDashboardStore } from 'stores';
import { IProjectState } from 'models/interface';
import DateFormatForm from '../CustomFieldForms/DateFormatForm';

export interface ColumnEditFormProps {
  label?: string;
  description?: string;
  type?: string;
  defaultValue?: any;
  editForm?: any;
  id?: number | string;
  onDelete?: () => void;
  onSave?: (form: any) => void;
  onChange?: (changedValues: any, allValues: any) => void;
  onSaveSetting?: (width: number) => void;
  onDeleteOptions?: (deletedOptions: any) => void;
  onClose?: () => void;
}

const ColumnEditForm: React.FC<ColumnEditFormProps> = ({
  defaultValue,
  type,
  editForm,
  onDelete = () => {},
  onSave = () => {},
  onChange,
  onSaveSetting,
  onDeleteOptions,
  onClose,
  ...rest
}) => {
  const typeId = rest?.id;

  // State
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showDescription, setShowDescription] = useState(
    !!editForm?.description,
  );
  const [changeFields, setChangeFields] = useState({});

  const { customfieldCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const isChanged = useMemo(() => {
    return Object.values(changeFields)?.some((value) => !!value);
  }, [changeFields]);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const onConfirm = () => {
    setConfirmLoading(true);
    onDelete && debounce(onDelete, 300)();
  };

  const [form] = Form.useForm();
  const { setFieldValue, getFieldValue } = form;

  useEffect(() => {
    if (defaultValue) {
      // const customFormat =
      //   editForm?.number_format?.find((ele: any) => ele?.id === -1)
      //     ?.description ?? '';

      const customFormat =
        editForm?.selectedFormat === -1
          ? editForm?.number_format?.description
          : '';
      form.setFieldsValue({
        ...defaultValue,
        description: editForm?.description,
        selectedNegativeNumberFormat:
          editForm?.selectedNegativeNumberFormat || 1,
        precision: editForm?.precision || 0,
        selectedFormat: editForm?.selectedFormat || 1,
        direction: editForm?.direction || 1,
        alignment: editForm?.alignment || 3,
        customFormat: customFormat,
      });
    }
    if (editForm?.options) setFieldValue('options', [...editForm?.options]);

    return () => {
      onClose?.();
    };
  }, []);

  useEffect(() => {
    if (defaultValue.columnName === 'Date') {
      form.setFieldsValue({
        ...defaultValue,
        description: editForm?.description,
        showTime: editForm?.showTime,
        selectedDateFormat: editForm?.selectedDateFormat,
      });
    }
    return () => {
      onClose?.();
    };
  }, []);

  const detectChangedFields = (changedValues: any, originalForm: any) => {
    const baseValues = R.clone({ ...(originalForm ?? {}) });
    const keys = Object.keys(changedValues) || [];
    const changedFields: any = {};
    for (let iterator of keys) {
      const newValue = changedValues?.[iterator as string];
      const oldValue = baseValues?.[iterator as string];
      if ((iterator as string) === 'options') {
        const newOptions = JSON.stringify(
          newValue
            ?.map((o: any) => ({
              value: o?.name,
              color: o?.new_color,
              option_rank: Number(o?.new_option_rank),
            }))
            .sort((a: any, b: any) => a?.option_rank - b?.option_rank),
        );
        const oldOptions = JSON.stringify(
          oldValue
            ?.map((o: any) => ({
              value: o?.value,
              color: o?.color,
              option_rank: Number(o?.option_rank),
            }))
            .sort((a: any, b: any) => a?.option_rank - b?.option_rank),
        );

        changedFields['options'] = newOptions !== oldOptions;
        continue;
      }
      if (![undefined, null]?.includes(newValue)) {
        changedFields[iterator as string] = newValue !== oldValue;
      }
    }
    return changedFields;
  };

  const handleChangeValues = (changedValues: any, allValues: any) => {
    // const customFormat =
    //   editForm?.number_format?.find((ele: any) => ele?.id === -1)
    //     ?.description ?? '';
    const customFormat =
      editForm?.selectedFormat === -1
        ? editForm?.number_format?.description
        : '';
    const baseValues = {
      ...defaultValue,
      ...editForm,
      customFormat,
    };
    const detectedChanges = detectChangedFields(changedValues, baseValues);
    const otherChanges: Record<string, boolean> = {};
    if (typeId === 2) {
      if (editForm?.selectedFormat === changedValues?.selectedFormat) {
        otherChanges.selectedFormat = false;
      }
    }
    if (changedValues.hasOwnProperty('description')) {
      otherChanges.description =
        (editForm?.description || '') !== changedValues?.description;
    }
    setChangeFields((prev: any) => ({
      ...prev,
      ...detectedChanges,
      ...otherChanges,
    }));
    onChange?.(changedValues, { ...allValues });
  };

  const debounceUpdate = useCallback(debounce(handleChangeValues, 100), []);

  return (
    <div className="settingPopup" onClick={(e) => e.stopPropagation()}>
      <div className="popupHeading">
        {
          customfieldCmsData?.lbl_column_setting_popup_options
            .lbl_column_setting_header
        }
      </div>
      <Form
        form={form}
        onValuesChange={debounceUpdate}
        labelCol={{ span: 10 }}
        layout="vertical"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        <Form.Item
          label={
            customfieldCmsData?.lbl_column_setting_popup_options.lbl_column_name
          }
          name="columnName"
        >
          <Input maxLength={50} minLength={1} />
        </Form.Item>
        {!showDescription ? (
          <Form.Item>
            <div
              role="button"
              className="addElement"
              onClick={() => setShowDescription(true)}
            >
              <span className="addPlusSolid"></span>
              {
                customfieldCmsData?.lbl_column_setting_popup_options
                  .lbl_add_description
              }
            </div>
          </Form.Item>
        ) : null}
        {showDescription ? (
          <Form.Item
            label={
              customfieldCmsData?.lbl_column_setting_popup_options
                .lbl_description
            }
            name="description"
          >
            <Input.TextArea minLength={1} />
          </Form.Item>
        ) : null}
        <Form.Item
          label={customfieldCmsData?.lbl_column_setting_popup_options.lbl_width}
          name="width"
        >
          <Slider max={500} min={50} />
        </Form.Item>
        {(typeId === 1 || typeId === 4 || typeId === 5) && (
          <Form.Item name="options">
            <StatusOptionsCreator
              onChange={(updatedOptions: any) => {
                // console.log("voila", updatedOptions);
                // setFieldValue(
                //   "options",
                //   updatedOptions?.map((option: any) => ({
                //     value: option.name,
                //     color: option.color,
                //   }))
                // );
                // TODO: Add onchange handler logic here.
              }}
              onDeleteOptions={(deletedOptions: any) => {
                onDeleteOptions?.(deletedOptions);
              }}
              options={editForm.options}
            />
          </Form.Item>
        )}
        {typeId === 2 && (
          <NumberForm
            form={form}
            {...rest}
            setFieldValue={setFieldValue}
            getFieldValue={getFieldValue}
            setChangeFields={setChangeFields}
            editForm={editForm}
          />
        )}
        {typeId === 7 && (
          <DateFormatForm
            form={form}
            {...rest}
            setFieldValue={setFieldValue}
            getFieldValue={getFieldValue}
            setChangeFields={setChangeFields}
            editForm={editForm}
          />
        )}
        {/* <Form.Item name="addToQuickFilter" valuePropName="checked">
          <Checkbox>Add to Quick Filters</Checkbox>
        </Form.Item> */}
        <div className="btnWrap">
          <Popconfirm
            title={
              customfieldCmsData?.lbl_column_setting_popup_options
                .lbl_delete_confirm_msg
            }
            onConfirm={onConfirm}
            onCancel={() => {
              setOpen(false);
            }}
            open={open}
            okButtonProps={{
              loading: confirmLoading,
              style: { backgroundColor: '#ba1a1a' },
              className: 'deleteFld',
            }}
            okText={
              customfieldCmsData?.lbl_column_setting_popup_options
                .lbl_delete_ok_text
            }
            cancelText={
              customfieldCmsData?.lbl_column_setting_popup_options
                .lbl_delete_cancel_text
            }
          >
            <Button onClick={showPopconfirm} className="iconBtn">
              <span className="cmnIcon deleteBin"></span>
              {
                customfieldCmsData?.lbl_column_setting_popup_options
                  .lbl_delete_cta
              }
            </Button>
          </Popconfirm>
          <Button
            disabled={!isChanged}
            onClick={() => {
              if (!isChanged) return;

              const allValues = form.getFieldsValue();
              // const findCustom = editForm?.number_format?.findIndex(
              //   (ele: any) => ele?.id === -1,
              // );
              // if (!allValues?.customFormat || findCustom > 0) {
              //   allValues.number_format = editForm?.number_format?.map(
              //     (ele: any) =>
              //       ele?.id === -1
              //         ? {
              //             ...ele,
              //             description: allValues.customFormat,
              //           }
              //         : ele,
              //   );
              // } else {
              //   allValues.number_format = [
              //     {
              //       id: -1,
              //       description: allValues.customFormat,
              //     },
              //   ];
              // }
              allValues.number_format = {
                id: -1,
                description: allValues.customFormat,
              };

              onSave?.({
                ...allValues,
                columnName: `${allValues.columnName} `.trim(),
              });
              onSaveSetting?.(allValues.width);
            }}
            type="primary"
            className="btnStyle1"
          >
            {customfieldCmsData?.lbl_column_setting_popup_options.lbl_save_cta}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ColumnEditForm;
