import { Tooltip } from 'antd';
import { TaskListView } from 'models/enums/tasklistView';
import { IProjectState } from 'models/interface';
import React, { Fragment } from 'react';
import { useProjectDashboardStore, useUserStore } from 'stores';
import { getUserGravatar } from 'utils/commonFunctions';

interface UserNameTagProps {
  type: string;
  eachTaskDetail: any;
  sectionId?: string | number;
  setEachTaskDetail?: (detail: any) => void;
  viewType: TaskListView;
  contextType: string;
  setTaskListDetails?: (details: any) => void;
  assignedBy?: number | string;
  lastUpdatedBy?: number;
  isAssignerOrLastUpdateBy: boolean;
}

const UserNameTag: React.FC<UserNameTagProps> = ({
  type,
  eachTaskDetail,
  lastUpdatedBy,
  sectionId,
  setEachTaskDetail,
  viewType,
  contextType,
  setTaskListDetails,
  assignedBy,
  isAssignerOrLastUpdateBy,
}) => {
  const { taskListCmsData, projectMembers } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );

  const { userDetails } = useUserStore((state: any) => state);

  const getAssignerNameAvatarJsx = (id: number) => {
    if (eachTaskDetail?.assignee_inactive) {
      return null;
    }
    let assignerDetails = projectMembers?.find(
      (ele: any) => ele?.user_id === Number(id),
    );
    return assignerDetails?.profile_picture ? (
      <img alt="user avatar" src={assignerDetails?.profile_picture}></img>
    ) : (
      getUserGravatar(assignerDetails?.full_name ?? assignerDetails?.email!)
    );
  };

  const getAssignerName = (id: number, type: string) => {
    let assignerDetails = projectMembers?.find(
      (ele: any) => ele?.user_id === id,
    );
    let userId = type === 'assigner_id' ? assignedBy : lastUpdatedBy;
    if (userId === userDetails?.user_id) {
      return (
        assignerDetails?.full_name +
        `\u0020(${taskListCmsData?.task_assignee_custom_text[1]})`
      );
    } else if (assignerDetails?.full_name) {
      return assignerDetails?.full_name;
    } else {
      return taskListCmsData?.task_assignee_custom_text[0];
    }
  };

  const getPopoverAssignerNameClassName = () => {
    if (!eachTaskDetail?.assigner_id) {
      return 'userAvtrBlankContent';
    }
    const assignerDetails = projectMembers?.find(
      (ele: any) => ele.user_id === eachTaskDetail?.assigner_id,
    );
    return assignerDetails?.full_name ? 'userName' : '';
  };

  const getPopoverAssignerAvatarClassName = () => {
    if (!eachTaskDetail?.assigner_id) {
      return 'userAvtrBlank';
    }
    const assignerDetails = projectMembers?.find(
      (ele: any) => ele.user_id === eachTaskDetail?.assigner_id,
    );
    return assignerDetails?.profile_picture ? 'userAvtrImg' : '';
  };

  const getPopoverLastUserNameClassName = () => {
    if (!eachTaskDetail?.last_updated_by) {
      return 'userAvtrBlankContent';
    }
    const assignerDetails = projectMembers?.find(
      (ele: any) => ele.user_id === Number(eachTaskDetail?.last_updated_by),
    );
    return assignerDetails?.full_name ? 'userName' : '';
  };

  const getPopoverLastUserAvatarClassName = () => {
    if (!eachTaskDetail?.last_updated_by) {
      return 'userAvtrBlank';
    }
    const assignerDetails = projectMembers?.find(
      (ele: any) => ele.user_id === Number(eachTaskDetail?.last_updated_by),
    );
    return assignerDetails?.profile_picture ? 'userAvtrImg' : '';
  };

  const contentForTaskList = () => {
    if (type === 'assigner_id') {
      if (!eachTaskDetail.assigner_id) {
        return (
          <div className="unassignUserBlkWrap userBlkWrap">
            <div className="userName textMuted cursorDisabled">
              {taskListCmsData?.due_date_custom_text[0]}
            </div>
          </div>
        );
      } else if (eachTaskDetail.assigner_id) {
        return (
          <Tooltip
            title={getAssignerName(eachTaskDetail.assigner_id, 'assigner_id')}
            placement="bottom"
            color={'#2E364C'}
          >
            <div>
              <div className="userBlkWrap">
                <div
                  className={`userAvtr ${getPopoverAssignerAvatarClassName()} cursorDisabled`}
                >
                  {getAssignerNameAvatarJsx(eachTaskDetail.assigner_id)}
                </div>
                <div
                  className={`${getPopoverAssignerNameClassName()} cursorDisabled`}
                >
                  {getAssignerName(eachTaskDetail.assigner_id, 'assigner_id')}
                </div>
              </div>
            </div>
          </Tooltip>
        );
      }
    } else if (type === 'last_updated_by') {
      let last_updated_by = lastUpdatedBy;
      if (!last_updated_by) {
        return (
          <div className="unassignUserBlkWrap userBlkWrap ">
            <div className="userName textMuted cursorDisabled">
              {taskListCmsData?.due_date_custom_text[0]}
            </div>
          </div>
        );
      } else if (last_updated_by) {
        return (
          <Tooltip
            title={getAssignerName(last_updated_by, 'last_updated_by')}
            placement="bottom"
            color={'#2E364C'}
          >
            <div>
              <div className="userBlkWrap">
                <div
                  className={`userAvtr ${getPopoverLastUserAvatarClassName()} cursorDisabled`}
                >
                  {getAssignerNameAvatarJsx(last_updated_by)}
                </div>
                <div
                  className={`${getPopoverLastUserNameClassName()} cursorDisabled`}
                >
                  {getAssignerName(last_updated_by, 'last_updated_by')}
                </div>
              </div>
            </div>
          </Tooltip>
        );
      }
    }
  };

  return (
    <Fragment>
      {viewType === TaskListView.CARD ? (
        contentForTaskList()
      ) : (
        <td className="assignWrap">{contentForTaskList()}</td>
      )}
    </Fragment>
  );
};
export default UserNameTag;
