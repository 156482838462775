import { MoreOutlined } from '@ant-design/icons';
import type { TableColumnsType } from 'antd';
import { ConfigProvider, message, Popover, Tooltip } from 'antd';
import enUS from 'antd/locale/en_US';
import frFR from 'antd/locale/fr_FR';
import jaJP from 'antd/locale/ja_JP';
import { Rbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import dayjs from 'dayjs';
import { newCustomFieldColumnWidth } from 'models/enums/constants';
import {
  ICustomFieldModalAttributes,
  IProjectState,
  ITaskList,
  ITaskListDetails,
  IUpdatedTasks,
} from 'models/interface';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import BrowserStorageService from 'services/browserStorage.service';
import { useProjectDashboardStore, useUserStore } from 'stores';
import { checkIsCustomField } from '../../GridListV4/services/grid.handler';
import useTableService, {
  DEFAULT_COLUMN_DATA_INDEX,
  mappingDetails,
} from '../services/table.service';
import useTaskService from '../services/task.service';
import Task from '../Task/Task';
import AddCustomField from './AddCustomField';
import ActionDropdown from './ColumnAction/ActionDropdown';
import UploadField from './CustomCells/UploadField';
import PriorityDropdown from './CustomFieldCells/PriorityDropdown';
import HideColumns from './Filter/HideColumns';
import TableView from './TableComponents/ColumnDragTable';
// import CustomNumberField from './CustomCells/CustomNumberField';
import { useFetchNewPermission } from 'components/sharedComponents/hooks';
import cryptoRandomString from 'crypto-random-string';
import { Locale } from 'antd/es/locale';
import MultiSelectDropdown from './CustomFieldCells/MultiSelectDropdown';
import CustomTextField from './CustomCells/CustomTextField';
import DateTimeField from './CustomCells/DateTimeField';
import EffortsField from './CustomCells/EffortsField/EffortsField';
import { getNewCustomFieldId } from 'utils/commonFunctions';
import CustomNumberField from '../../GridListV4/CustomFieldComponents/CustomNumberField';
import GridCustomTextField from '../../GridListV4/CustomFieldComponents/GridCustomTextField';
export interface DataType extends ITaskListDetails {
  key: React.Key;
  id: string | number;
  dataIndex: string;
  name: string;
  platform: string;
  version: string;
  label: string;
  title: string;
  upgradeNum: number;
  creator: string;
  createdAt: string;
  show: boolean;
  options?: any[];
  fixed: boolean;
  hidden: boolean;
  columnIndex: number;
  isDefaultColumn: boolean;
  width: number;
}

export interface ExpandedDataType {
  key: React.Key;
  date: string;
  name: string;
  upgradeNum: string;
}

const localeMap: {
  [key: string]: Locale; // Allow any string as a key
  en: Locale;
  fr: Locale;
  ja: Locale;
} = {
  en: enUS,
  fr: frFR,
  ja: jaJP,
};

const CFID_EFFORT_FIELD = 8;

const CTable: React.FC<{
  taskDetails?: ITaskList;
  sectionToggleState: 'collapsed' | 'expanded';
  socket?: any;
  setTaskListDetails: React.Dispatch<React.SetStateAction<ITaskList>>;
  isTaskEmpty?: boolean;
  customfieldCmsData?: ICustomFieldModalAttributes;
  setIsTaskEmpty?: React.Dispatch<React.SetStateAction<boolean>>;
  forPnc?: boolean;
}> = (props) => {
  const {
    taskDetails,
    setTaskListDetails,
    isTaskEmpty,
    setIsTaskEmpty,
    customfieldCmsData,
    forPnc,
  } = props ?? {};
  const location = useLocation();
  const browserStorage = BrowserStorageService.getInstance();
  const language = browserStorage.getLocalization().language;

  const { fetchNewPermission } = useFetchNewPermission();
  // Stores
  const { taskListCmsData, customFields, projectDetails } =
    useProjectDashboardStore((state: IProjectState) => state);
  const { userDetails } = useUserStore((state: any) => state);
  // Static
  const sectionId = taskDetails?.section_id ?? '';
  const packId = taskDetails?.pack_id;
  const sectionName = taskDetails?.section_name ?? '';
  const org_key = location.pathname.split('/')[2];
  const [
    status,
    task,
    dueDate,
    assignee,
    assigned_on,
    assigned_by,
    last_updated_on,
    last_updated_by,
  ] = DEFAULT_COLUMN_DATA_INDEX;
  const DEFAULT_COLUMNS: any = [];
  const ACTION_COLUMN = {
    title: taskListCmsData?.lbl_task_action_header,
    hidden: false,
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    width: 60,
    fixed: 'right',
    render: (_: any, record: any) => {
      return (
        <Task
          setIsTaskEmpty={setIsTaskEmpty}
          key={record.task_id}
          eachTaskDetail={record}
          sectionId={sectionId}
          setTaskListDetails={props?.setTaskListDetails}
          statusDoneId={taskDetails?.status_done_id}
          sectionName={sectionName}
          packId={packId}
          taskDetails={props?.taskDetails}
          renderType="action"
        />
      );
    },
  };

  // States
  const [columns, setColumns] = useState<TableColumnsType<DataType>>([]);
  const [dataSource, setDataSource] = useState<ITaskListDetails[]>();
  let deleteOptionsRef = useRef<any>(null);

  // Services
  const {
    updateExistingTask,
    updateCustomField,
    deleteCustomFieldAPI,
    updateSettings,
    addNewCustomField,
    deleteCustomFieldV2,
    updateCustomFieldV2,
    updateTaskCustomFieldValue,
  } = useTaskService({
    projectDetails,
    sectionId: taskDetails?.section_id ?? 0,
    org_key,
    userDetails,
    taskListCmsData,
  });
  const {
    revertCustomField,
    addCustomField,
    getDataSource,
    convertColumnsToCustomMeta,
    deleteCustomField,
    changeCustomField,
    handleToggleEdit,
    updateCFData,
  } = useTableService({
    taskDetails,
    setTaskListDetails,
  });

  useEffect(() => {
    sessionStorage.removeItem('newCustomField');
  }, []);
  let newCF = getNewCustomFieldId();

  useEffect(() => {
    if (newCF) {
      // Use setTimeout to allow the new cell to be added to the table
      // before scrolling to it. This ensures that the DOM has been updated
      // and the new cell is available for scrolling.
      const timeoutId = setTimeout(() => {
        const scrollableArea =
          document.querySelector<HTMLElement>('.ant-table-thead');

        if (scrollableArea) {
          const allCells =
            scrollableArea.querySelectorAll<HTMLElement>('th.ant-table-cell');

          if (allCells.length > 1) {
            const secondLastCell = allCells[allCells.length - 2];

            if (scrollableArea && secondLastCell) {
              secondLastCell.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
              });
            }
          }
        }
      }, 10);
      return () => clearTimeout(timeoutId);
    }
  }, [newCF]);

  const composeColumns = (newCols: any) => {
    const columnList = [...newCols];
    if (!projectDetails?.is_archived && !forPnc) {
      columnList.push(ACTION_COLUMN);
    }
    setColumns(columnList);
  };

  const convertTo24HourParts = (time12h: string) => {
    const parsedTime = dayjs(time12h, 'h:mm A');

    // Extract the hour and minute separately
    const hour = parsedTime.format('HH'); // 24-hour format hour
    const minute = parsedTime.format('mm'); // Minutes

    return { hour, minute };
  };

  // Function to convert 'DD/MM/YYYY' to a Date object
  const parseDate = (
    dateString: string | null | undefined,
    timeString: string | null | undefined,
  ) => {
    let time = timeString ? convertTo24HourParts(timeString) : null;
    if (dateString === null || dateString === undefined) return null;
    const [day, month, year] = dateString.split('/').map(Number); // Split and convert to numbers
    if (time) {
      return new Date(
        year,
        month - 1,
        day,
        Number(time.hour),
        Number(time.minute),
      );
    }
    return new Date(year, month - 1, day);
  };

  const multiSelectOptionFilter = (
    selectedOptions: string[],
    allOptions: any[],
  ) => {
    if (selectedOptions && selectedOptions.length > 0) {
      let fetchOptionDetails: any[] = [];
      selectedOptions.forEach((opt: string) => {
        let findOptionDetails = allOptions.find(
          (itm: any) => itm.value === opt,
        );
        if (findOptionDetails) {
          fetchOptionDetails.push(findOptionDetails);
        }
      });

      return fetchOptionDetails;
    }

    return [];
  };

  // Functions
  const getColumns = (taskDetail?: ITaskList) => {
    const metaData = (taskDetail?.custom_meta ?? [])
      .map((i: any, index) => {
        if (!i) return;

        if (+i.mapping_id >= 1 && +i.mapping_id <= 8) {
          if (+i.mapping_id === 1) {
            return {
              mapping_id: i.mapping_id,
              title: taskListCmsData?.lbl_task_status_header,
              width: i.width,
              hidden: i.hidden,
              dataIndex: status,
              isDefaultColumn: true,
              showSorterTooltip: { target: 'sorter-icon', color: '#2E364C' },
              key: status,
              fixed: 'left',
              align: 'center',
              sorter: (a: any, b: any) =>
                (a?.[status] ?? 0) - (b?.[status] ?? 0),
              render: (_: any, record: any) => {
                return (
                  <Task
                    setIsTaskEmpty={setIsTaskEmpty}
                    key={record.task_id}
                    eachTaskDetail={record}
                    sectionId={sectionId}
                    setTaskListDetails={setTaskListDetails}
                    statusDoneId={taskDetail?.status_done_id}
                    // socket={props?.socket}
                    sectionName={sectionName}
                    packId={packId}
                    taskDetails={taskDetail}
                    renderType="status"
                  />
                );
              },
            };
          }

          if (+i.mapping_id === 2) {
            return {
              mapping_id: i.mapping_id,
              title: (
                <Popover
                  content={<div>Actions</div>}
                  trigger="contextMenu"
                  placement="bottomLeft"
                >
                  <span style={{ width: '100%' }}>
                    {taskListCmsData?.lbl_task_name_header}
                  </span>
                </Popover>
              ),
              width: i.width,
              hidden: i.hidden,
              dataIndex: task,
              isDefaultColumn: true,
              showSorterTooltip: { target: 'sorter-icon', color: '#2E364C' },
              key: task,
              fixed: 'left',
              sorter: (a: any, b: any) =>
                (a?.[task] ?? '').localeCompare(b?.[task] ?? ''),
              render: (_: any, record: any) => {
                return (
                  <Task
                    setIsTaskEmpty={setIsTaskEmpty}
                    key={record.task_id}
                    eachTaskDetail={record}
                    sectionId={sectionId}
                    setTaskListDetails={setTaskListDetails}
                    statusDoneId={taskDetail?.status_done_id}
                    sectionName={sectionName}
                    packId={packId}
                    taskDetails={taskDetail}
                    renderType="task"
                    forPnc={forPnc}
                  />
                );
              },
            };
          }

          if (+i.mapping_id === 3) {
            return {
              mapping_id: i.mapping_id,
              title: (
                <span className="displayFlex">
                  {taskListCmsData?.lbl_task_due_date_header}
                </span>
              ),
              width: i.width,
              hidden: i.hidden,
              dataIndex: dueDate,
              isDefaultColumn: true,
              showSorterTooltip: { target: 'sorter-icon', color: '#2E364C' },
              key: dueDate,
              sorter: (a: any, b: any) =>
                dayjs(a?.[dueDate]).diff(dayjs(b?.[dueDate])),
              render: (_: any, record: any) => {
                return (
                  <Task
                    setIsTaskEmpty={setIsTaskEmpty}
                    key={record.task_id}
                    eachTaskDetail={record}
                    sectionId={sectionId}
                    setTaskListDetails={setTaskListDetails}
                    statusDoneId={taskDetail?.status_done_id}
                    sectionName={sectionName}
                    packId={packId}
                    taskDetails={taskDetail}
                    renderType="dueDate"
                  />
                );
              },
            };
          }

          if (+i.mapping_id === 4) {
            return {
              mapping_id: i.mapping_id,
              title: (
                <span className="displayFlex">
                  {taskListCmsData?.lbl_task_assignee_header}
                </span>
              ),
              width: i.width,
              hidden: i.hidden,
              dataIndex: assignee,
              isDefaultColumn: true,
              showSorterTooltip: { target: 'sorter-icon', color: '#2E364C' },
              key: assignee,
              sorter: (a: any, b: any) =>
                (a?.[assignee] ?? '').localeCompare(b?.[assignee] ?? ''),
              render: (_: any, record: any) => {
                return (
                  <Task
                    setIsTaskEmpty={setIsTaskEmpty}
                    key={record.task_id}
                    eachTaskDetail={record}
                    sectionId={sectionId}
                    setTaskListDetails={setTaskListDetails}
                    statusDoneId={taskDetail?.status_done_id}
                    sectionName={sectionName}
                    packId={packId}
                    taskDetails={taskDetail}
                    renderType="assignee"
                  />
                );
              },
            };
          }
          if (+i.mapping_id === 5) {
            return {
              mapping_id: i.mapping_id,
              title: (
                <span className="displayFlex">
                  {taskListCmsData?.lbl_assigned_on_header}
                </span>
              ),
              width: i.width,
              hidden: i.hidden,
              dataIndex: assigned_on,
              isDefaultColumn: true,
              showSorterTooltip: { target: 'sorter-icon', color: '#2E364C' },
              key: assigned_on,
              sorter: (a: any, b: any) =>
                dayjs(a?.[assigned_on]).diff(dayjs(b?.[assigned_on])),
              render: (_: any, record: any) => {
                return (
                  <Task
                    setIsTaskEmpty={setIsTaskEmpty}
                    key={record.task_id}
                    eachTaskDetail={record}
                    sectionId={sectionId}
                    setTaskListDetails={setTaskListDetails}
                    statusDoneId={taskDetail?.status_done_id}
                    sectionName={sectionName}
                    packId={packId}
                    taskDetails={taskDetail}
                    renderType="assignedOn"
                  />
                );
              },
            };
          }

          if (+i.mapping_id === 6) {
            return {
              mapping_id: i.mapping_id,
              title: (
                <span className="displayFlex">
                  {taskListCmsData?.lbl_assigned_by_header}
                </span>
              ),
              width: i.width,
              hidden: i.hidden,
              dataIndex: assigned_by,
              isDefaultColumn: true,
              showSorterTooltip: { target: 'sorter-icon', color: '#2E364C' },
              key: assigned_by,
              sorter: (a: any, b: any) =>
                (a?.[assigned_by] ?? '').localeCompare(b?.[assigned_by] ?? ''),
              render: (_: any, record: any) => {
                return (
                  <Task
                    setIsTaskEmpty={setIsTaskEmpty}
                    key={record.task_id}
                    eachTaskDetail={record}
                    sectionId={sectionId}
                    setTaskListDetails={setTaskListDetails}
                    statusDoneId={taskDetail?.status_done_id}
                    sectionName={sectionName}
                    packId={packId}
                    taskDetails={taskDetail}
                    renderType="assignedBy"
                  />
                );
              },
            };
          }

          if (+i.mapping_id === 7) {
            return {
              mapping_id: i.mapping_id,
              title: (
                <span className="displayFlex">
                  {taskListCmsData?.lbl_last_updated_on_header}
                </span>
              ),
              width: i.width,
              hidden: i.hidden,
              dataIndex: last_updated_on,
              isDefaultColumn: true,
              showSorterTooltip: { target: 'sorter-icon', color: '#2E364C' },
              key: last_updated_on,
              sorter: (a: any, b: any) =>
                dayjs(a?.[last_updated_on]).diff(dayjs(b?.[last_updated_on])),
              render: (_: any, record: any) => {
                return (
                  <Task
                    setIsTaskEmpty={setIsTaskEmpty}
                    key={record.task_id}
                    eachTaskDetail={record}
                    sectionId={sectionId}
                    setTaskListDetails={setTaskListDetails}
                    statusDoneId={taskDetail?.status_done_id}
                    sectionName={sectionName}
                    packId={packId}
                    taskDetails={taskDetail}
                    renderType="lastUpdatedOn"
                  />
                );
              },
            };
          }
          if (+i.mapping_id === 8) {
            return {
              mapping_id: i.mapping_id,
              title: (
                <span className="displayFlex">
                  {taskListCmsData?.lbl_last_updated_by_header}
                </span>
              ),
              width: i.width,
              hidden: i.hidden,
              dataIndex: last_updated_by,
              isDefaultColumn: true,
              showSorterTooltip: { target: 'sorter-icon', color: '#2E364C' },
              key: last_updated_by,
              sorter: (a: any, b: any) =>
                (a?.[last_updated_by] ?? '').localeCompare(
                  b?.[last_updated_by] ?? '',
                ),
              render: (_: any, record: any) => {
                return (
                  <Task
                    setIsTaskEmpty={setIsTaskEmpty}
                    key={record.task_id}
                    eachTaskDetail={record}
                    sectionId={sectionId}
                    setTaskListDetails={setTaskListDetails}
                    statusDoneId={taskDetail?.status_done_id}
                    sectionName={sectionName}
                    packId={packId}
                    taskDetails={taskDetail}
                    renderType="lastUpdatedBy"
                  />
                );
              },
            };
          }
        } else {
          let column = {
            ...i,
            title: (
              <div className="custom-column-actions">
                <span className="custom-column-name">
                  <Tooltip
                    title={i?.description ? i?.description : i?.name}
                    color={'#2E364C'}
                  >
                    {i?.name}
                  </Tooltip>
                </span>
                {!projectDetails?.is_archived ? (
                  <Rbac
                    allowedPermissions={[
                      ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_CUSTOM_SETTING_VIEW,
                      ERbacPermissions.PROJECT_SECTION_TASK_CUSTOM_FIELD_SETTING_VIEW,
                    ]}
                    project_role_id={projectDetails?.associated_role_id}
                  >
                    <ActionDropdown
                      // onDelete={() => {
                      //   customFieldOps(i, 'delete');
                      // }}
                      onDelete={() => {
                        handleDeleteCustomField(i);
                      }}
                      onDeleteOptions={(deletedOptions: any) => {
                        let tempDelRef = deleteOptionsRef?.current ?? [];
                        const { option_id } = deletedOptions[0];
                        if (option_id) {
                          let deletedField = {
                            option_id,
                          };
                          tempDelRef.push(deletedField);
                        }
                        deleteOptionsRef.current = tempDelRef;
                      }}
                      onChange={(changedValues, allValues) => {
                        if (!changedValues?.width) return;

                        const newWidth =
                          changedValues?.width ?? allValues?.width;
                        let newTaskDetails: any = {};

                        setTaskListDetails((prev: any) => {
                          const newCustomMeta = prev.custom_meta.map(
                            (j: any) => {
                              if (j?.mapping_id === i?.mapping_id) {
                                j.tempWidth = newWidth;
                              }
                              return j;
                            },
                          );

                          newTaskDetails = {
                            ...prev,
                            custom_meta: newCustomMeta,
                          };

                          return newTaskDetails;
                        });
                      }}
                      onSave={(allValues) => {
                        const newWidth = allValues?.width;
                        const newName = allValues?.columnName;
                        const description = allValues?.description;
                        let newTaskDetails: any = {};
                        setTaskListDetails((prev: any) => {
                          const col_rank = prev?.settings?.col_rank ?? [];
                          const newCustomMeta = prev.custom_meta
                            .map((j: any) => {
                              if (j?.mapping_id !== i?.mapping_id) return j;

                              j.width = newWidth;
                              j.name = newName ? newName : j.name;
                              j.description = description;

                              if (j.id === 2) {
                                j.number_format =
                                  allValues?.number_format ?? j.number_format;
                                j.precision =
                                  allValues?.precision ?? j?.precision;
                                j.direction =
                                  allValues?.direction ?? j?.direction;
                                j.alignment =
                                  allValues?.alignment ?? j?.alignment;
                                j.selectedFormat =
                                  allValues?.selectedFormat ??
                                  j?.selectedFormat;
                                j.selectedNegativeNumberFormat =
                                  allValues?.selectedNegativeNumberFormat ??
                                  j?.selectedNegativeNumberFormat;
                              }
                              if (j.id === 7) {
                                j.selectedDateFormat =
                                  allValues.selectedDateFormat ??
                                  j.selectedDateFormat;
                                j.showTime = allValues?.showTime ?? j?.showTime;
                              }

                              if (j.tempWidth) delete j.tempWidth;

                              if (
                                (j.id === 1 || j.id === 4 || j.id === 5) &&
                                allValues?.options?.length
                              ) {
                                j.options = allValues.options?.map(
                                  (option: any) => {
                                    return {
                                      value: option?.name ?? option?.value,
                                      color: option?.new_color ?? option?.color,
                                      option_id: option?.option_id,
                                      option_rank: option?.option_rank,
                                    };
                                  },
                                );
                              }

                              // customFieldOps(j, 'update');
                              handleUpdateCustomField(j, allValues);

                              return j;
                            })
                            .sort((a: any, b: any) => {
                              const indexA = col_rank.indexOf(a.key);
                              const indexB = col_rank.indexOf(b.key);
                              return indexA - indexB;
                            });

                          newTaskDetails = {
                            ...prev,
                            custom_meta: newCustomMeta,
                          };

                          if (deleteOptionsRef.current?.length) {
                            newTaskDetails?.task_list_details?.map(
                              (task: any) => {
                                task.custom_data = task?.custom_data?.filter(
                                  (cd: any) =>
                                    !deleteOptionsRef.current?.some(
                                      (doItem: any) =>
                                        cd.mapping_id === doItem.mapping_id &&
                                        cd.value === doItem.value,
                                    ),
                                );
                                return task;
                              },
                            );
                          }
                          return newTaskDetails;
                        });

                        // handleUpdateSettings(newTaskDetails, 'width');

                        const newSettings = newTaskDetails?.settings;
                        const col_rank = newSettings?.col_rank ?? [];
                        const newCols = [...getColumns(newTaskDetails)].sort(
                          (a, b) => {
                            const indexA = col_rank.indexOf(a.key);
                            const indexB = col_rank.indexOf(b.key);
                            return indexA - indexB;
                          },
                        );
                        composeColumns(newCols);

                        const data = getDataSource(
                          newTaskDetails?.task_list_details,
                        );
                        setDataSource(data);
                      }}
                      onSaveSetting={() => {}}
                      defaultValue={{
                        width: i.width ? i.width : newCustomFieldColumnWidth,
                        columnName: i.name,
                      }}
                      editForm={{
                        ...i,
                        addToQuickFilter: i?.addToQuickFilter || false,
                      }}
                      onClose={() => (deleteOptionsRef.current = null)}
                    >
                      <div onClick={(e) => e.stopPropagation()}>
                        <MoreOutlined />
                      </div>
                    </ActionDropdown>
                  </Rbac>
                ) : null}
              </div>
            ),
            width: i?.tempWidth
              ? i?.tempWidth
              : i.width || newCustomFieldColumnWidth,
            hidden: i?.hidden ?? false,
            dataIndex: i.mapping_id,
            key: i.mapping_id,
            fixed: false,
            columnIndex: index,
            showSorterTooltip: { target: 'sorter-icon', color: '#2E364C' },
          };

          if (i.id === 1 || i.id === 4) {
            column.render = (text: any, record: any) => {
              const cfValue = record?.custom_data?.find(
                (item: any) => item?.mapping_id === i?.mapping_id,
              );
              const options = i.options
                .filter((i: any) => i.value?.trim())
                .map((i: any) => ({
                  ...i,
                  label: i.value,
                  value: i.value,
                }))
                .sort((a: any, b: any) => a.option_rank - b.option_rank);

              const currentValue = options.find(
                (i: any) => i.option_id === cfValue?.option_id,
              );
              return (
                <PriorityDropdown
                  options={options}
                  value={currentValue?.option_id}
                  disabled={projectDetails?.is_archived}
                  onChange={(value) => {
                    updateCFData(
                      value,
                      i.id,
                      record.task_id,
                      i.mapping_id,
                      taskDetail!,
                    );

                    // const newSettings = newTaskDetails?.settings;
                    // const col_rank = newSettings?.col_rank ?? [];
                    // const newCols = [...getColumns(newTaskDetails)].sort(
                    //   (a, b) => {
                    //     const indexA = col_rank.indexOf(a.key);
                    //     const indexB = col_rank.indexOf(b.key);
                    //     return indexA - indexB;
                    //   },
                    // );
                    // composeColumns(newCols);

                    // const data = getDataSource(
                    //   newTaskDetails?.task_list_details,
                    // );
                    // setDataSource(data);
                    if (typeof record.task_id === 'number') {
                      // handleUpdateTaskList(newTaskDetails);
                      handleUpdateTaskCustomData({
                        taskId: record.task_id,
                        forEffortField: false,
                        customData: {
                          mapping_id: i.mapping_id,
                          option_id: value?.option_id ?? '',
                        },
                      });
                    }
                  }}
                  onSelect={(value) => {
                    if (value?.option_id === currentValue?.option_id) {
                      handleToggleEdit(i.id, record.task_id, i.mapping_id);
                    }
                  }}
                />
              );
            };

            if (i.sortable) {
              column.sorter = (a: any, b: any) => {
                return (a?.[i?.mapping_id] ?? '').localeCompare(
                  b?.[i?.mapping_id] ?? '',
                );
              };
            }
          }

          if (i.id === 2) {
            column.render = (text: any, record: any) => {
              return (
                <CustomNumberField
                  taskDetail={record}
                  disabled={projectDetails?.is_archived}
                  value={text as number}
                  configuration={i}
                  // sectionId={sectionId}
                  // setTaskListDetails={setTaskListDetails}
                  onChange={(value) => {
                    updateCFData(
                      value,
                      i.id,
                      record.task_id,
                      i.mapping_id,
                      taskDetail!,
                    );

                    // const newSettings = newTaskDetails?.settings;
                    // const col_rank = newSettings?.col_rank ?? [];
                    // const newCols = [...getColumns(newTaskDetails)].sort(
                    //   (a, b) => {
                    //     const indexA = col_rank.indexOf(a.key);
                    //     const indexB = col_rank.indexOf(b.key);
                    //     return indexA - indexB;
                    //   },
                    // );
                    // composeColumns(newCols);

                    // const data = getDataSource(
                    //   newTaskDetails?.task_list_details,
                    // );
                    // setDataSource(data);
                    if (typeof record.task_id === 'number') {
                      handleUpdateTaskCustomData({
                        taskId: record.task_id,
                        forEffortField: false,
                        customData: {
                          mapping_id: i.mapping_id,
                          value: value,
                        },
                      });
                    }
                  }}
                />
              );
            };

            if (i.sortable) {
              column.sorter = (a: any, b: any) => {
                return (a?.[i?.mapping_id] ?? 0) - (b?.[i?.mapping_id] ?? 0);
              };
            }
          }

          if (i.id === 3) {
            column.render = (text: any, record: any) => {
              return <UploadField disabled={projectDetails?.is_archived} />;
            };
          }

          if (i.id === 5) {
            column.render = (text: any, record: any) => {
              const cfValue = record?.custom_data?.find(
                (item: any) => item?.mapping_id === i?.mapping_id,
              );

              const options = i.options
                .filter((i: any) => i.value?.trim())
                .map((i: any, _index: number) => ({
                  ...i,
                  option_rank: Number(i.option_rank),
                  label: i.value,
                }))
                .sort((a: any, b: any) => a.option_rank - b.option_rank);

              const currentValue = options
                .filter((i: any) => cfValue?.option_id?.includes(i.option_id))
                .map((i: any) => i.value);

              const allValues = options.filter((i: any) =>
                cfValue?.option_id?.includes(i.option_id),
              );

              return (
                <MultiSelectDropdown
                  options={options.map((item: any) => ({
                    ...item,
                    label: item.value,
                    key: item.option_id,
                    value: item.option_id,
                  }))}
                  values={allValues}
                  onChange={(value) => {
                    updateCFData(
                      value,
                      i.id,
                      record.task_id,
                      i.mapping_id,
                      taskDetail!,
                    );

                    // const newSettings = newTaskDetails?.settings;
                    // const col_rank = newSettings?.col_rank ?? [];
                    // const newCols = [...getColumns(newTaskDetails)].sort(
                    //   (a, b) => {
                    //     const indexA = col_rank.indexOf(a.key);
                    //     const indexB = col_rank.indexOf(b.key);
                    //     return indexA - indexB;
                    //   },
                    // );
                    // composeColumns(newCols);

                    // const data = getDataSource(
                    //   newTaskDetails?.task_list_details,
                    // );
                    // setDataSource(data);

                    if (typeof record.task_id === 'number') {
                      // handleUpdateTaskList(newTaskDetails);
                      handleUpdateTaskCustomData({
                        taskId: record.task_id,
                        forEffortField: false,
                        customData: {
                          mapping_id: i.mapping_id,
                          option_id: value?.map((item: any) => item?.option_id),
                        },
                      });
                    }
                  }}
                  onSelect={(value) => {
                    if (value === currentValue?.value) {
                      handleToggleEdit(i.id, record.task_id, i.mapping_id);
                    }
                  }}
                  disabled={projectDetails?.is_archived}
                />
              );
            };

            if (i.sortable) {
              column.sorter = (a: any, b: any) => {
                const aValue = Array.isArray(a?.[i?.mapping_id])
                  ? a[i?.mapping_id].join(',')
                  : a?.[i?.mapping_id] ?? '';

                const bValue = Array.isArray(b?.[i?.mapping_id])
                  ? b[i?.mapping_id].join(',')
                  : b?.[i?.mapping_id] ?? '';

                return aValue.localeCompare(bValue);
              };
            }
          }
          if (i.id === 6) {
            column.render = (text: any, record: any) => {
              return (
                <GridCustomTextField
                  disabled={projectDetails?.is_archived}
                  value={text}
                  onChange={(value) => {
                    updateCFData(
                      value,
                      i.id,
                      record.task_id,
                      i.mapping_id,
                      taskDetail!,
                    );
                    // const newSettings = newTaskDetails?.settings;
                    // const col_rank = newSettings?.col_rank ?? [];
                    // const newCols = [...getColumns(newTaskDetails)].sort(
                    //   (a, b) => {
                    //     const indexA = col_rank.indexOf(a.key);
                    //     const indexB = col_rank.indexOf(b.key);
                    //     return indexA - indexB;
                    //   },
                    // );
                    // composeColumns(newCols);

                    // const data = getDataSource(
                    //   newTaskDetails?.task_list_details,
                    // );
                    // setDataSource(data);
                    if (typeof record.task_id === 'number') {
                      handleUpdateTaskCustomData({
                        taskId: record.task_id,
                        forEffortField: false,
                        customData: {
                          mapping_id: i.mapping_id,
                          value: value,
                        },
                      });
                    }
                  }}
                />
              );
            };
          }
          if (i.id === 7) {
            column.render = (text: any, record: any) => {
              return (
                <DateTimeField
                  onSave={(value) => {
                    updateCFData(
                      value,
                      i.id,
                      record.task_id,
                      i.mapping_id,
                      taskDetail!,
                    );

                    // const newSettings = newTaskDetails?.settings;
                    // const col_rank = newSettings?.col_rank ?? [];
                    // const newCols = [...getColumns(newTaskDetails)].sort(
                    //   (a, b) => {
                    //     const indexA = col_rank.indexOf(a.key);
                    //     const indexB = col_rank.indexOf(b.key);
                    //     return indexA - indexB;
                    //   },
                    // );
                    // composeColumns(newCols);

                    // const data = getDataSource(
                    //   newTaskDetails?.task_list_details,
                    // );
                    // setDataSource(data);
                    if (typeof record.task_id === 'number') {
                      // handleUpdateTaskList(newTaskDetails);
                      handleUpdateTaskCustomData({
                        taskId: record.task_id,
                        forEffortField: false,
                        customData: {
                          mapping_id: i.mapping_id,
                          value: value,
                        },
                      });
                    }
                  }}
                  dateTimeValue={text}
                  config={i}
                />
              );
            };
            if (i.sortable) {
              column.sorter = (a: any, b: any) => {
                const dateA: any = parseDate(
                  a?.[i?.mapping_id]?.Date,
                  a?.[i?.mapping_id]?.Time,
                );
                const dateB: any = parseDate(
                  b?.[i?.mapping_id]?.Date,
                  b?.[i?.mapping_id]?.Time,
                );

                if (dateA === null && dateB === null) return 0; // Both null, keep their order
                if (dateA === null) return 1; // Treat null as the latest date (place null after other values)
                if (dateB === null) return -1; // Treat null as the latest date (place other values before null)
                return dateA - dateB;
              };
            }
          }

          if (i.id === 8) {
            column.render = (text: any, record: any) => {
              return (
                <EffortsField
                  type="taskList"
                  value={text}
                  taskDetail={record}
                  disabled={projectDetails?.is_archived}
                  onSave={(efforts: any, effortLogged: any) => {
                    handleUpdateTaskCustomData({
                      taskId: record.task_id,
                      forEffortField: true,
                      ...effortLogged,
                      customData: {
                        mapping_id: i.mapping_id,
                        ...efforts,
                      },
                    });
                  }}
                />
              );
            };
          }
          return column;
        }
      })
      .filter((i) => i?.key);

    return metaData;
  };

  const sumColumnWidth = (columns: TableColumnsType<DataType>) => {
    let tableWidth = columns.reduce(
      (acc, current) =>
        acc + Number(current?.hidden ? 0 : current?.width || 175),
      0,
    );
    tableWidth =
      projectDetails?.is_archived || forPnc ? tableWidth + 60 : tableWidth;
    return tableWidth;
  };

  async function customFieldOps(
    option: any,
    type: 'add' | 'update' | 'delete' = 'add',
  ) {
    try {
      const mappingId = option?.mapping_id;

      if (type === 'delete' && mappingId) {
        const updatedTaskList = await deleteCustomFieldAPI(mappingId);
        if (updatedTaskList?.response?.data?.messageId === -4) {
          const error = new Error(updatedTaskList?.response?.data?.message);
          (error as any).messageId = updatedTaskList?.response?.data?.messageId;
          throw error;
        }
        const emptyTask = {
          task_id: cryptoRandomString({ length: 5 }),
          task_rank: 1,
          task_name: '',
          due_date: '',
          assignee_dp: '',
          assignee_id: null,
          assignee_name: '',
          assignee_email: '',
          assigner_id: null,
          task_status_id: 1,
          task_priority_id: 2,
          task_status_name: taskListCmsData?.task_status_options.find(
            (eachOption) => eachOption.status_id === 1,
          )?.status_name,
          task_priority_name: taskListCmsData?.task_priority_options.find(
            (eachOption) => eachOption.priority_id === 2,
          )?.priority_name,
          created_by: userDetails?.user_id,
          custom_data: null,
        };
        const task =
          Object.keys(updatedTaskList).length > 0
            ? updatedTaskList
            : [emptyTask];

        const newDetails = deleteCustomField(mappingId, task);
        const newTaskDetails = {
          ...mappingDetails(newDetails),
        };
        const newSettings = newTaskDetails?.settings;
        const col_rank = newSettings?.col_rank ?? [];
        const newCols = [...getColumns(newTaskDetails)].sort((a, b) => {
          const indexA = col_rank.indexOf(a.key);
          const indexB = col_rank.indexOf(b.key);
          return indexA - indexB;
        });
        composeColumns(newCols);

        const newDataSource = getDataSource(newTaskDetails?.task_list_details);
        setDataSource(newDataSource);
        return;
      }

      const { data, messageId } = await updateCustomField({
        customMeta: type === 'add' ? option.default_meta : option,
        userId: Number(taskDetails?.created_by),
        sectionId: Number(taskDetails?.section_id),
        sectionTypeId: Number(taskDetails?.section_type_id),
        deleteOptions: deleteOptionsRef.current,
      });

      if (messageId === -4) {
        const error = new Error(
          taskListCmsData?.lbl_error_message_permission_denied,
        );
        (error as any).messageId = messageId;
        throw error;
      }

      // if (type === 'add') {
      //   const newTaskDetails = addCustomField(option, JSON.parse(data));
      //   sessionStorage.setItem('newCustomField', data);
      //   await handleUpdateSettings(newTaskDetails as any, 'orderRow');
      // }

      if (messageId !== 1 && type === 'add') revertCustomField(option);
    } catch (error: any) {
      if (error?.messageId === -4) {
        fetchNewPermission(
          org_key,
          taskListCmsData?.lbl_error_message_permission_denied,
        );
        return;
      }
      revertCustomField(option);
    }
  }

  const handleUpdateTaskList = async (taskDetails: ITaskList) => {
    if (taskDetails) {
      const updatedTaskList = await updateExistingTask(
        (taskDetails.task_list_details as IUpdatedTasks[]) ?? [],
      );

      const newDetails = changeCustomField(updatedTaskList);

      const newTaskDetails = {
        ...mappingDetails(newDetails),
      };

      const newSettings = newTaskDetails?.settings;
      const col_rank = newSettings?.col_rank ?? [];
      const newCols = [...getColumns(newTaskDetails)].sort((a, b) => {
        const indexA = col_rank.indexOf(a.key);
        const indexB = col_rank.indexOf(b.key);
        return indexA - indexB;
      });
      composeColumns(newCols);

      const newDataSource = getDataSource(newTaskDetails?.task_list_details);
      setDataSource(newDataSource);
    }
  };

  const handleUpdateSettings = async (
    taskDetails?: ITaskList,
    type: 'orderColumn' | 'orderRow' | 'hiding' | 'width' = 'hiding',
    settings?: any,
  ) => {
    if (taskDetails) {
      await updateSettings(taskDetails);

      if (type === 'width') {
        const newTaskDetails = taskDetails;
        const newSettings = newTaskDetails?.settings;
        const col_rank = newSettings?.col_rank ?? [];
        const newCols = [...getColumns(taskDetails)].sort((a, b) => {
          const indexA = col_rank.indexOf(a.key);
          const indexB = col_rank.indexOf(b.key);
          return indexA - indexB;
        });
        composeColumns(newCols);

        const data = getDataSource(newTaskDetails?.task_list_details);
        setDataSource(data);
        await updateSettings(newTaskDetails);
        return;
      }

      const newTaskDetails = {
        ...mappingDetails(taskDetails),
      };
      const newSettings = newTaskDetails?.settings;
      setTaskListDetails(newTaskDetails);
      await updateSettings(newTaskDetails);

      if (['orderColumn', 'hiding'].includes(type)) return;

      const col_rank = newSettings?.col_rank ?? [];
      const newCols = [...getColumns(newTaskDetails)].sort((a, b) => {
        const indexA = col_rank.indexOf(a.key);
        const indexB = col_rank.indexOf(b.key);
        return indexA - indexB;
      });

      composeColumns(newCols);

      const data = getDataSource(newTaskDetails?.task_list_details);
      setDataSource(data);
    }
  };

  const handleAddNewCustomField = async (option: any) => {
    try {
      const { data, messageId } = await addNewCustomField({
        sectionId: Number(taskDetails?.section_id),
        fieldTypeId: Number(option?.field_type_id),
      });
      if (messageId === 1) {
        const newTaskDetails = addCustomField(data);
        sessionStorage.setItem('newCustomField', data?.mapping_id);
        await handleUpdateSettings(newTaskDetails as any, 'orderRow');
      } else {
        const error = new Error(data?.message);
        (error as any).messageId = messageId;
        throw error;
      }
    } catch (error: any) {
      if (error?.messageId === -4) {
        fetchNewPermission(
          org_key,
          taskListCmsData?.lbl_error_message_permission_denied,
        );
        return;
      }
    }
  };

  const handleDeleteCustomField = async (option: any) => {
    try {
      const { data, messageId } = await deleteCustomFieldV2({
        sectionId: Number(taskDetails?.section_id),
        mappingId: option.mapping_id,
      });

      if (messageId === 1) {
        setTaskListDetails((prev: any) => {
          const newTaskDetails = {
            ...prev,
            custom_meta: prev?.custom_meta?.filter(
              (i: any) => i.mapping_id !== option.mapping_id,
            ),
          };
          return newTaskDetails;
        });
      } else {
        const error = new Error(data?.message);
        (error as any).messageId = messageId;
        throw error;
      }
    } catch (error: any) {
      if (error?.messageId === -4) {
        fetchNewPermission(
          org_key,
          taskListCmsData?.lbl_error_message_permission_denied,
        );
        return;
      }
    }
  };

  const handleUpdateCustomField = async (
    updatedTask: any,
    updatedCustomField: any,
  ) => {
    try {
      let payload: any = {};
      const customSettings = { ...updatedTask };

      payload.mappingId = updatedTask?.mapping_id;

      if ('options' in customSettings) {
        delete customSettings.options;
      }

      payload.customSetting = customSettings;

      const deleteOptions =
        deleteOptionsRef?.current?.filter(
          (option: any) =>
            option.option_id !== undefined && option.option_id !== null,
        ) ?? [];

      if (deleteOptions?.length) {
        payload.deleteOptions = deleteOptions;
      }

      if (updatedCustomField?.options?.length) {
        let addOptions: any[] = [],
          modifyOptions: any[] = [];
        updatedCustomField.options.forEach((option: any) => {
          if (!option.option_id) {
            addOptions.push({
              value: option.name,
              color: option.new_color,
              option_rank: option.new_option_rank,
            });
            return;
          }
          if (
            option.name !== option.value ||
            option.color !== option.new_color ||
            option.option_rank !== option.new_option_rank
          ) {
            modifyOptions.push({
              option_id: option.option_id,
              value: option.name,
              color: option.new_color,
              option_rank: option.new_option_rank,
            });
          }
        });
        if (addOptions.length) {
          payload.addOptions = addOptions;
        }
        if (modifyOptions.length) {
          payload.modifyOptions = modifyOptions;
        }
      }
      const { data, messageId, message } = await updateCustomFieldV2(payload);

      if (messageId !== 1) {
        const error = new Error(message);
        (error as any).messageId = messageId;
        throw error;
      }

      if ([1, 4, 5].includes(data?.id)) {
        setTaskListDetails((prev: any) => {
          const tempCustomMeta = prev.custom_meta.map((i: any) => {
            if (i.mapping_id === data?.mapping_id) {
              return {
                ...i,
                ...data,
              };
            }
            return i;
          });
          return { ...prev, custom_meta: tempCustomMeta };
        });
      }
    } catch (error: any) {
      if (error?.messageId === -4) {
        fetchNewPermission(
          org_key,
          taskListCmsData?.lbl_error_message_permission_denied,
        );
        return;
      }
    }
  };

  const handleUpdateTaskCustomData = async (payload: any) => {
    const fallBackTaskListDetails = { ...taskDetails } as ITaskList;

    try {
      if (payload?.forEffortField) {
        updateCFData(
          payload?.effortLogged ?? [],
          CFID_EFFORT_FIELD,
          payload?.taskId,
          payload?.customData?.mapping_id,
          taskDetails!,
        );
        delete payload?.effortLogged;
      }
      const { data, _message, messageId } = await updateTaskCustomFieldValue({
        ...payload,
        sectionId: Number(taskDetails?.section_id),
      });

      if (messageId !== 1) {
        const error = new Error(data);
        (error as any).messageId = messageId;
        throw error;
      }
      if (payload?.forEffortField) {
        updateCFData(
          data?.[0]?.custom_data.filter(
            (e: any) => e?.mapping_id === payload?.customData?.mapping_id,
          )?.[0]?.value ?? [],
          CFID_EFFORT_FIELD,
          payload?.taskId,
          payload?.customData?.mapping_id,
          taskDetails!,
        );
      }
    } catch (error: any) {
      setTaskListDetails(fallBackTaskListDetails);
      if (error?.messageId === -4) {
        fetchNewPermission(
          org_key,
          taskListCmsData?.lbl_error_message_permission_denied,
        );
        return;
      }
    }
  };

  useEffect(() => {
    if (columns && taskDetails) {
      const newTaskDetails = {
        ...mappingDetails(taskDetails),
      };
      const settings = taskDetails?.settings;
      const col_rank = settings?.col_rank ?? [];
      const newCols = [...getColumns(newTaskDetails)].sort((a, b) => {
        const indexA = col_rank.indexOf(a.key);
        const indexB = col_rank.indexOf(b.key);
        return indexA - indexB;
      });

      composeColumns(newCols);

      const data = getDataSource(newTaskDetails?.task_list_details);
      setDataSource(data);
    }
  }, [taskDetails, projectDetails]);

  return (
    <div className="custom-table-container">
      {taskDetails &&
        createPortal(
          <HideColumns
            columns={
              columns.filter((i: any) => {
                return (
                  [
                    dueDate,
                    assignee,
                    assigned_on,
                    assigned_by,
                    last_updated_on,
                    last_updated_by,
                  ].includes(i.key) || checkIsCustomField(i.mapping_id)
                );
              }) as any[]
            }
            onMenuClick={(newData: any[]) => {
              setColumns((prev: any) => {
                // Handle map `columns` to `newData`
                const newColumns = prev.map((i: any) => {
                  const found = newData.find((j) => j?.key === i?.key);
                  if (found) {
                    return {
                      ...i,
                      hidden: found?.hidden,
                    };
                  }
                  return i;
                });

                if (taskDetails) {
                  const newTaskDetails = {
                    ...taskDetails,
                    custom_meta: convertColumnsToCustomMeta(newColumns).filter(
                      (i: any) => i,
                    ),
                  };

                  if (newTaskDetails) {
                    handleUpdateSettings(newTaskDetails, 'hiding');
                  }
                }
                return newColumns;
              });
            }}
          />,
          document.getElementById(
            `table-section-filter-container-${taskDetails?.section_id}`,
          )!,
        )}
      <div className="antTableWrap">
        <div className="simpleTblWrap">
          <ConfigProvider
            theme={{
              token: {
                colorText: '#2e364c',
              },
            }}
            locale={localeMap?.[language] ?? enUS}
          >
            {columns?.length && taskDetails?.task_list_details?.length ? (
              <TableView
                disabled={projectDetails?.is_archived}
                className="nested-table"
                columns={!columns?.length ? DEFAULT_COLUMNS : columns}
                onColumnOrderChange={(_: any, fromKey: any, toKey: any) => {
                  setColumns((prev) => {
                    const newColumns = [...prev];
                    const fromIndex = newColumns.findIndex(
                      (i) => i?.key === fromKey,
                    );
                    const toIndex = newColumns.findIndex(
                      (i) => i?.key === toKey,
                    );
                    const fromColumn = newColumns[fromIndex];
                    const toColumn = newColumns[toIndex];

                    if (fromColumn?.fixed || toColumn?.fixed) {
                      message.warning(
                        taskListCmsData?.lbl_warning_invalid_reorder_operation,
                      );
                      return newColumns;
                    }

                    if (fromIndex >= 0 && toIndex >= 0) {
                      newColumns.splice(
                        toIndex,
                        0,
                        newColumns.splice(fromIndex, 1)[0],
                      );
                    }

                    if (taskDetails) {
                      const newTaskDetails = {
                        ...taskDetails,
                        custom_meta: convertColumnsToCustomMeta(
                          newColumns,
                        ).filter((i: any) => i),
                      };

                      if (newTaskDetails) {
                        handleUpdateSettings(newTaskDetails, 'orderColumn');
                      }
                    }

                    return newColumns;
                  });
                }}
                onRowReOrder={(newDataSource) => {
                  if (taskDetails) {
                    setDataSource(getDataSource(newDataSource));
                    handleUpdateSettings(
                      {
                        ...taskDetails,
                        custom_meta: convertColumnsToCustomMeta(columns).filter(
                          (i: any) => i,
                        ),
                        task_list_details: newDataSource,
                      },
                      'orderRow',
                    );
                  }
                }}
                onClickAdd={() => {}}
                dataSource={
                  taskDetails?.hide_completed_tasks
                    ? dataSource?.filter(
                        (item) =>
                          item.task_status_id !== 3 ||
                          typeof item.task_id === 'string',
                      )
                    : dataSource
                }
                size="small"
                bordered
                pagination={false}
                key="task_id"
                rowKey="task_id"
                scroll={{
                  x: sumColumnWidth(columns),
                }}
                // loading={!dataSource?.length}
              />
            ) : (
              <></>
            )}
            {!isTaskEmpty && !projectDetails?.is_archived && (
              <Rbac
                allowedPermissions={[
                  ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_CUSTOM_FIELD_ADD,
                  ERbacPermissions.PROJECT_SECTION_TASK_CUSTOM_FIELD_ADD,
                ]}
                project_role_id={projectDetails?.associated_role_id}
              >
                <AddCustomField
                  customfieldCmsData={customfieldCmsData}
                  options={customFields ?? []}
                  // onClick={(option) => customFieldOps(option, 'add')}
                  onClick={handleAddNewCustomField}
                />
              </Rbac>
            )}
          </ConfigProvider>
        </div>
      </div>
    </div>
  );
};

export default CTable;
