import { arrayMove } from '@dnd-kit/sortable';
import cryptoRandomString from 'crypto-random-string';
import {
  EffortFieldType,
  IGridList,
  IGridListBlockDetail,
  IGridListStageDetail,
} from 'models/interface';

interface GridService {
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
}

export const checkIsCustomField = (stageId: number | string) => {
  return Boolean(typeof stageId === 'string' && stageId.includes('_'));
};

export const mapCFtoCompactView = (details: IGridList) => {
  const newDetails = { ...details };
  const blockDetails = details.grid_list_details?.block_details ?? [];
  const taskDetails = details.grid_list_details?.task_details ?? [];
  const stageDetails = details.grid_list_details?.stage_details ?? [];

  // Remove Stage CustomField
  const newStageDetails = stageDetails.filter(
    (i) => !checkIsCustomField(i.stage_id),
  );

  if (newDetails?.grid_list_details) {
    newDetails.grid_list_details.stage_details = newStageDetails;
    newDetails.grid_list_details.stage_details = newStageDetails;
  }

  return newDetails;
};

const useGridHandler = (props: GridService) => {
  const { gridListDetails, setGridListDetails } = props;
  const details = gridListDetails.grid_list_details;

  const validateReOrderItems = (fromIndex: number, toIndex: number) => {
    const stages = details?.stage_details ?? [];
    const fromStage = stages[fromIndex - 1];
    const toStage = stages[toIndex - 1];
    const isFromCustomField = checkIsCustomField(fromStage.stage_id);
    const isToCustomField = checkIsCustomField(toStage.stage_id);
    const isBothCustomField = isFromCustomField && isToCustomField;
    const isBothNormalStage = !isFromCustomField && !isToCustomField;
    const isInvalidReOrder = isFromCustomField !== isToCustomField;

    return { isBothCustomField, isBothNormalStage, isInvalidReOrder };
  };

  const updateStageDragEnd = (
    fromIndex: number,
    toIndex: number,
    setColumns: React.Dispatch<React.SetStateAction<any>>,
  ): { fromId?: number; toId?: number; newCols: any } => {
    const stages = details?.stage_details ?? [];
    const fromStage = stages[fromIndex - 1];
    const toStage = stages[toIndex - 1];
    let newCols: any = [];

    setColumns((prev: any) => {
      const reOrderList = arrayMove(prev, fromIndex, toIndex);
      newCols = reOrderList;
      return reOrderList;
    });
    return { fromId: fromStage.stage_id, toId: toStage.stage_id, newCols };
  };

  const updateTaskDragEnd = (
    fromId: string,
    toId: string,
    setDataSource: React.Dispatch<React.SetStateAction<any>>,
  ) => {
    let newDataSources: any = [];
    setDataSource((prev: any) => {
      if (!prev) return [];

      const activeIndex = prev.findIndex((i: any) => i.key == fromId);
      const overIndex = prev.findIndex((i: any) => i.key == toId);
      const reOrderList = arrayMove(prev, activeIndex, overIndex);
      newDataSources = reOrderList;
      return reOrderList;
    });

    return newDataSources;
  };

  const updateCFCell = (
    value: string | EffortFieldType,
    blockId: string | number,
    taskId: number,
  ) => {
    let newDetails = { ...gridListDetails };

    setGridListDetails((prev: IGridList) => {
      if (!prev.custom_meta || !taskId) return prev;
      const currentBlockLevel = prev.grid_list_details?.task_details?.findIndex(
        (i) => i.task_id === taskId,
      );

      if (currentBlockLevel === -1 || currentBlockLevel === undefined)
        return prev;

      const currentBlocks: any =
        prev.grid_list_details?.block_details?.[currentBlockLevel];

      if (!currentBlocks) return prev;

      const blockIndex = currentBlocks?.findIndex(
        (i: IGridListBlockDetail) => i.block_id === blockId,
      );

      const blockIndexBlockDetails = currentBlocks?.[blockIndex];

      if (typeof value === 'object' && value?.type === 'effort') {
        blockIndexBlockDetails.custom_field_block_value = value.value;
        blockIndexBlockDetails.total_duration_in_minutes =
          value?.total_duration_in_minutes;
        currentBlocks[blockIndex] = blockIndexBlockDetails;
      } else {
        blockIndexBlockDetails.custom_field_block_value = value;
        currentBlocks[blockIndex] = blockIndexBlockDetails;
      }

      if (prev.grid_list_details && prev.grid_list_details.block_details) {
        prev.grid_list_details.block_details[currentBlockLevel] = currentBlocks;
      }

      newDetails = { ...prev };
      return newDetails;
    });

    return newDetails;
  };

  const getCFBlockDataByTask = (
    taskId: number,
    value?: EffortFieldType | null,
  ) => {
    return (
      details?.block_details
        ?.flat()
        ?.filter((i) => i.task_id === taskId)
        ?.map((i) => {
          // return {
          //   mapping_id: i.stage_id,
          //   value: i.custom_field_block_value,
          //   // type: 'effort',
          // };
          const result: any = {};
          result.mapping_id = i.stage_id;
          result.value = i.custom_field_block_value;
          if (value && i.stage_id === value?.mappingId) {
            result.type = 'effort';
          }

          return result;
        })
        ?.filter((i) => i.value) ?? []
    );
  };

  const handleNewCFBlock = (data: any, user_id: string, option: any) => {
    const custom_meta_temp = [
      ...(gridListDetails?.custom_meta ?? []),
      { ...data, width: 150 },
    ];

    const temp_task_list_details =
      gridListDetails?.grid_list_details?.task_details ?? [];

    const temp_stage_details: IGridListStageDetail[] | null | undefined =
      gridListDetails?.grid_list_details?.stage_details ?? [];

    const new_cf_stage_block: IGridListStageDetail = {
      created_by: user_id,
      section_id: gridListDetails?.section_id as number,
      stage_id: data?.mapping_id,
      stage_name: option?.name,
      stage_rank: 1,
    };

    temp_stage_details?.unshift(new_cf_stage_block);

    temp_stage_details?.map((i: any, index: number) => {
      return {
        ...i,
        stage_id: index + 1,
      };
    });

    const temp_block_details:
      | IGridListBlockDetail[]
      | IGridListBlockDetail[][] =
      gridListDetails?.grid_list_details?.block_details ?? [];

    const newObject = {
      block_id: 'new_block',
      due_date: null,
      stage_id: data?.mapping_id ?? 'new cf',
      created_by: user_id,
      section_id: gridListDetails?.section_id,
      assignee_id: null,
      assigner_id: null,
      assignee_name: null,
      assignee_email: null,
      block_status_id: 1,
      is_custom_field_block: true,
      assignee_profile_picture: null,
      custom_field_block_value: null,
      task_rank: 0,
      stage_rank: 1,
    };

    let resultArray: any[];

    if (Array.isArray(temp_block_details) && temp_block_details.length > 0) {
      const flatBlockDetails = temp_block_details.flat();

      const groupedByTaskId = flatBlockDetails.reduce<
        Record<string | number, IGridListBlockDetail[]>
      >((accumulator, current) => {
        const taskId = current.task_id;

        if (!accumulator[taskId]) {
          accumulator[taskId] = [];
        }

        accumulator[taskId].push(current);
        return accumulator;
      }, {});

      resultArray = Object.entries(groupedByTaskId).map(([taskId, blocks]) => {
        const newBlockWithRank = {
          ...newObject,
          block_id: cryptoRandomString({ length: 5 }),
          stage_rank: 1,
          task_id: Number(taskId),
        };

        const updatedBlocks = blocks.map((block, index) => ({
          ...block,
          stage_rank: index + 2,
        }));

        return [newBlockWithRank, ...updatedBlocks];
      });
    } else if (Array.isArray(temp_task_list_details)) {
      resultArray = temp_task_list_details.map((task) => {
        return [
          {
            ...newObject,
            block_id: cryptoRandomString({ length: 5 }),
            task_id: task.task_id,
            task_rank: task.task_rank,
          },
        ];
      });
    } else {
      resultArray = [];
    }

    let tempGridListDetails: any = gridListDetails.grid_list_details;
    tempGridListDetails = {
      ...tempGridListDetails,
      block_details: resultArray,
      stage_details: temp_stage_details,
    };

    setGridListDetails((prev) => ({
      ...prev,
      custom_meta: custom_meta_temp,
      grid_list_details: { ...tempGridListDetails },
    }));
  };

  const handleDeleteCFBlock = (mapping_id: string) => {
    const custom_meta_temp = gridListDetails?.custom_meta?.filter(
      (i: any) => i.mapping_id !== mapping_id,
    );

    const temp_stage_details =
      gridListDetails?.grid_list_details?.stage_details?.filter(
        (i: any) => i.stage_id !== mapping_id,
      );

    const flattenedAndFiltered =
      gridListDetails?.grid_list_details?.block_details
        ?.flat()
        ?.filter((item) => item?.stage_id.toString() !== mapping_id);

    const temp_block_details = flattenedAndFiltered?.reduce(
      (accumulator: any, current) => {
        const taskId = Number(current.task_id);

        let group = accumulator.find(
          (arr: any) => Number(arr[0]?.task_id) === taskId,
        );

        if (!group) {
          group = [];
          accumulator.push(group);
        }

        group.push(current);

        return accumulator;
      },
      [],
    );

    let tempGridListDetails: any = gridListDetails.grid_list_details;
    tempGridListDetails = {
      ...tempGridListDetails,
      block_details: temp_block_details,
      stage_details: temp_stage_details,
    };

    setGridListDetails((prev) => ({
      ...prev,
      custom_meta: custom_meta_temp,
      grid_list_details: { ...tempGridListDetails },
    }));
  };

  const handleUpdateCFBlock = (data: any) => {
    const temp_stage_details =
      gridListDetails?.grid_list_details?.stage_details?.map((ele: any) => {
        if (ele?.stage_id === data?.mapping_id) {
          return {
            ...ele,
            stage_name: data?.name,
          };
        }
        return ele;
      });

    const tempCustomMeta = gridListDetails?.custom_meta?.map((i: any) => {
      if (i?.mapping_id === data?.mapping_id) {
        return {
          ...i,
          ...data,
        };
      }
      return i;
    });

    setGridListDetails((prev: any) => {
      return {
        ...prev,
        custom_meta: tempCustomMeta,
        grid_list_details: {
          ...prev?.grid_list_details,
          stage_details: temp_stage_details,
        },
      };
    });
  };

  return {
    updateStageDragEnd,
    updateTaskDragEnd,
    validateReOrderItems,
    updateCFCell,
    getCFBlockDataByTask,
    handleNewCFBlock,
    handleDeleteCFBlock,
    handleUpdateCFBlock,
  };
};

export default useGridHandler;
