import { FC, Fragment, useEffect, useState } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';

import {
  IFolderData,
  IProjectCreationAttributes,
  IProjectSubmitData,
} from 'models/interface';
import { dashboardService, loggerService } from 'services';
import { useDashboardStore, useUserStore } from 'stores';
import { generateNewListFromCreateFolder } from 'utils/generateNewList';
import { modeOps } from 'models/enums/constants';
import { getCurrentOrganization } from 'utils/commonFunctions';

const RightSideDrawerAddFolderCTA: FC<{
  pageSpecificDataState?: IProjectCreationAttributes | null;
  setValue: UseFormSetValue<IProjectSubmitData>;
  mode: string;
  getValues: UseFormGetValues<IProjectSubmitData>;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isFetching?: boolean;
}> = ({
  pageSpecificDataState,
  setValue,
  setIsDisabled,
  mode,
  getValues,
  isFetching,
}) => {
  const { userDetails } = useUserStore((state: any) => state);

  const {
    folderProjectList,
    setFolderProjectList,
    setFolderList,
    folderList,
    setSelectedFolderId,
  } = useDashboardStore((state: any) => state);

  const [showAddFolderInputField, setShowAddFolderInputField] =
    useState<boolean>(false);
  const [newFolderName, setNewFolderName] = useState<string>('');
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];

  useEffect(() => {
    let targetFolder = [];

    if (newFolderName.trim() !== '') {
      targetFolder = folderList.filter(
        (ele: IFolderData) => ele.folder_name === newFolderName.trim(),
      );
      if (mode === modeOps.EDIT || mode === modeOps.CLONE) {
        setValue('folderId', targetFolder[0].folder_id);
      } else {
        setSelectedFolderId(targetFolder[0].folder_id);
      }
    }
  }, [folderList]);
  useEffect(() => {
    if (showAddFolderInputField) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [showAddFolderInputField]);

  const submitChange = async () => {
    let existingSelectedFolderId = getValues('folderId');
    let existingSelectedFolder = [];

    if (Number(existingSelectedFolderId) !== 0) {
      if (folderList?.length > 0) {
        existingSelectedFolder = folderList.filter(
          (ele: IFolderData) =>
            ele.folder_id === Number(existingSelectedFolderId),
        );
        let folderName = existingSelectedFolder[0]?.folder_name;

        if (folderName !== newFolderName.trim()) {
          createNewFolder();
        } else {
          setShowAddFolderInputField(false);
          setNewFolderName('');
        }
      } else {
        createNewFolder();
        setIsDisabled(false);
      }
    } else {
      createNewFolder();
      setIsDisabled(false);
    }
  };

  const createNewFolder = async () => {
    if (newFolderName.trim() !== '') {
      try {
        const response: any = await dashboardService.addEditFolder({
          folderId: 0,
          folderName: newFolderName.trim(),
          organizationId: getCurrentOrganization(
            org_key,
            userDetails.organization_info,
          )?.organization_id!,
          userId: userDetails.user_id,
        });

        if (response.messageId === 1) {
          let newFolderList = generateNewListFromCreateFolder(
            response.data.project_folder_list,
            folderProjectList,
          );
          setFolderProjectList(newFolderList);
          setFolderList(response.data.folder_list);
          setNewFolderName('');
          setShowAddFolderInputField(false);
        }

        if (response.messageId === -2) {
          message.error(
            pageSpecificDataState?.lbl_error_message_folder_name_exists,
            3,
          );
        }

        if (response.messageId === -1) {
          message.error(pageSpecificDataState?.lbl_error_message_generic, 3);
          await loggerService.log({
            severity: 'High',
            message: 'folder creation failed',
            payload: 'DB Error',
          });
        }
      } catch (error) {
        message.error(pageSpecificDataState?.lbl_error_message_generic, 3);
        await loggerService.log({
          severity: 'High',
          message: 'folder creation failed',
          payload: error,
        });
      }
    } else {
      setShowAddFolderInputField(false);
      setNewFolderName('');
    }
  };

  return (
    <Fragment>
      {showAddFolderInputField && (
        <div className="mrg-t8">
          <input
            type="text"
            maxLength={20}
            value={newFolderName}
            placeholder={pageSpecificDataState?.lbl_default_folder_name}
            onChange={(e) => setNewFolderName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                (async () => {
                  await submitChange();
                })();
              }
            }}
            onBlur={(e) => {
              (async () => await submitChange())();
            }}
            autoFocus
          />
        </div>
      )}
      <div className=" itemNameBlk addFolder">
        <span
          className="addPlusSolid"
          onClick={() => {
            if (!isFetching) {
              setShowAddFolderInputField(true);
            }
          }}
          onKeyDown={() => {}}
        />
        <span
          className="itemName"
          onClick={() => setShowAddFolderInputField(true)}
          onKeyDown={() => {}}
        >
          {pageSpecificDataState?.btn_create_folder_cta}
        </span>
      </div>
    </Fragment>
  );
};

export default RightSideDrawerAddFolderCTA;
