import { Fragment, useEffect, useRef, useState } from 'react';
import { Content, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Mention from '@tiptap/extension-mention';
import Link from '@tiptap/extension-link';
import TaskList from '@tiptap/extension-task-list';
import Paragraph from '@tiptap/extension-paragraph';
import Emoji, { gitHubEmojis } from '@tiptap-pro/extension-emoji';
import Placeholder from '@tiptap/extension-placeholder';
import EditorControls from './EditorControls';
import suggestion from './suggestion';
import { IAllowedExtensions } from 'models/interface';
import { ClickableImage } from './ClickableImage';
import { ModifiedTaskItem } from './ModifiedTaskItem';
import { formatMessageItems } from 'utils/commonFunctions';

export interface ITipTapEditor {
  sendMessage?: any;
  extensionPosition?: 'top' | 'bottom';
  allowedExtensions?: IAllowedExtensions;
  editorClassName?: string;
  threadId?: string;
  showEmojiPicker?: boolean;
  setShowEmojiPicker?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditorData?: React.Dispatch<React.SetStateAction<string | null>>;
  messageContent?: string;
  setMessageId?: React.Dispatch<React.SetStateAction<string>>;
  setMessageContent?: React.Dispatch<React.SetStateAction<string>>;
  setEditContent?: React.Dispatch<React.SetStateAction<string>>;
  setContentAsText?: React.Dispatch<React.SetStateAction<string>>;
  pageCmsData?: any;
  editorContent?: Content;
  autoFocus?: boolean;
  setEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  editing?: boolean;
  info?: {
    orgId: number;
    taskId: number;
    blockId: number;
    projectId: number;
  };
  className?: string;
  isExpandedView?: boolean;
  disable?: boolean;
  toolTipPlacement?: string;
  placeHolder?: string;
}
const Editor: React.FC<ITipTapEditor> = ({
  sendMessage,
  extensionPosition,
  allowedExtensions,
  editorClassName,
  threadId,
  showEmojiPicker,
  setShowEmojiPicker,
  setEditorData,
  messageContent,
  setMessageId,
  setMessageContent,
  setEditContent,
  setContentAsText,
  pageCmsData,
  editorContent,
  autoFocus = true,
  setEditing,
  editing,
  info,
  className,
  isExpandedView = false,
  disable,
  toolTipPlacement,
  placeHolder,
}) => {

  const [mentionPopupParent, setMentionPopupParent] = useState<any>(
    document.body,
  );
  const suggestionRef = useRef<any>(suggestion(info, mentionPopupParent));
  const editor = useEditor({
    editable: true,
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        renderLabel({ options, node }) {
          return `${node.attrs.label ?? node.attrs.id}`;
        },
        suggestion: suggestionRef.current,
      }),
      Link.extend({ inclusive: false }).configure({
        autolink: true,
        linkOnPaste: true,
        HTMLAttributes: {
          target: '_blank',
          class: 'leftMessagePanelLink',
        },
      }),
      Emoji.configure({
        emojis: gitHubEmojis,
        enableEmoticons: true,
      }),
      ClickableImage.configure({
        HTMLAttributes: {
          class: 'tiptapExtensionImage',
        },
      }),
      TaskList.configure({
        HTMLAttributes: {
          class: 'tiptapExtensionCheckListParent',
        },
      }),
      ModifiedTaskItem.configure({
        HTMLAttributes: {
          class: 'tiptapExtensionCheckListChild',
        },
      }),
      Paragraph.configure({
        HTMLAttributes: {
          class: 'tiptapExtensionParagraph',
        },
      }),
      Placeholder.configure({
        placeholder: !placeHolder
          ? pageCmsData
            ? pageCmsData.lbl_messaging_section_editor_placeholder
            : ''
          : placeHolder,
      }),
    ],
    onUpdate({ editor }) {
      if (setEditorData) {
        setEditorData(editor.getHTML());
      }
      if (
        setMessageId &&
        setMessageContent &&
        editing !== undefined &&
        editing === false &&
        editor.getHTML() === `<p class="tiptapExtensionParagraph"></p>`
      ) {
        setMessageId('');
        setMessageContent('');
      }
      if (setEditContent) {
        setEditContent('');
      }

      editor.view.dom.addEventListener('keydown', (event) => {
        if (event.key === 'Backspace') {
          const selection = editor.state.selection;
          const { $from } = selection;
          const node = $from.nodeBefore;
          const parentNode = node?.marks[0];
          if (
            parentNode &&
            node &&
            parentNode.type.name === 'link' &&
            parentNode.attrs.rel !== 'noopener noreferrer nofollow'
          ) {
            event.preventDefault();
            editor
              .chain()
              .focus()
              .deleteRange({ from: $from.pos - node.nodeSize, to: $from.pos })
              .run();
          }
        }
      });
    },
    autofocus: autoFocus,
    content: editorContent,
  });

  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [progressBarPercentage, setProgressBarPercentage] = useState<number>(0);
  useEffect(() => {
    if (document.querySelector('.messageThreadClass') !== null) {
      setMentionPopupParent(document.querySelector('.messageThreadClass'));
      document
        .querySelector('.messageThreadClass')
        ?.classList.remove('testClass');
    }
  }, [document.querySelector('.messageThreadClass')]);

  useEffect(() => {
    if (document.querySelector('.feedbackPopover') !== null) {
      setMentionPopupParent(document.querySelector('.feedbackPopover'));
      document.querySelector('.feedbackPopover')?.classList.add('testClass');
    }
  }, [document.querySelector('.feedbackPopover')]);

  useEffect(() => {
    if (suggestionRef.current) {
      suggestionRef.current.updateParent(mentionPopupParent);
    }
  }, [mentionPopupParent]);

  useEffect(() => {
    if (editor && messageContent) {
      editor.commands.setContent(formatMessageItems(messageContent));
      if (editing && autoFocus) {
        editor.commands.focus();
      }
    }
  }, [editor, messageContent]);

  useEffect(() => {
    if (isExpandedView && editor && autoFocus) {
      editor.commands.focus();
    }
  }, [isExpandedView]);

  useEffect(() => {
    if (editor && autoFocus) {
      editor.commands.focus('end');
    }
  }, [editor]);

  useEffect(() => {
    if (!editorContent && editor) {
      editor.commands.setContent('');
    }
  }, [editorContent, editor]);

  useEffect(() => {
    if (disable && editor) {
      editor?.setEditable(false);
    } else {
      editor?.setEditable(true);
    }
  }, [disable, editor]);

  function getEditorTextWithFileNames(editor: any) {
    const doc = editor?.state?.doc;
    let textContent = '';
    // let hasText = false;
    let isEmptyList = false;

    // Traverse the document to check for text or image nodes
    doc?.descendants((node: any) => {
      // console.log(node)
      if (node.type.name === 'bulletList' || node.type.name === 'taskList') {
        // If the node is an empty bullet list or task list, return an empty string
        if (!node.textContent.trim() || node.textContent.trim() === '') {
          isEmptyList = true;
        }
      } else if (node?.attrs && node.attrs.title) {
        // If there's no text, we might use the image title
        // if (!hasText) {
          textContent += ` ${node?.attrs?.title}`;
        // }
      } else if (node?.isText) {
        // If text is found, prioritize it over the image's title
        // hasText = true;
        textContent += ` ${node?.text}`;
      } 
      else if (node.type.name === 'emoji') {
        // If emoji is found, we might use the emoji name
        textContent += ` ${node?.attrs.name}`;
      }
    });

    // If an empty list is found, return an empty string
    if (isEmptyList) { 
      return '';
    }

    // Clean up: trim, replace multiple spaces, and limit to 255 characters
    return textContent?.trim().replace(/\s+/g, ' ').substring(0, 255);
  }

  // Example usage
  const extractedText = getEditorTextWithFileNames(editor);

  useEffect(() => {
    if (setContentAsText && extractedText) {
      setContentAsText(extractedText);
    }
  }, [extractedText]);


  return extensionPosition === 'top' ? (
    <Fragment>
      <EditorControls
        editor={editor}
        sendMessage={sendMessage}
        allowedExtensions={allowedExtensions}
        threadId={threadId}
        showEmojiPicker={showEmojiPicker}
        setShowEmojiPicker={setShowEmojiPicker}
        setShowProgressBar={setShowProgressBar}
        setProgressBarPercentage={setProgressBarPercentage}
        progressBarPercentage={progressBarPercentage}
        className={className}
        toolTipPosition={toolTipPlacement ?? ''}
        isSendDisabled={!extractedText.trim() || extractedText.trim() === ''}
      />
      {showProgressBar && (
        <progress value={progressBarPercentage} max="100"></progress>
      )}
      {/* {showProgressBar && <progress />} */}
      <EditorContent className={editorClassName} editor={editor} />
    </Fragment>
  ) : (
    <Fragment>
      <EditorContent className={editorClassName} editor={editor} />
      {showProgressBar && (
        <progress value={progressBarPercentage} max="100"></progress>
      )}
      {/* {showProgressBar && <progress />} */}
      <EditorControls
        editor={editor}
        sendMessage={sendMessage}
        allowedExtensions={allowedExtensions}
        threadId={threadId}
        showEmojiPicker={showEmojiPicker}
        setShowEmojiPicker={setShowEmojiPicker}
        pageCmsData={pageCmsData}
        setShowProgressBar={setShowProgressBar}
        setProgressBarPercentage={setProgressBarPercentage}
        progressBarPercentage={progressBarPercentage}
        className={className}
        isSendDisabled={!extractedText.trim() || extractedText.trim() === ''}
      />
    </Fragment>
  );
};

export default Editor;
