import { ChangeEvent, FC, Fragment } from "react";
import { Input, Tooltip } from "antd";
import { IProfileSettingCmsData } from "../../../models/interface";
import { useUserStore } from "../../../stores";
import isEmail from "validator/lib/isEmail";

const ListNewMember: FC<{
  listNewMemberData: { email: string; roleId: number }[];
  updateMemberEmail: (i: number, evt: ChangeEvent<HTMLInputElement>) => void;
  removeNewMember: (i: number) => void;
  updateMemberRole: (i: number, evt: number) => void;
  settingLblCms: IProfileSettingCmsData;
}> = ({
  listNewMemberData,
  updateMemberEmail,
  removeNewMember,
  updateMemberRole,
  settingLblCms,
}) => {
  const storeUserDetail = useUserStore((state) => state.userDetails);
  let teamEmailsArray:any[] = []
  listNewMemberData.map(element => {
    teamEmailsArray.push(element.email)
  });

  return listNewMemberData.map((e, i) => (
   <Fragment key={e.email}>
      <li>
        <div>
          <Input
            autoFocus={true}
            type="text"
            aria-label="email"
            defaultValue={e.email}
            maxLength={60}
            onFocus={(evt) => updateMemberEmail(i, evt)}
            onBlur={(evt) => {
              updateMemberEmail(i, evt);
            }}
          />
          <select
            name="role"
            value={e.roleId}
            onChange={(evt) => updateMemberRole(i, +evt.target.value)}
            style={{
              backgroundColor: "transparent",
            }}
          >
            {settingLblCms?.org_role.map((e) => (
              <option key={e.value} value={e.value}>
                {e.label}
              </option>
            ))}
          </select>
        </div>
        {listNewMemberData.length > 1 ? (
          <Tooltip
            title={settingLblCms?.lbl_remove}
            color={"#2E364C"}
            placement="bottom"
          >
            {" "}
            <span
              className="cmnIcon deleteBin"
              onClick={() => removeNewMember(i)}
              onKeyDown={() => {}}
            >
            </span>
          </Tooltip>
        ) : null}
      </li>
      {e.email !== "" && !isEmail(e.email) ? (
        <small className="msgError">
          {settingLblCms?.lbl_please_input_valid_email}
        </small>
      ) : null}
      {e.email !== "" && e.email === storeUserDetail?.email! ? (
        <small className="msgError">
          {settingLblCms?.lbl_error_responses?.cannot_invite_yourself_error}
        </small>
      ) : null}
      {
       emailAdded(e.email, teamEmailsArray, i)? <small className="msgError">
       {settingLblCms?.lbl_duplicate_email_id}
     </small>:null
      }
    </Fragment>
  ));
};

export default ListNewMember;

const emailAdded = (email:string, array:any[], index:number) =>{
  if(email!==""&&array.indexOf(email)!== index){
    return true
  }
  return false;
}