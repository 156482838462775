import { FC } from 'react';
import {
  ICustomFieldModalAttributes,
  IGridList,
  IProjectSections,
  IProjectState,
} from 'models/interface';
import { useProjectDashboardStore } from 'stores';
import { Rbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import AddStageCTA from './Stage/AddStageCTA';
import AddTaskCTA from './Task/AddTaskCTA';
import { GRID_VIEW_TYPES } from './GridList';
import Table from './Table/Table';
import CompactView from './CompactView/CompactView';

const GridListDetails: FC<{
  sectionDetails: IProjectSections;
  sectionToggleState: 'collapsed' | 'expanded';
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  getSectionDetails: () => void;
  customfieldCmsData?: ICustomFieldModalAttributes;
}> = ({
  sectionDetails,
  sectionToggleState,
  gridListDetails,
  setGridListDetails,
  getSectionDetails,
  customfieldCmsData,
}) => {
  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );

  const getViewClass = () => {
    const view_id = gridListDetails.view_id;
    if (gridListDetails?.compactView) return '';
    return GRID_VIEW_TYPES.find((i) => i.id === view_id)?.className ?? '';
  };

  if (sectionToggleState !== 'expanded') return <></>;

  const taskDetails = gridListDetails.grid_list_details?.task_details || [];

  if (gridListDetails?.compactView) {
    return (
      <CompactView
        gridListDetails={gridListDetails}
        sectionDetails={sectionDetails}
        sectionToggleState={sectionToggleState}
        setGridListDetails={setGridListDetails}
        customfieldCmsData={customfieldCmsData}
        getSectionDetails={getSectionDetails}
      />
    );
  }

  return (
    <div className="gridList2DOuterWrap">
      <div className={'gridList2DWrap ' + getViewClass()}>
        <Table
          sectionDetails={sectionDetails}
          gridListDetails={gridListDetails}
          setGridListDetails={setGridListDetails}
          getSectionDetails={getSectionDetails}
          customfieldCmsData={customfieldCmsData}
        />
      </div>
      {projectDetails?.is_archived === false ? (
        <>
          {taskDetails.length > 0 ? (
            <Rbac
              allowedPermissions={[
                ERbacPermissions.PROJECT_SECTION_GRIDLIST_STAGE_ADD,
              ]}
              project_role_id={projectDetails?.associated_role_id}
            >
              <AddStageCTA
                gridListDetails={gridListDetails}
                setGridListDetails={setGridListDetails}
              />
            </Rbac>
          ) : (
            <></>
          )}
          <Rbac
            allowedPermissions={[
              ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_ADD,
            ]}
            project_role_id={projectDetails?.associated_role_id}
          >
            <AddTaskCTA
              gridListDetails={gridListDetails}
              setGridListDetails={setGridListDetails}
            />
          </Rbac>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default GridListDetails;
