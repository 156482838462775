import { usePncCmsDataStore } from 'stores/pncCmsData.store';
import NoFeedbackIcon from '../../../../assets/images/no-feedback-grey.svg';

const EmptyListViewPage = () => {
  const { pncFeedbackSpecificCmsData } = usePncCmsDataStore();
  return (
    <div className='noFeedbackBlock'>
      <img src={NoFeedbackIcon} alt="No feedback icon" />
      {/* <p>{pncFeedbackSpecificCmsData?.list_view_empty_page_text}</p> */}
    </div>
  );
};

export default EmptyListViewPage;
