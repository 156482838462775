import { useRbac } from 'auth/rbac/rbac';
import { LeftNav } from 'components/layout';
import { NavRoutesEnum } from 'models/enums/navRoutes';
import { FC, useEffect } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import AuthService from 'services/Auth0/authService';
import { getDefaultOrganization } from 'utils/commonFunctions';
const authService = AuthService.getInstance();

const LandingPage: FC<{
  pageSlug?: string[];
  cmsPageName?: string[];
}> = ({ pageSlug, cmsPageName }) => {
  const { userDetails, organizations } = useRbac();
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();

  const redirect = (currentOrganization: any) => {
    if (params.get('redirect')) {
      return navigate(`${location.pathname}${location.search}`);
    }
    if (sessionStorage.getItem('lastLocation')) {
      return navigate(sessionStorage.getItem('lastLocation')!);
    }
    return navigate(`/org/${currentOrganization.org_key}/dashboard`);
  };

  useEffect(() => {
    if (organizations) {
      let currentOrganization = authService.getCurrentOrganization();
      if (!currentOrganization || currentOrganization === '') {
        if (organizations) {
          currentOrganization = getDefaultOrganization(organizations);
        } else {
          navigate(NavRoutesEnum.USER_ONBOARDING);
        }
      }
      if (!userDetails?.additional_settings?.onboarding_complete) {
        navigate(NavRoutesEnum.USER_ONBOARDING);
      } else if (currentOrganization) {
        redirect(currentOrganization);
      }
    } else {
      navigate(NavRoutesEnum.USER_ONBOARDING);
    }
  }, [userDetails, navigate]);

  useEffect(() => {
    sessionStorage.setItem(
      'lastLocation',
      `${location.pathname}${location.search}`,
    );
  }, [location]);

  return location.pathname === NavRoutesEnum.CREATE_NEW_TEAM ||
    location.pathname.split('/')[3] === NavRoutesEnum.PLANNER ||
    location.pathname === NavRoutesEnum.USER_ONBOARDING ? (
    <Outlet />
  ) : (
    <div className="mainWrapper">
      <LeftNav pageSlug={pageSlug} cmsPageName={cmsPageName} />
      <Outlet />
    </div>
  );
};

export default LandingPage;
