import React, { useState, useEffect, useRef } from 'react';
import { Space, Tooltip, Tag } from 'antd';
import { getContrastColor } from 'utils/commonFunctions';

interface ICustomTagDisplay {
  options: any[];
  value: any[];
  allowedToEdit: boolean;
  disabled: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDropdownOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomTagDisplay = (props: ICustomTagDisplay) => {
  const {
    value,
    options,
    allowedToEdit,
    disabled,
    setIsEdit,
    setIsDropdownOpen,
  } = props;
  const [maxTagCount, setMaxTagCount] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const customTagRef = useRef<HTMLDivElement>(null);
  const moreTagRef = useRef<HTMLDivElement>(null);
  const [currentTagDefaultWidth, setCurrentTagDefaultWidth] =
    useState<number>(80);
  const [moreTagWidth, setMoreTagWidth] = useState<number>(15);

  const calculateMaxTagCount = () => {
    if (containerRef.current) {
      const containerWidth = containerRef?.current?.offsetWidth;
      let totalWidth = 0;
      let count = 0;
      let customTagContainer = customTagRef?.current?.offsetWidth
        ? customTagRef?.current?.offsetWidth
        : currentTagDefaultWidth;
      let moreTagContainer = moreTagRef?.current?.offsetWidth
        ? moreTagRef?.current?.offsetWidth
        : moreTagWidth;
      value.forEach(() => {
        const remainingWidth = containerWidth - totalWidth;
        if (
          customTagContainer < remainingWidth &&
          remainingWidth - customTagContainer >= moreTagContainer + 8
        ) {
          totalWidth += customTagContainer + 4;
          count++;
        }
      });

      setMaxTagCount(count);
    }
  };

  useEffect(() => {
    calculateMaxTagCount();
    window.addEventListener('resize', calculateMaxTagCount);

    return () => {
      window.removeEventListener('resize', calculateMaxTagCount);
    };
  }, [value, options]);

  if (!value) return null;

  const optionIdsSet = new Set(options?.map((option) => option.option_id));
  const selectedOptions = value?.filter((selected) =>
    optionIdsSet.has(selected.option_id),
  );

  return (
    <Space
      direction="vertical"
      style={{ width: '100%' }}
      onClick={() => {
        if (allowedToEdit && !disabled) {
          setIsEdit(true);
          setIsDropdownOpen && setIsDropdownOpen(true);
        }
      }}
    >
      <div ref={containerRef} className="multiSelectTagDisplay">
        {selectedOptions.slice(0, maxTagCount).map((option) => (
          <Tooltip title={option.label} placement="left" color={'#2E364C'}>
            <Tag
              className="custom-tag"
              key={option.value}
              ref={customTagRef}
              style={{
                background: option?.color ?? '#FFFFFF',
                color: getContrastColor(option?.color ?? '#FFFFFF'),
              }}
            >
              {option.label}
            </Tag>
          </Tooltip>
        ))}
        {selectedOptions.length > maxTagCount && (
          <Tooltip
            overlayStyle={{ pointerEvents: 'none' }}
            title={selectedOptions
              .slice(maxTagCount)
              .map(({ label }) => label)
              .join(', ')}
            color="var(--cs-primary-a)"
          >
            <span className="more-tag" ref={moreTagRef}>
              +{selectedOptions.length - maxTagCount}
            </span>
          </Tooltip>
        )}
      </div>
    </Space>
  );
};

export default CustomTagDisplay;
