import { RoutesEnum } from '../../models/enums/apiRoutes';
import axios from 'axios';
import ApiService from '../Api.service';
import AuthService from '../Auth0/authService';
import { IResponseCommon } from 'models/interface';
import {
  ICommonPncRespose,
  IGeneratePncRespose,
  IResponseSaveReply,
} from 'models/interface/pnc.interface';

type IOrgId = number | string | undefined;

const authService = AuthService.getInstance();
class FeedbackService {
  private getToken() {
    return authService.getAccessToken();
  }

  public saveFeedback = async (feedbackObjPayload: any) => {
    const token = await this.getToken();
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    };
    return await axios.post(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.SAVE_FEEDBACK}`,
      feedbackObjPayload,
      { headers: headers },
    );
  };
  public getAllFeedbacks = async (payload: {
    organizationId: IOrgId;
    projectId: number | undefined;
    taskStatusId: number;
    environmentId: number;
  }) => {
    return ApiService.post<any>(RoutesEnum.GET_ALL_FEEDBACKS, payload);
  };
  public getFeedbacksById = async (payload: any) => {
    return ApiService.post<any>(RoutesEnum.GET_FEEDBACK_BY_ID, payload);
  };
  public editFeedBack = async (feedbackIdPayload: {
    organizationId: IOrgId;
    projectId: number | undefined;
    detailsId: number;
    feedback: any;
    taskName: string;
  }) => {
    return ApiService.post<IResponseCommon>(
      RoutesEnum.EDIT_FEEDBACK,
      feedbackIdPayload,
    );
  };
  public deleteFeedBack = async (feedbackIdPayload: {
    organizationId: IOrgId;
    projectId: number | undefined;
    detailsId: number;
  }) => {
    return ApiService.post<IResponseCommon>(
      RoutesEnum.DELETE_FEEDBACK,
      feedbackIdPayload,
    );
  };
  public replyFeedBack = async (replyPayload: {
    organizationId: IOrgId;
    projectId: number | undefined;
    detailsId: number;
    reportedBy: number;
    reply: any;
  }) => {
    return ApiService.post<IResponseSaveReply>(
      RoutesEnum.REPLY_FEEDBACK,
      replyPayload,
    );
  };
  public updateStatusFeedBack = async (statusPayload: {
    organizationId: IOrgId;
    projectId: number | undefined;
    taskStatusId: number;
    detailsId: number;
  }) => {
    return ApiService.post<IResponseCommon>(
      RoutesEnum.UPDATE_STATUS_FEEDBACK,
      statusPayload,
    );
  };
  public editReply = async (editReplyPayload: {
    organizationId: IOrgId;
    projectId: number | undefined;
    replyId: number;
    reply: any;
  }) => {
    return ApiService.post<IResponseCommon>(
      RoutesEnum.EDIT_REPLY,
      editReplyPayload,
    );
  };
  public deleteReply = async (deleteReplyPayload: {
    organizationId: IOrgId;
    projectId: number | undefined;
    replyId: number;
  }) => {
    return ApiService.post<IResponseCommon>(
      RoutesEnum.DELETE_REPLY,
      deleteReplyPayload,
    );
  };
  public editFeedbackScreen = async (payload: {
    organizationId: IOrgId;
    projectId: number | undefined;
    feedbackId: number;
    ScreenName: string;
  }) => {
    return ApiService.post<IResponseCommon>(
      RoutesEnum.EDIT_FEEDBACK_SCREEN,
      payload,
    );
  };

  public screenDetails = async (payload: {
    organizationId: IOrgId;
    projectId: number | undefined;
    feedbackId: number;
  }) => {
    return ApiService.post<IResponseCommon>(
      RoutesEnum.GET_FEEDBACK_SCREEN_DETAILS,
      payload,
    );
  };

  public updatePriority = async (payload: {
    detailsId: number;
    taskPriorityId: number;
    organizationId: IOrgId;
    projectId: number | undefined;
  }) => {
    return ApiService.post<IResponseCommon>(
      RoutesEnum.UPDATE_PRIORITY,
      payload,
    );
  };

  public createEnvironment = async (payload: {
    organizationId: IOrgId;
    projectId: number | undefined;
    environmentName: string;
    url: string;
  }) => {
    return ApiService.post<ICommonPncRespose>(
      RoutesEnum.CREATE_ENVIRONMENT,
      payload,
    );
  };

  public getEnvData = async (payload: {
    organizationId: IOrgId;
    projectId: number | undefined;
  }) => {
    return ApiService.post<ICommonPncRespose>(RoutesEnum.GET_ENV_DATA, payload);
  };

  public getEnvById = async (payload: {
    organizationId: IOrgId;
    projectId: number | undefined;
    environmentId: number;
  }) => {
    return ApiService.post<ICommonPncRespose>(
      RoutesEnum.GET_ENV_BY_ID,
      payload,
    );
  };

  public editEnvDetails = async (payload: {
    organizationId: IOrgId;
    projectId: number | undefined;
    environmentId: number;
    url: string;
    environmentName: string;
    updatedField: 'name' | 'url' | 'both' | null;
  }) => {
    return ApiService.post<ICommonPncRespose>(
      RoutesEnum.EDIT_ENV_DETAILS,
      payload,
    );
  };

  public deleteEnv = async (payload: {
    organizationId: IOrgId;
    projectId: number | undefined;
    environmentId: number;
  }) => {
    return ApiService.post<ICommonPncRespose>(RoutesEnum.DELETE_ENV, payload);
  };

  public deleteScreen = async (payload: {
    organizationId: IOrgId;
    projectId: number | undefined;
    feedbackId: number;
    deletedBy: number;
  }) => {
    return ApiService.post<ICommonPncRespose>(
      RoutesEnum.DELETE_SCREEN,
      payload,
    );
  };

  public getPncFilters = async (payload: {
    organizationId: IOrgId;
    projectId: number | undefined;
  }) => {
    return ApiService.post<ICommonPncRespose>(
      RoutesEnum.GETPNCFILTERS,
      payload,
    );
  };

  public regenerateScript = async (payload: {
    organizationId: IOrgId;
    projectId: number | undefined;
    environmentId: number;
  }) => {
    return ApiService.post<IGeneratePncRespose>(
      RoutesEnum.REGENERATE_SCRIPT,
      payload,
    );
  };
}

const feedbackService = new FeedbackService();

export default feedbackService;
