import { message } from 'antd';
import {
  IProjectData,
  ITaskListAttributes,
  IUpdatedTasks,
  IUserDetails,
} from 'models/interface';
import { project } from 'ramda';
import { projectService } from 'services';
import { getCurrentOrganization } from 'utils/commonFunctions';

interface TaskServiceProps {
  projectDetails?: IProjectData | null;
  sectionId: number | string | undefined;
  org_key: string;
  userDetails: IUserDetails;
  taskListCmsData?: ITaskListAttributes | null;
}

const useTaskService = (props: TaskServiceProps) => {
  const {
    projectDetails,
    sectionId,
    org_key = '',
    userDetails,
    taskListCmsData,
  } = props ?? {};
  const updateExistingTask = async (taskData?: IUpdatedTasks[]) => {
    if (projectDetails?.project_id && typeof sectionId === 'number') {
      try {
        const { organizationId } = validateData(userDetails, org_key);
        const { data, message, messageId } = await projectService.updateTaskV2({
          organizationId,
          project_id: projectDetails.project_id,
          section_id: sectionId,
          section_details: taskData ?? [],
        });
        if (messageId !== 1) throw Error(message);
        return data;
      } catch (error) {
        message.error(taskListCmsData?.error_message_options?.msg, 3);
      }
    }
  };

  const updateCustomField = async ({
    customMeta,
    userId,
    sectionId,
    sectionTypeId,
    deleteOptions = null,
  }: {
    customMeta: any;
    userId: number;
    sectionId: number;
    sectionTypeId: number;
    deleteOptions?: any;
  }) => {
    try {
      const { organizationId } = validateData(userDetails, org_key);
      const payload = {
        organizationId,
        projectId: projectDetails?.project_id,
        userId,
        sectionId,
        sectionTypeId,
        customItem: customMeta,
        deleteOptions,
      };

      const response = await projectService.addEditCustomMeta(payload);
      if (response?.messageId !== 1) {
        const error = new Error(response?.message);
        (error as any).messageId = response?.messageId;
        throw error;
      }
      return response;
    } catch (error: any) {
      if (error?.messageId === -4) {
        return error;
      }
      message.error(error?.message ?? 'Failed to add custom field', 3);
    }
  };

  const deleteCustomFieldAPI = async (mapping_id: string) => {
    try {
      const { organizationId } = validateData(userDetails, org_key);
      const { data, message, messageId } =
        await projectService.deleteCustomMeta({
          organizationId: organizationId?.toString(),
          userId: userDetails?.user_id,
          sectionId,
          mappingId: mapping_id,
        });
      if (messageId !== 1) {
        const error = new Error(message);
        (error as any).messageId = messageId;
        throw error;
      }
      return data;
    } catch (error: any) {
      if (error?.response?.data?.messageId === -4) {
        return error;
      }
      message.error(error?.message ?? 'Failed to delete custom field', 3);

      return [];
    }
  };

  const updateSettings = async (taskList: any) => {
    try {
      const { organizationId } = validateData(userDetails, org_key);
      const { message, messageId } =
        await projectService.updateCustomFieldSettingV2({
          organizationId: organizationId,
          userId: userDetails.user_id,
          sectionId,
          settings: {
            columnSettings: taskList?.custom_meta?.filter(
              (ele: any) => ele.key !== 'action',
            ),
            taskRank: taskList.task_list_details.map((ele: any) => ele.task_id),
          },
        });
      if (messageId !== 1) throw Error(message);
    } catch (error: any) {
      if (error?.response?.data?.messageId === -4) {
        return error?.response?.data;
      }
      message.error(
        error?.message ?? 'Failed to update custom field settings',
        3,
      );
    }
  };

  const addNewCustomField = async ({
    sectionId,
    fieldTypeId,
  }: {
    sectionId: number;
    fieldTypeId: number;
  }) => {
    try {
      const { organizationId } = validateData(userDetails, org_key);

      const response = await projectService.addNewCustomField({
        organizationId,
        projectId: projectDetails?.project_id,
        sectionId,
        fieldTypeId,
      });
      if (response?.messageId !== 1) {
        const error = new Error(response?.message);
        (error as any).messageId = response?.messageId;
        throw error;
      }
      return response;
    } catch (error: any) {
      if (error?.messageId === -4) {
        return error;
      }
      message.error(error?.message ?? 'Failed to add custom field', 3);
    }
  };

  const deleteCustomFieldV2 = async ({
    sectionId,
    mappingId,
  }: {
    sectionId: number;
    mappingId: string;
  }) => {
    try {
      const { organizationId } = validateData(userDetails, org_key);

      const { data, message, messageId } =
        await projectService.deleteCustomField({
          organizationId,
          projectId: projectDetails?.project_id,
          sectionId: sectionId,
          mappingId,
        });
      if (messageId !== 1) {
        const error = new Error(message);
        (error as any).messageId = messageId;
        throw error;
      }
      return { data, message, messageId };
    } catch (error: any) {
      if (error?.response?.data?.messageId === -4) {
        return error;
      }
      message.error(error?.message ?? 'Failed to delete custom field', 3);

      return [];
    }
  };

  const updateCustomFieldV2 = async (payload: any) => {
    try {
      const { organizationId } = validateData(userDetails, org_key);

      const { data, message, messageId } =
        await projectService.updateCustomField({
          ...payload,
          organizationId,
          projectId: projectDetails?.project_id,
          sectionId: sectionId,
        });

      if (messageId !== 1) {
        const error = new Error(message);
        (error as any).messageId = messageId;
        throw error;
      }
      return { data, message, messageId };
    } catch (error: any) {
      if (error?.response?.data?.messageId === -4) {
        return error;
      }
      message.error(
        error?.message ?? 'Failed to update custom field settings',
        3,
      );

      return [];
    }
  };

  const updateTaskCustomFieldValue = async (payload: any) => {
    try {
      const { organizationId } = validateData(userDetails, org_key);
      const { data, message, messageId } =
        await projectService.updateTaskCustomField({
          organizationId,
          projectId: projectDetails?.project_id,
          sectionId: sectionId,
          taskId: payload.taskId,
          for_effort_field: payload.forEffortField,
          customData: payload.customData,
        });

      if (messageId !== 1) {
        const error = new Error(message);
        (error as any).messageId = messageId;
        throw error;
      }
      return { data, message, messageId };
    } catch (error: any) {
      message.error(taskListCmsData?.error_message_options?.msg, 3);
      return error;
    }
  };

  return {
    updateExistingTask,
    updateCustomField,
    deleteCustomFieldAPI,
    updateSettings,
    addNewCustomField,
    deleteCustomFieldV2,
    updateCustomFieldV2,
    updateTaskCustomFieldValue,
  };
};

export default useTaskService;

function validateData(userDetails: IUserDetails, org_key: string) {
  if (
    !userDetails ||
    !userDetails.organization_info ||
    !userDetails.user_id ||
    !org_key
  )
    throw Error('Organization info not found!');

  const organizationId = getCurrentOrganization(
    org_key,
    userDetails?.organization_info,
  )?.organization_id!;

  if (!organizationId) throw Error('Organization ID not found!');

  return { organizationId };
}
