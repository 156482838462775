import { Checkbox, Select, Space, Tag, Tooltip } from 'antd';
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { IProjectState } from 'models/interface';
import React, { useEffect, useRef, useState } from 'react';
import { useProjectDashboardStore } from 'stores';
import { getContrastColor } from 'utils/commonFunctions';
import CustomTagDisplay from './CustomTagDisplay';
import type { SelectProps } from 'antd';

type TagRender = SelectProps['tagRender'];

interface IMultiSelectDropdownProps {
  options: any[];
  values: any[];
  onChange: (value: any) => void;
  onSelect: (value: any) => void;
  disabled?: boolean;
}

const MultiSelectDropdown = (props: IMultiSelectDropdownProps) => {
  const { options, values, onChange, onSelect, disabled = false } = props;
  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const [isEdit, setIsEdit] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const selectRef = React.useRef<any>(null);

  const value = values.map((itm: any) => ({
    ...itm,
    label: itm?.value,
    value: itm?.option_id,
    key: itm?.key,
    color: itm?.color ?? '#FFFFFF',
  }));

  const selectedValuesOptions: string[] = selectedOptions?.map(
    (item: any) => item?.option_id,
  );
  const valueOptions: string[] = value?.map((item: any) => item?.option_id);

  useEffect(() => {
    const value = values.map((itm: any) => ({
      ...itm,
      label: itm?.value,
      value: itm?.option_id,
      key: itm?.key,
      color: itm?.color ?? '#FFFFFF',
    }));
    setSelectedValues(value);
    setSelectedOptions(value);
  }, [values, options]);
  const { hasPermissions } = useRbac();

  const compareSelectedValues = (valArr1: string[], valArr2: string[]) => {
    const sortedArr1 = valArr1.sort();
    const sortedArr2 = valArr2.sort();

    return sortedArr1?.toString()?.trim() === sortedArr2?.toString()?.trim();
  };

  const trimText = (str: any) => {
    if (str && str.length > 7) {
      return `${str.slice(0, 7)}...`;
    }
    return str;
  };

  const tagRender: TagRender = (props) => {
    const { label, closable, onClose, value } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const color =
      options.find((opt: any) => opt.value === value)?.color || '#FFFFFF';
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          background: color,
          color: getContrastColor(color),
        }}
        className="editingStateOptionTag"
      >
        {trimText(label)}
      </Tag>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target) &&
        !isDropdownOpen
      ) {
        setIsEdit(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef, isDropdownOpen]);
  const allowedToEdit = hasPermissions(
    [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
    projectDetails?.associated_role_id,
  );

  if ((isEdit || selectedValues.length === 0) && allowedToEdit) {
    return (
      <div ref={selectRef}>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          options={options}
          open={isDropdownOpen}
          tagRender={tagRender}
          className="multiSelectInputBox"
          popupClassName="customSelect customMultiSelect"
          autoFocus={isDropdownOpen}
          maxTagCount="responsive"
          onChange={(name, obj) => {
            setSelectedValues(obj);
            setSelectedOptions(obj);
          }}
          value={selectedValues}
          filterOption={(input, option) =>
            option?.label.toLowerCase().includes(input.toLowerCase())
          }
          virtual={false}
          showSearch={true}
          onDropdownVisibleChange={(open) => {
            setIsDropdownOpen(open);
            if (!open) {
              if (
                !compareSelectedValues(
                  [...selectedValuesOptions],
                  [...valueOptions],
                )
              ) {
                setTimeout(() => {
                  onChange(selectedOptions);
                }, 700);
              }
              setIsEdit(false);
            } else {
              setIsEdit(true);
            }
          }}
          onSelect={onSelect}
          optionRender={(option, prop) => {
            const color = option?.data?.color ?? '#FFFFFF';
            const value: string | undefined = option?.value?.toString();

            return (
              <Tooltip
                title={option.label}
                placement={'left'}
                color={'#2E364C'}
              >
                <span>
                  <Checkbox
                    checked={Boolean(
                      value &&
                        (selectedValues.includes(value) ||
                          selectedValuesOptions?.includes(value)),
                    )}
                  />
                </span>
                <div
                  className="customOption"
                  key={prop.index}
                  style={{
                    background: color,
                    color: getContrastColor(color),
                  }}
                  title=""
                >
                  {option.label}
                </div>
              </Tooltip>
            );
          }}
          maxTagPlaceholder={(omittedValues) => (
            <Tooltip
              overlayStyle={{ pointerEvents: 'none' }}
              title={omittedValues.map(({ label }) => label).join(', ')}
              color="var(--cs-primary-a)"
              className="editableStateMoreTag"
            >
              <span>+{omittedValues.length}</span>
            </Tooltip>
          )}
        />
      </div>
    );
  }

  return (
    <CustomTagDisplay
      value={selectedValues}
      options={options}
      allowedToEdit={allowedToEdit}
      disabled={disabled}
      setIsEdit={setIsEdit}
      setIsDropdownOpen={setIsDropdownOpen}
    />
  );
};

export default MultiSelectDropdown;
